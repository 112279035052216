import React, { Component } from "react";
import Header from "./parts/Header";
import Menu from "./parts/Menu";
import multilanguage from '../multilanguage';

class DNotifications extends Component {
  state = {};
  render() {
    return (
      <div className="desktop_container">
        <Menu screenProps={this.props.history} />
        <div className="desktop_container_content">
          <Header screenProps={this.props.history} />
          <div className="d_main_content">
            <div className="d_page_title_container">
              <div className="d_page_title">{multilanguage.notifications}</div>
              <div className="d_page_title_sort">
                <span>{multilanguage.sort_by}: </span>
                <select>
                  <option>{multilanguage.newest_simple}</option>
                  <option>{multilanguage.type_simple}</option>
                </select>
              </div>
            </div>
            <div className="">
              <div className="notification_item_container">
                <div className="notification_left_detele">
                  <img src={require("../assets/images/whiteclose.svg")} />
                </div>
                <div className="notification_item_content">
                  <div className="notification_item_line">
                    <div className="notification_item_tag yellow">{multilanguage.message_simple}</div>
                    <div className="notification_item_date">
                      <img src={require("../assets/images/grayClock.svg")} />
                      <span>24 Nov 2018 at 9:30 AM</span>
                    </div>
                  </div>
                  <div className="notification_item_title">
                    Darren Smith {multilanguage.sent_a_message}
                  </div>
                  <div className="notification_item_subtitle">
                    Sed ut Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nam sed magna commodo, tincidunt tellus ultrices,
                    faucibus lectus.
                  </div>
                </div>
              </div>
              <div className="notification_item_container">
                <div className="notification_left_detele">
                  <img src={require("../assets/images/whiteclose.svg")} />
                </div>
                <div className="notification_item_content">
                  <div className="notification_item_line">
                    <div className="notification_item_tag blue">{multilanguage.comment_simple}</div>
                    <div className="notification_item_date">
                      <img src={require("../assets/images/grayClock.svg")} />
                      <span>24 Nov 2018 at 9:30 AM</span>
                    </div>
                  </div>
                  <div className="notification_item_title">
                    Olivia Wilson {multilanguage.commented_on_your_post}
                  </div>
                  <div className="notification_item_subtitle">
                    Sed ut Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nam sed magna commodo, tincidunt tellus ultrices,
                    faucibus lectus.
                  </div>
                </div>
              </div>

              <div className="notification_item_container">
                <div className="notification_left_detele">
                  <img src={require("../assets/images/whiteclose.svg")} />
                </div>
                <div className="notification_item_content">
                  <div className="notification_item_line">
                    <div className="notification_item_tag green">
                      {multilanguage.result_ready}
                    </div>
                    <div className="notification_item_date">
                      <img src={require("../assets/images/grayClock.svg")} />
                      <span>24 Nov 2018 at 9:30 AM</span>
                    </div>
                  </div>
                  <div className="notification_item_title">
                    {multilanguage.new_result_ready} on Maya Cooper
                  </div>
                  <div className="notification_item_subtitle">
                    Sed ut Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nam sed magna commodo, tincidunt tellus ultrices,
                    faucibus lectus.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
)}
}

export default DNotifications;
