import React, { Component } from 'react';
import SlideCart from './SlideCart';
import NotificationPopup from './NotificationPopup';
import api from '../../api/api';
import auth from '../../api/auth';
import emitter from 'tiny-emitter/instance';
import cart from '../../api/cart';
import multilanguage from '../../multilanguage';
class Header extends Component {
    state = { 
        activeCartMenu: false,
        activeNotifications: false,
        cartUpdateTimestamp: 0,
     }

     handleCloseCart(){
        this.setState({activeCartMenu : false});
    }


    handleCloseNotifications(){
        this.setState({activeNotifications : false});
    }

    componentDidMount(){
        
        emitter.on("cartUpdate", ()=>{
            this.setState({cartUpdateTimestamp: 1})
        })
    }
    render() { 
        return ( 
            <div className="desktop_header">
            
                <SlideCart active={this.state.activeCartMenu} onClose={() => this.handleCloseCart()} screenProps={this.props.screenProps} />
           
            <div className="desktop_header_inner">

            {/* {this.props.detail ?
                <div className="d_header_left">
                    <div className="d_header_detail_back" onClick={() => this.props.screenProps.goBack()}>
                    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.515152 7.20675L6.9798 0.531902C7.66667 -0.177301 8.79798 -0.177301 9.48485 0.531902C10.1717 1.2411 10.1717 2.4092 9.48485 3.1184L4.27273 8.5L9.48485 13.8816C10.1717 14.5908 10.1717 15.7589 9.48485 16.4681C8.79798 17.1773 7.66667 17.1773 6.9798 16.4681L0.515152 9.79325C-0.171717 9.08405 -0.171717 7.91595 0.515152 7.20675Z" fill="black"/>
                    </svg>
                    </div>
                    <img className="d_header_left_avatar" src={require('../../assets/images/personPlaceholder.jpg')} />
                    <div className="d_header_left_detail_name">Anda Wilson</div>
                </div>
            : */}
                <div className="d_header_left">
                    <img className="d_header_left_avatar" src={require('../../assets/images/personPlaceholder.jpg')} />
                    <div>
                        <div className="dheader_left_title">{multilanguage.good_morning}<br className="break"/><span> Dr {auth.auth.user.FirstName}</span></div>
                        {/* <div className="dheader_left_subtitle">{multilanguage.here_are_the_latest_news}</div> */}
                    </div>
                </div>
            {/* } */}

                <div className="d_header_right">
                    {/* <div className="d_header_search">
                        <input type="text" />
                        <img src={require('../../assets/images/desktop/search1.svg')} />
                    </div> */}
                    <div className="d_header_account" onClick={() => this.props.screenProps.push('/account')}>
                        <img src={require('../../assets/images/desktop/account.svg')} />
                    </div>
                    {/* <div className="d_header_notification" onClick={() => this.setState({activeNotifications : true})}>
                        <div className="d_header_cart_iterator">2</div>
                        <img src={require('../../assets/images/desktop/notification.svg')} />
                        <NotificationPopup onViewAll={() => this.props.screenProps.push('/notifications')} active={this.state.activeNotifications} onClose={() => this.handleCloseNotifications()}/>
                    </div> */}
                    {auth.auth.user.role == 'practitioner' ? 
                    <div key={this.state.cartUpdateTimestamp} className="d_header_cart" onClick={() => this.setState({activeCartMenu : true})}>
                        {cart.itemCount == 0 ? null :
                        <div className="d_header_cart_iterator">{cart.itemCount}</div>}
                        <img src={require('../../assets/images/desktop/cart.svg')} />
                    </div>: null}
                    
                </div>
            </div>
            </div>
 )}
}
 
export default Header;