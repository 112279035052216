import React from 'react';
import NavBar from './parts/NavBar';
import { FilePicker } from 'react-file-picker';
import multilanguage from '../multilanguage';
import {NotificationManager} from 'react-notifications';
import api from '../api/api';

let screenHeight = 0;

export default  class EditProfile extends React.Component {

    constructor() {
        super();
        this.state={
            visibleNavBar: true,
            displayName: null,
            email: null,
            phoneNumber: null,
            education: null,
            description: null,
            profile: [],
            loading: true,
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount(){
        screenHeight = document.documentElement.clientHeight;
        window.addEventListener('resize',this.handleResize);
        api.get('/Profile')
        .then(res => {
            this.setState({profile: res.data, displayName: res.data.displayName, education: res.data.education, description: res.data.description, loading: false})
        })
}
save(){
    api.post('/Profile', {displayName: this.state.displayName, education: this.state.education, description: this.state.description})
    .then(res => {
        NotificationManager.success("Profile Saved", '',1000)
    })
}
    componentWillUnmount(){
        window.removeEventListener('resize',this.handleResize);
    }

    handleResize(){
        if(screenHeight - document.documentElement.clientHeight > 150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: false})
        }
        if(screenHeight - document.documentElement.clientHeight < -150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: true})
        }
    }

    render() {
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.edit_profile}</div>
                </div>
               </div>

                <div className="editProfile_container">
                    <FilePicker
                        extensions={[]}
                        // onChange={}
                        // onError={}
                    >
                    <div className="edit_profile_top_container">
                        <div className="edit_profile_avatar_container">
                            <img className="edit_profile_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                            <div className="home_intro_edit_button" onClick={() => this.props.history.push('/edit_profile')}>
                                <img src={require('../assets/images/pencil.svg')} />
                            </div>
                        </div>
                        <span>{multilanguage.change_photo}</span>
                    </div>
                    </FilePicker>

                    {/* <div className="short_bio_container">
                        <div className="short_bio_label">{multilanguage.short_bio}</div>
                        <textarea rows="4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget laoreet metus. Phasellus at sollicitudin arcu. Vestibulum justo odio, viverra a mauris ac.</textarea>
                    </div> */}
<div className="d_packages_info_container">
                             <svg width="36" height="93" viewBox="0 0 36 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="noun_Info_73316">
                                <path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M36 11.0174C36 14.0567 34.831 16.6482 32.4916 18.7827C30.1528 20.917 27.3431 21.988 24.0496 21.988C20.7691 21.988 17.9326 20.917 15.58 18.7827C13.2412 16.6482 12.0584 14.0567 12.0584 11.0174C12.0584 7.98328 13.2412 5.3927 15.58 3.23864C17.9326 1.08386 20.7691 0 24.0496 0C27.3431 0 30.1528 1.08386 32.4916 3.23864C34.831 5.3927 36 7.98328 36 11.0174ZM31.3624 82.9082C33.5539 84.5734 31.322 88.2144 27.1009 90.1514C23.4444 91.844 18.9277 93 15.7686 93C10.9025 93 6.74856 90.8255 4.04592 88.479C1.344 86.1394 0 83.1794 0 79.5905C0 78.1891 0.09408 76.7613 0.29556 75.3003C0.51036 73.8404 0.83364 72.1947 1.2636 70.3573L6.30492 52.87C6.762 51.1981 7.12428 49.6056 7.43364 48.1115C7.72956 46.6185 7.90416 45.2367 7.90416 44.0076C7.90416 41.7671 7.42044 40.2141 6.4794 39.3417C5.53824 38.4622 3.76368 38.0203 1.11552 38.0203C-0.18816 38.0203 -1.49232 32.9965 4.3824 30.9745C7.38012 29.9568 10.2433 29.5668 12.9318 29.5668C17.7713 29.5668 21.5084 30.7243 24.1165 33.0168C26.738 35.3169 28.0558 38.304 28.0558 41.9716C28.0558 42.7253 27.9612 44.0603 27.7865 45.9905C27.5986 47.9001 27.2488 49.6585 26.7784 51.2581L21.764 68.6792C21.3608 70.0799 20.9844 71.6865 20.6618 73.4833C20.3392 75.275 20.1913 76.6299 20.1913 77.5545C20.1913 79.8677 20.7022 81.4343 21.764 82.2802C22.8124 83.119 24.6412 83.5357 27.2356 83.5357C27.683 83.5357 28.1912 83.3186 28.718 83.0936C29.6152 82.7105 30.5662 82.3043 31.3624 82.9082Z" fill="#FDB913"/>
                                </g>
                            </svg>
                            <span >
                                {multilanguage.edit_profil_info} (<a href="http://synevo.pl/przedstawiciele">{multilanguage.list}</a>)
                            </span>
                        </div>
                    <div className="edit_profile_form_container">
                        <input placeholder={multilanguage.display_name} type="text" 
                                value={this.state.displayName}
                                onChange={(e) => this.setState({displayName: e.target.value})}
                                />
                        <input placeholder={multilanguage.email_address}  type="text" 
                                value={this.state.profile.email} 
                                disabled = {true}
                                />
                        <input placeholder={multilanguage.name}  type="text" 
                                value={this.state.profile.firstName}
                                disabled = {true}
                                />
                        <input placeholder={multilanguage.last_name} type="text" 
                                value={this.state.profile.lastName} 
                                disabled = {true}
                                />
                        <input placeholder={multilanguage.phone_number} type="text"  
                                value={this.state.profile.phoneNumber}
                                disabled = {true}
                                />
                        <input placeholder={multilanguage.education} type="text" 
                                value={this.state.education}
                                onChange={(e) => this.setState({education: e.target.value})}
                                />
                        <textarea className="dep_textarea" placeholder={multilanguage.short_description}
                            value={this.state.description}
                            onChange={(e) => this.setState({description: e.target.value})}
                        ></textarea>
                        <button onClick={() => this.save()}>{multilanguage.save_simple}</button>
                    </div>
                </div>
                
                {this.state.visibleNavBar ?
                <NavBar navigation={this.props.history} />
                : null}
           </div>
)}
}
