import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import multilanguage from '../multilanguage';

class DResetPassword extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="desktop_container">
                <Menu screenProps={this.props.history} />
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} />
                    <div className="d_main_content">
                    <div className="d_page_title_container_bordered">
                        <div className="d_page_title">{multilanguage.reset_password}</div>
                        <div className="dp_save_button">
                            <span>{multilanguage.save_changes}</span>
                        </div>
                    </div>

                    <div className="d_reset_password_inputs_container">
                        <div className="dep_input_container">
                            <div className="dep_input_label">{multilanguage.old_password}</div>
                            <input className="dep_input" type="text" />
                        </div>
                        <div className="dep_input_container">
                            <div className="dep_input_label">{multilanguage.new_password}</div>
                            <input className="dep_input" type="text" />
                        </div>
                    </div>

                        <div className="dh_boxes_container">
                             {/* EDIT PROFILE */}
                            <div className="daccount_box" onClick={() => this.props.history.push('/edit_profile')}>
                                <div  className="daccount_box_inner">
                                <img className="margin-right-account" src={require('../assets/images/Asset 5.svg')} />
                                <div>
                                    <div className="daccount_box_title">{multilanguage.edit_profile}</div>
                                    <div className="daccount_box_subtitle">{multilanguage.change_your_personal}</div>
                                </div>
                                </div>
                            </div>

                            {/* ORDER TEMPLATE */}
                            {/* <div className="daccount_box" onClick={() => this.props.history.push('/templates')}> 
                                <div  className="daccount_box_inner">
                                <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M25.0304 2.57669L30.0515 8.17178C31.3255 9.57055 32 11.411 32 13.2515V29.3742C32 33.0552 29.0023 36 25.2553 36H6.74473C2.99766 36 0 33.0552 0 29.3742V6.62577C0 2.94479 2.99766 0 6.74473 0H19.185C21.4333 0 23.5316 0.957055 25.0304 2.57669ZM25.2553 33.7914C27.7283 33.7914 29.7518 31.8037 29.7518 29.3742V13.2515C29.7518 11.9264 29.2272 10.6748 28.3279 9.64417L23.3068 4.04908C22.2576 2.87117 20.6838 2.20859 19.1101 2.20859H6.74473C4.27166 2.20859 2.24824 4.19632 2.24824 6.62577V29.3742C2.24824 31.8037 4.27166 33.7914 6.74473 33.7914H25.2553Z" fill="#00549E"/>
                                    <path d="M22.8889 23H9.11111C8.51852 23 8 23.4667 8 24C8 24.5333 8.51852 25 9.11111 25H22.8889C23.4815 25 24 24.5333 24 24C24 23.4667 23.5556 23 22.8889 23Z" fill="#00549E"/>
                                    <path d="M22.8889 18H9.11111C8.51852 18 8 18.4667 8 19C8 19.5333 8.51852 20 9.11111 20H22.8889C23.4815 20 24 19.5333 24 19C24 18.4667 23.5556 18 22.8889 18Z" fill="#00549E"/>
                                    <path d="M9.10092 15H14.8991C15.4862 15 16 14.5333 16 14C16 13.4667 15.4862 13 14.8991 13H9.10092C8.51376 13 8 13.4667 8 14C8 14.5333 8.51376 15 9.10092 15Z" fill="#00549E"/>
                                </svg>
                                <div>
                                    <div className="daccount_box_title">{multilanguage.order_template}</div>
                                    <div className="daccount_box_subtitle">{multilanguage.upload_your_personal}</div>
                                </div>
                                </div>
                            </div> */}

                             {/* MY LOCATION */}
                            {/* <div className="daccount_box" onClick={() => this.props.history.push('/my_location')}>
                                <div  className="daccount_box_inner">
                                <svg width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.5795 0.0091637C23.2302 0.359773 29.5594 6.60062 29.9767 14.314C30.1158 16.7683 29.629 19.2927 28.5857 21.3262C26.986 24.9726 21.2132 30.7226 17.8748 33.878C17.1097 34.6494 16.136 35 15.0927 35C14.0494 35 13.0061 34.6494 12.2411 33.878C9.18081 31.003 3.75578 25.6738 2.01699 22.5884C0.695515 20.3445 0 17.75 0 15.0854C0 10.9482 1.59969 7.09148 4.5904 4.21648C7.58112 1.34148 11.476 -0.13108 15.5795 0.0091637ZM16.4142 32.4756C21.8392 27.3567 25.595 23.0092 26.7078 20.625C27.612 18.6616 28.0293 16.6281 27.9597 14.4543C27.612 7.86282 22.1869 2.46343 15.5795 2.18294H15.0231C11.6151 2.18294 8.48529 3.44514 6.05099 5.82928C3.47758 8.28355 2.08655 11.6494 2.08655 15.2256C2.08655 17.4695 2.71251 19.7134 3.82534 21.6768C5.00771 23.7104 8.62439 27.7073 13.6321 32.4756C14.3972 33.1768 15.6491 33.1768 16.4142 32.4756Z" fill="#00549E"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11 14.5C11 12.0323 13.0323 10 15.5 10C17.9677 10 20 12.0323 20 14.5C20 16.9677 17.9677 19 15.5 19C13.0323 19 11 16.9677 11 14.5ZM13.1774 14.5726C13.1774 15.8065 14.1935 16.8952 15.5 16.8952C16.8065 16.8952 17.8226 15.8065 17.8226 14.5726C17.8226 13.3387 16.8065 12.25 15.5 12.25C14.2661 12.25 13.1774 13.2661 13.1774 14.5726Z" fill="#00549E"/>
                                </svg>
                                <div>
                                    <div className="daccount_box_title">{multilanguage.my_location}</div>
                                    <div className="daccount_box_subtitle">{multilanguage.adjust_your_personal_location}</div>
                                </div>
                                </div>
                            </div> */}

                            {/* RESET PASSWORD */}
                            {/* <div className="daccount_box">
                                <div  className="daccount_box_inner">
                                <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.14 14.5655H22.47C25.48 14.5655 27.93 17.0211 28 20.038V27.4751C28 31.0533 25.06 34 21.49 34H6.51C2.94 34 0 31.0533 0 27.4751V20.038C0 17.1615 2.24 14.776 5.11 14.5655V10.9172C5.11 7.12849 6.23 4.18175 8.4 2.21725C10.99 -0.098048 15.26 -0.659333 18.34 0.81404C18.9 1.09468 19.39 1.44549 19.88 1.79629C22.33 3.90111 22.75 6.77769 22.82 8.74219C22.89 9.37363 22.4 9.86476 21.84 9.86476C21.21 9.93492 20.72 9.44379 20.72 8.88251C20.58 6.35673 19.95 4.67287 18.48 3.48014C18.13 3.12934 17.78 2.91886 17.36 2.70838C15.05 1.58581 11.69 2.00677 9.73 3.76078C7.98 5.30432 7.14 7.68978 7.14 10.9172V14.5655ZM21.42 31.8952C23.87 31.8952 25.83 29.9307 25.83 27.4751V20.038C25.83 18.2139 24.36 16.6703 22.47 16.6703H5.39C3.57 16.6703 2.03 18.1437 2.03 20.038V27.4751C2.03 29.9307 3.99 31.8952 6.44 31.8952H21.42Z" fill="#00549E"/>
                                </svg>
                                <div>
                                    <div className="daccount_box_title">{multilanguage.reset_password}</div>
                                    <div className="daccount_box_subtitle">{multilanguage.reset_password_text}</div>
                                </div>
                                </div>
                            </div> */}


                        </div>

                    </div>
                </div>
            </div>
 )}
}
 
export default DResetPassword;