import React from 'react';
import multilanguage from '../multilanguage';
import DLoader from "./parts/DLoader";
import api from '../api/api';
import auth from '../api/auth';
import {NotificationManager} from 'react-notifications';

export default  class Notes extends React.Component {

    constructor() {
        super();
        this.state={
           visibleAdd: false,
           visibleDetail: false,
           loading: true,
            notes: [],
            title: null,
            text: null,
            selectedNoteTitle: null,
            selectedNoteText: null,
            selectedNote: null,
        }
    }
    componentDidMount(){
        this.getNewNotes()
     }
     addNote(){
        this.setState({loading: true})
        // this.setState({visibleAddNew:false})
        const id = this.props.match.params.id
        if(this.state.title && this.state.text){
            api.put('/Patient/'+id+'/note', {title: this.state.title, text: this.state.text})
            .then(res => {
                this.getNewNotes()
                this.setState({visibleAdd:false, loading: false, text: null, title: null})
            })
        }
     }
     getNewNotes(){
        const id = this.props.match.params.id
        api.get('/Patient/'+id+'/note')
        .then(res => {
            
            this.setState({loading: false, notes: res.data})
        })
     }
     editNote(){
        const id = this.props.match.params.id
         
         api.put('/Patient/'+id+'/note', {id: this.state.selectedNote, text: this.state.selectedNoteText, title: this.state.selectedNoteTitle})
            .then(res => {
                this.getNewNotes()
                this.setState({visibleDetail:false, loading: false})
            })
     }
    render() {
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.notes}</div>
                </div>
               </div>
                
                <div className="notification_list">
                {this.state.loading ? <DLoader/> :
                    this.state.notes.map((note)=>{
                        return(
                        <div className="notification_item_container"  onClick={() => this.setState({visibleDetail: true, selectedNoteTitle: note.title, selectedNote: note.id, selectedNoteText: note.text})}>
                            <div className="notes_content">
                                <div className="notification_item_line">
                                    <div className="notes_title">
                                        <img src={require('../assets/images/blueNote.svg')} />
                                        <span>{note.title}</span>
                                    </div>
                                    {/* <div className="notification_item_date">
                                        <img src={require('../assets/images/grayClock.svg')} />
                                        <span>24 Nov 2018</span>
                                    </div> */}
                                </div>
                                <div className="notification_item_subtitle">{note.text}</div>
                            </div>
                        </div>
                        )
                    })
                    }
                </div>
                <div className="orders_buton_fixed_simple lower" onClick={() => this.setState({visibleAdd : true})}>
                    <img className="orders_buton_fixed_plus" src={require('../assets/images/whitePlus.svg')} />
                    <span>{multilanguage.add_new_note}</span>
                </div>

                {this.state.visibleAdd ? 
                        <div className="more_menu_container" style={{height:'100%'}}>
                            <div className="more_menu_container_inner">
                                <div className="more_menu_header">
                                    <div className="morE_menu_left_white_space"></div>
                                    <div className="header_my_account">{multilanguage.new_note}</div>
                                    <div className="close_more_menu" onClick={() => this.setState({visibleAdd: false,  title: null, text: null})}>
                                        <img src={require('../assets/images/closeMenu.svg')} />
                                    </div>
                                </div>                             
                            </div>

                            <div className="notes_add_new_form_container">
                                <input type="text" placeholder="Title" onChange={(e) => this.setState({title: e.target.value})} defaultValue={this.state.title}/>
                                <textarea rows="10" placeholder="Write the text here..." onChange={(e) => this.setState({text: e.target.value})} defaultValue={this.state.text}></textarea>
                                <div className="button_full_blue" onClick={() => this.addNote()}>{multilanguage.save_note}</div>
                            </div>

                        </div>
                : null}

                {this.state.visibleDetail ? 
                        <div className="more_menu_container" style={{height:'100%'}}>
                            <div className="more_menu_container_inner">
                                <div className="more_menu_header">
                                <div className="order_detail_back_icon" onClick={() => this.setState({visibleDetail : false})}>
                                        <img src={require('../assets/images/arrowBack.svg')} />
                                    </div>
                                    <div className="header_my_account">{this.state.selectedNoteTitle}</div>
                                    <div className="morE_menu_left_white_space"></div>
                                </div>                             
                            </div>
                <div className="contract_big_content">{this.state.selectedNoteText}</div>
                            <div className="notes_add_new_form_container">              
                                <div className="button_full_blue" onClick={() => this.setState({visibleDetail: false})}>{multilanguage.delete_note}</div>
                            </div>

                        </div>
                : null}


           </div>
)}
}
