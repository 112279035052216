import React from 'react';
import multilanguage from '../multilanguage';


export default  class Notifications extends React.Component {

    constructor() {
        super();
        this.state={
           
        }
    }



    render() {
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.notifications}</div>
                </div>
               </div>

                <div className="notification_list">
                    <div className="notification_item_container">
                        <div className="notification_left_detele">
                            <img src={require('../assets/images/whiteclose.svg')} />
                        </div>
                        <div className="notification_item_content">
                            <div className="notification_item_line">
                                <div className="notification_item_tag yellow">{multilanguage.message_simple}</div>
                                <div className="notification_item_date">
                                    <img src={require('../assets/images/grayClock.svg')} />
                                    <span>24 Nov 2018 at 9:30 AM</span>
                                </div>
                            </div>
                            <div className="notification_item_title">Darren Smith {multilanguage.sent_a_message}</div>
                            <div className="notification_item_subtitle">Sed ut Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed magna commodo, tincidunt tellus ultrices, faucibus lectus.</div>
                        </div>
                    </div>

                    <div className="notification_item_container">
                        <div className="notification_left_detele">
                            <img src={require('../assets/images/whiteclose.svg')} />
                        </div>
                        <div className="notification_item_content">
                            <div className="notification_item_line">
                                <div className="notification_item_tag blue">{multilanguage.comment_simple}</div>
                                <div className="notification_item_date">
                                    <img src={require('../assets/images/grayClock.svg')} />
                                    <span>24 Nov 2018 at 9:30 AM</span>
                                </div>
                            </div>
                            <div className="notification_item_title">Olivia Wilson {multilanguage.commented_on_your_post}</div>
                            <div className="notification_item_subtitle">Sed ut Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed magna commodo, tincidunt tellus ultrices, faucibus lectus.</div>
                        </div>
                    </div>

                    <div className="notification_item_container">
                        <div className="notification_left_detele">
                            <img src={require('../assets/images/whiteclose.svg')} />
                        </div>
                        <div className="notification_item_content">
                            <div className="notification_item_line">
                                <div className="notification_item_tag green">{multilanguage.result_ready}</div>
                                <div className="notification_item_date">
                                    <img src={require('../assets/images/grayClock.svg')} />
                                    <span>24 Nov 2018 at 9:30 AM</span>
                                </div>
                            </div>
                            <div className="notification_item_title">{multilanguage.new_result_ready} on Maya Cooper</div>
                            <div className="notification_item_subtitle">Sed ut Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed magna commodo, tincidunt tellus ultrices, faucibus lectus.</div>
                        </div>
                    </div>
                </div>
           </div>
)}
}
