import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import auth from './api/auth';
import * as serviceWorker from './serviceWorker';
import multilanguage from './multilanguage';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// ReactDOM.render(<App />, document.getElementById('root'));
class Index extends React.Component{
state = {
    loading: true,
    auth: {logged: false}
}
    componentDidMount(){
        if (window.REACT_ENV == 'production') {
        }
        auth.check().then(response =>{
            this.setState({loading: false, auth: response})

        })
        .catch( response =>{
            this.setState({loading: false, auth: response})
        })
    }
    render() {
        if(this.state.loading){
            return null
        }
        return (
            <div id="index" className="index">
                <NotificationContainer/>
                <App auth={this.state.auth} />
            </div>
            
        )
        }
}
ReactDOM.render(<Index />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register();


