import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import multilanguage from '../multilanguage';

class DContract extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="desktop_container">
                <Menu screenProps={this.props.history} />
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} detail={true} />
                    <div className="d_main_content">
                        <div className="d_page_title_container_bordered">
                            <div className="d_page_title">{multilanguage.contract_details}</div>
                        </div>


                        <div className="d_contract_header_box">
                            <div className="d_contract_box_column">
                                <div className="d_contract_box_column_title">{multilanguage.name}</div>
                                <div className="d_contract_box_column_value">ANDA WILSON</div>
                            </div>
                            <div className="d_contract_box_column">
                                <div className="d_contract_box_column_title">{multilanguage.pesel_number}</div>
                                <div className="d_contract_box_column_value">29503264585956</div>
                            </div>
                            <div className="d_contract_box_column">
                                <div className="d_contract_box_column_title">{multilanguage.location_simple}</div>
                                <div className="d_contract_box_column_value">Centrul Synevo "Mama si Copilul"</div>
                            </div>
                            <div className="d_contract_box_column">
                                <div className="d_contract_box_column_title">{multilanguage.phone_simple}</div>
                                <div className="d_contract_box_column_value">/</div>
                            </div>
                            <div className="d_contract_box_column">
                                <div className="d_contract_box_column_title">{multilanguage.email_simple}</div>
                                <div className="d_contract_box_column_value">anda2000@yahoo.com</div>
                            </div>
                        </div>


                        <div className="d_contract_content">
                            <div className="d_contract_content_title">Lorem ipsum dolor sit amet</div>
                            <div className="d_contract_content_text">
                            Consectetur adipiscing elit. Nullam eget purus porttitor, semper nulla ut, laoreet erat. Cras convallis nisl accumsan sollicitudin volutpat. Maecenas congue sollicitudin ex, sit amet gravida purus maximus fermentum. Maecenas et ligula non nisi faucibus porta. Maecenas porta orci nunc. Pellentesque eu urna ligula. Sed libero odio, eleifend quis commodo vitae, dignissim quis magna. Duis pulvinar auctor pulvinar. Etiam dapibus auctor luctus. Vivamus vel justo vitae diam sollicitudin euismod.

Vestibulum eu nibh ut est congue vestibulum id vitae tortor. Integer placerat lectus lectus. Cras sed magna nisi. Fusce mattis magna dui, aliquet tincidunt arcu fermentum et. Donec fermentum porta nisl eu consequat. Fusce vel leo eu purus hendrerit suscipit. Nulla eget purus eu orci tempus efficitur. Cras dui tellus, egestas ac mattis pharetra, varius quis justo. Ut euismod dictum lorem, non volutpat massa euismod quis. Duis id iaculis eros. Quisque mollis augue nibh, sit amet eleifend augue efficitur sit amet. Proin vel nisi nec ligula tristique luctus vitae id lectus. Fusce at odio pellentesque, dignissim orci ut, ultrices velit.Cras sed magna nisi. Fusce mattis magna dui, aliquet tincidunt arcu fermentum et. Donec fermentum porta nisl eu consequat. Fusce vel leo eu purus hendrerit suscipit. Nulla eget purus eu orci tempus efficitur. Cras dui tellus, egestas ac mattis pharetra, varius quis justo. Ut euismod dictum lorem, non volutpat massa euismod quis. Duis id iaculis eros. Quisque mollis augue nibh, sit amet eleifend augue efficitur sit amet. Proin vel nisi nec ligula tristique luctus vitae id lectus. Fusce at odio pellentesque, dignissim orci ut, ultrices velit.Cras sed magna nisi. Fusce mattis magna dui, aliquet tincidunt arcu fermentum et. Donec fermentum porta nisl eu consequat. Fusce vel leo eu purus hendrerit suscipit. Nulla eget purus eu orci tempus efficitur. Cras dui tellus, egestas ac mattis pharetra, varius quis justo. Ut euismod dictum lorem, non volutpat massa euismod quis. Duis id iaculis eros. Quisque mollis augue nibh, sit amet eleifend augue efficitur sit amet. 
                            </div>
                            <div className="d_contract_content_title">Lorem ipsum dolor sit amet</div>
                            <div className="d_contract_content_text">
                            Proin vel nisi nec ligula tristique luctus vitae id lectus. Fusce at odio pellentesque, dignissim orci ut, ultrices velit.Cras sed magna nisi. Fusce mattis magna dui, aliquet tincidunt arcu fermentum et. Donec fermentum porta nisl eu consequat. Fusce vel leo eu purus hendrerit suscipit. Nulla eget purus eu orci tempus efficitur. Cras dui tellus, egestas ac mattis pharetra, varius quis justo. Ut euismod dictum lorem, non volutpat massa euismod quis. Duis id iaculis eros. Quisque mollis augue nibh, sit amet eleifend augue efficitur sit amet. Proin vel nisi nec ligula tristique luctus vitae id lectus. Fusce at odio pellentesque, dignissim orci ut, ultrices velit.Cras sed magna nisi. Fusce mattis magna dui, aliquet tincidunt arcu fermentum et. Donec fermentum porta nisl eu consequat. Fusce vel leo eu purus hendrerit suscipit. Nulla eget purus eu orci tempus efficitur. Cras dui tellus, egestas ac mattis pharetra, varius quis justo. Ut euismod dictum lorem, non volutpat massa euismod quis. Duis id iaculis eros. Quisque mollis augue nibh, sit amet eleifend augue efficitur sit amet. Proin vel nisi nec ligula tristique luctus vitae id lectus. Fusce at odio pellentesque, dignissim orci ut, ultrices velit.Cras sed magna nisi. Fusce mattis magna dui, aliquet tincidunt arcu fermentum et. Donec fermentum porta nisl eu consequat. Fusce vel leo eu purus hendrerit suscipit. Nulla eget purus eu orci tempus efficitur. Cras dui tellus, egestas ac mattis pharetra, varius quis justo. Ut euismod dictum lorem, non volutpat massa euismod quis. Duis id iaculis eros. Quisque mollis augue nibh, sit amet eleifend augue efficitur sit amet. Proin vel nisi nec ligula tristique luctus vitae id lectus. Fusce at odio pellentesque, dignissim orci ut, ultrices velit.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
 )}
}
 
export default DContract;