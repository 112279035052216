import React from 'react';
import NavBar from './parts/NavBar';
import Switch from './parts/Switch';
import Chart from 'chart.js';
import annotation from 'chartjs-plugin-annotation';
import multilanguage from '../multilanguage';
import emitter from 'tiny-emitter/instance';
import DLoader from "./parts/DLoader";
import api from '../api/api';
import auth from '../api/auth';
import moment from 'moment';
import MobileDetect from "mobile-detect"
import cart from '../api/cart';
import {NotificationManager} from 'react-notifications';
import { saveAs } from 'file-saver';

export default  class Result extends React.Component {

    constructor() {
        super();
        this.state={
        visibleNavbar:true,
           deficient: false,
           pdf: [],
           codes: '',
            showPdf: false,
            pdfurl: null,
            loading: true,
            testName: '',
            repeatTest: false,
            result: null,
           results:[
               {id: 1,
                name:'Acid 1,4–metil-imidazolacetic (metabolit histamina)',
                value:'32.75',
                unit:'mmol/L',
                type: 'minmax',
              },
              {id: 2,
                name:'Acid delta-aminolevulinic',
                value:'8.2',
                unit:'nmol/L',
                type: 'max',
              },
              {id: 3,
                name:'Calciu ionic',
                type: 'boolean',
                comment: true,
              },
              {id: 4,
                name:'Bilirubin total',
                type: 'comment',
              },
              {id: 5,
                name:'LDL Cholesterol (calculated)',
                type: 'pending',
                date:'ETA 24.06.2019',
              },
           ],
           visibleGraph: false,
        }
        this.canvasRef = React.createRef();
    }

    setVisibleGraph(){
        var inv = !this.state.visibleGraph;
        this.setState({visibleGraph: inv});
        var vthis = this;
        if(inv)
        setTimeout(() => {
            vthis.setVisibleGraphTime()
        }, 10);
    }
    componentDidMount(){
        
        
        const id = this.props.match.params.id;
        var testName = ''
        var codes = ''
        api.get('/ServiceResult/'+id)
            .then(res => {
                res.data.serviceRequest.services.map((item, index) =>{
                    if(index < res.data.serviceRequest.services.length-1)
                    codes +=item.code+','
                    else{
                    codes +=item.code
                    }
                })
                res.data.serviceResultList.map((nume, index)=>{
                    if(res.data.serviceResultList.length-1 == index)
                    testName = testName+nume.name
                    else testName = testName+nume.name+', '
                })
                this.setState({result: res.data, codes: codes, loading: false, testName: testName})
            })
        emitter.on("cartUpdate", ()=>{
            this.forceUpdate()
        })
    }
    
    setVisibleGraphTime(){
        var myChart = new Chart(document.getElementById('myChart').getContext('2d'), {
        type: 'line',
            data: {
                labels: ['12 April', '11 June', '12 August', '5 November', '24 December'],
                datasets: [{
                    label: 'mmol/L',
                    data: [40, 35, 60, 40, 70],
                    backgroundColor: [
                        'rgba(0, 84, 158, 0.1)',
                    ],
                    borderColor: [
                        '#00549E',
                    ],
                    borderWidth: 1,
                    lineTension: 0.00001,
                    pointBackgroundColor: '#00549E',
                    pointBorderColor: '#00549E',
                    pointHitRadius: 15,
                    pointBorderWidth: 1,
                    yAxisID: 'y-axis-1',
                }]
            },
            options: {
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        type: 'linear',
                        display: false,
                        position: 'left',
                        id: 'y-axis-1',
                    }],
                    xAxes: [
                        {
                            display: false,
                        }
                    ],           
                },
                annotation: {
                    annotations: [{
                        drawTime: 'afterDatasetsDraw',
                        id: 'a-line-1',
                        type: 'box',
                        yScaleID: 'y-axis-1',
                        yMax: 55,
                        yMin: 40,
                        borderColor: 'rgba(144, 181, 87, 0.4)',
                        backgroundColor: 'rgba(144, 181, 87, 0.4)',
                        borderWidth: 0,
                    }]
                },
            },
        });
}

    renderResults = (items) => {
        return items.map((item,index) => {
            if(item.type == 'minmax')
            return(
                <div key={item.id} className="result_item_container">
                   <div className="result_item_top_mobile" onClick={() => this.props.history.push('/result_detail')}>
                       <div className="result_item_title">{item.name}</div>
                       <div className="result_item_right">
                           <div className="result_item_value red">{item.value}</div>
                       </div>
                   </div>
                   <div className="result_detail_progressbar_container">
                        
                        <div className="result_detetail_progressbar_part firstred">
                            <div className="result_item_unit_left">2</div>
                            <img className="result_progressbar_indicator_failed" style={{left:'80%'}} src={require('../assets/images/blueIndicator.svg')} />
                        </div>
                        <div className="result_detetail_progressbar_part middlegreen"><img className="result_progressbar_indicator" style={{left:'80%'}} src={require('../assets/images/blueIndicator.svg')} /></div>
                        <div className="result_detetail_progressbar_part lastred">
                            <div className="result_item_unit_right">2</div>
                            <img className="result_progressbar_indicator_failed" style={{left:'80%'}} src={require('../assets/images/blueIndicator.svg')} /></div>
                   </div>
                   <div className="result_detail_more_graph_button" onClick={() => this.setVisibleGraph()}>
                        <img src={require('../assets/images/moreGraph.svg')} />
                   </div>
                   { this.state.visibleGraph  ?
                        <div>
                            <canvas id="myChart" ref={this.canvasRef} width="500" height="200">
                            </canvas>
                        </div>
                    : null}
                </div>
            )
            if(item.type == 'max')
            return(
                <div key={item.id} className="result_item_container">
                   <div className="result_item_top" onClick={() => this.props.history.push('/result_detail')}>
                       <div className="result_item_title">{item.name}</div>
                       <div className="result_item_right">
                           <div className="result_item_value">{item.value}</div>
                           <div className="result_item_unit">{item.unit}</div>
                       </div>
                   </div>
                   <div className="result_detail_progressbar_container">
                        
                        <div className="result_detetail_progressbar_part firstgreen"><img className="result_progressbar_indicator" style={{left:'40%'}} src={require('../assets/images/blueIndicator.svg')} /></div>
                        <div className="result_detetail_progressbar_part lastred"><img className="result_progressbar_indicator" style={{left:'40%'}} src={require('../assets/images/blueIndicator.svg')} /></div>
                   </div>
                   <div className="result_detail_more_graph_button">
                        <img src={require('../assets/images/moreGraph.svg')} />
                   </div>
                </div>
            )
            if(item.type == 'boolean')
            return(
                <div key={item.id} className="result_item_container" onClick={() => this.props.history.push('/result_detail')}>
                <div className="result_item_container_boolean">
                   <div className="result_item_bool_left">
                       <div className="result_item_bool_point"></div>
                       <div>
                            <div className="result_item_title">{item.name}</div>
                            <div className="result_item_read_below">{multilanguage.read_comment_below}</div>
                       </div>
                   </div>
                   <div className="result_item_bool_right">{multilanguage.your_result_is_negative}</div>
                </div>
                </div>
            )
            if(item.type == 'comment')
            return(
                <div key={item.id} className="result_item_container" onClick={() => this.props.history.push('/result_detail')}>
                <div className="result_item_container_boolean">
                    <div className="result_item_title">{item.name}</div>
                   <div className="result_item_comment_right">{multilanguage.read_more} <img src={require('../assets/images/whiteArrow.svg')} /></div>
                </div>
                </div>
            )
            if(item.type == 'pending')
            return(
                <div key={item.id} className="result_item_container" onClick={() => this.props.history.push('/result_detail')}>
                <div className="result_item_container_boolean">
                    <div>
                        <div className="result_item_title">{item.name}</div>
                        <div className="result_item_read_below">ETA 24.06.2019</div>
                    </div>
                   <div className="result_item_pending_right">{multilanguage.result_is_pending}</div>
                </div>
                </div>
        )})
    }

    addComment(){
        this.setState({visibleNavbar: false});
        document.getElementById('add_comment').style.display = 'flex';
    }
    doneComment(){
        this.setState({visibleNavbar: true});
        document.getElementById('add_comment').style.display = 'none';
    }

    openPdf(){
        var md = new MobileDetect(window.navigator.userAgent);
        api.get('/ServiceResult/'+this.props.match.params.id+'/pdf/base64')
            .then(res => {
                var base64str = res.data;
                // decode base64 string, remove space for IE compatibility
                var binary = atob(base64str.replace(/\s/g, ''));
                var len = binary.length;
                var buffer = new ArrayBuffer(len);
                var view = new Uint8Array(buffer);
                for (var i = 0; i < len; i++) {
                    view[i] = binary.charCodeAt(i);
                }

                // create the blob object with content-type "application/pdf"               
                var blob = new Blob( [view], { type: "application/pdf" });
                
                var url = window.URL.createObjectURL(blob);
                this.setState({pdfurl: url})
                // var data = new Blob([res.data], {type: "application/pdf"})
                // var url = URL.createObjectURL(data)
                // this.setState({pdfurl: url})
                // console.log(res.data)
                // window.open(this.state.pdfurl)
                // window.location = this.state.pdfurl
                if(md.os() != "iOS"){
                    window.open(this.state.pdfurl)
                    }else{
                        var FileSaver = require('file-saver');
                        FileSaver.saveAs(blob, "pdf.pdf");
                }
                
                
                  
        })
    }
    repeatTest(){
        api.get('/ServiceKnowledgebase/serviceByCodeMaterial', {serviceCodeMaterialList: this.state.codes})
        .then(res => {
            cart.empty()
            res.data.map((item)=>{
                cart.addItem(item)
            })
            cart.addPatient(this.state.result.patient)
            this.setState({repeatTest: true})
        })
    } 
    render() {
        return (
           <div className="app_container">
                <div id="add_comment" className="add_comment_container">
                    <div className="add_comment_container_inner">
                        <div className="add_comment_top">
                            <span>Urine test</span>
                            <div className="close_more_menu" onClick={() => this.doneComment()}>
                                <img src={require('../assets/images/closeMenu.svg')} />
                            </div>
                        </div>
                        <textarea rows="7" placeholder="Write your comment here.."></textarea>
                        <button onClick={() => this.doneComment()}>{multilanguage.save_comment}</button>
                    </div>
                </div>
               {/* HEADER */}
                <div className="home_header">
                <div className="home_header_inner">
                    <div className="order_detail_back_icon" onClick={() => this.props.history.goBack()}>
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    {this.state.loading ? null :
                   <div className="order_detail_header_title">{((this.state.result.display).length > 70) ? (((this.state.result.display).substring(0,70-3)) + '...') : this.state.result.display}</div>
                    }
                    <div className="morE_menu_left_white_space"></div>
                </div>
               </div>
               {this.state.loading ? null :
               <div className="store_section_title store_section_title_first">{this.state.result.patient.firstName} {this.state.result.patient.lastName}</div>}
               {this.state.loading ? <DLoader/> :
                <div className="result_detail_container">
                     <div className="result_detail_top_blue_bar">
                         <div className="result_blue_button">{multilanguage.save_comment}: <span>26</span></div>
                         <div className="result_top_blue_bar_hr"></div>
                         <div className="result_blue_button">{multilanguage.recoltation_date}: <span>{moment(this.state.result.issued).format('DD.MM.YYYY')}</span></div>
                     </div>
                     <div className="result_detail_top_gray_bar">
                        <img src={require('../assets/images/blackPin.svg')} />
                         <span>{this.state.result.labComment}</span>
                     </div>
                     <div className="result_detail_actions_container">
                        {/* <div className="result_detail_action_button" onClick={() => this.addComment()}>
                            <img src={require('../assets/images/blackPlus.svg')} />
                            <span>{multilanguage.add_comment}</span>
                        </div> */}
                        <div className="result_detail_action_button active" onClick={() => this.openPdf()}>
                            <img src={require('../assets/images/blackPrint.svg')} />
                            <span>{multilanguage.download}</span>
                        </div>
                        <div className="result_detail_action_button" onClick={() => this.repeatTest()}>
                            <img src={require('../assets/images/blackRepeat.svg')} />
                            <span>{multilanguage.repeat_test}</span>
                        </div>
                     </div>
                     {this.state.result.serviceResultList.map((service)=>{

//  console.log("resultlist", result)
 return(
    <div>
        <div className="d_result_title">{service.name}</div>
        <div key={service.id} className="result_item_container">
    {service.parameterList.map((params)=>{
        if(params.referenceRange && params.value){
        if(params.referenceRange.low && params.referenceRange.high){
            var minValue = parseFloat(params.referenceRange.low)
            var actualValue = parseFloat(params.value)-minValue
            var maxValue = parseFloat(params.referenceRange.high)-minValue

          
            var value = Math.round((actualValue *100)/(maxValue))
         
            


            return(
                
                <div key={params.id} className="result_item_container_inner">
                    <div className="result_item_top_mobile">
                       <div className="result_item_title_mobile">{params.name}</div>
                       <div className="result_item_right">
                            {parseFloat(params.value) < parseFloat(params.referenceRange.low) || parseFloat(params.value) > parseFloat(params.referenceRange.high) ? 
                            <div className="result_item_value red">{params.value}</div> 
                            :
                            <div className="result_item_value green">{params.value}</div>}
                        </div>
                   </div>
                <div className="result_detail_progressbar_container">
                    <div className="result_detetail_progressbar_part firstred">
                        <div className="result_item_unit_right">{params.referenceRange.low}</div>
                        {parseFloat(params.value) < parseFloat(params.referenceRange.low) ? 
                        <img className="result_progressbar_indicator_failed" style={{left:"40%"}} src={require('../assets/images/blueIndicator.svg')} />
                        : null}
                    </div>
                    <div className="result_detetail_progressbar_part middlegreen">
                        {parseFloat(params.value) < parseFloat(params.referenceRange.low) || parseFloat(params.value) > parseFloat(params.referenceRange.high) ? null :
                        <img className="result_progressbar_indicator" style={{left:value-10+"%"}} src={require('../assets/images/blueIndicator.svg')} />}
                    </div>
                    <div className="result_detetail_progressbar_part lastred">
                        <div className="result_item_unit_right">{params.referenceRange.high}</div>
                            {parseFloat(params.value) > parseFloat(params.referenceRange.high) ? 
                            <img className="result_progressbar_indicator_failed" style={{left:"40%"}} src={require('../assets/images/blueIndicator.svg')} />
                            : null}
                        </div>
                </div>
             </div>
                
                
            )
        }else if(!params.referenceRange.low && params.referenceRange.high && params.referenceRange.text){
            return(
                <div key={params.id} className="result_item_container_inner">
                    <div className="result_item_top_mobile">
                        <div className="result_item_title_mobile">{params.name}</div>
                        <div className="result_item_right">
                            {parseFloat(params.value) <= parseFloat(params.referenceRange.high) ? 
                            <div className="result_item_value green">{params.value}</div>
                            :
                            <div className="result_item_value red">{params.value}</div>}
                        </div>
                    </div>
                    <div className="result_detail_progressbar_container">
                        <div className="result_detetail_progressbar_part firstgreen">
                            {parseFloat(params.value) <= parseFloat(params.referenceRange.high) ? 
                            <img className="result_progressbar_indicator" style={{left:"40%"}} src={require('../assets/images/blueIndicator.svg')} />
                            : null}
                        </div>
                        <div className="result_detetail_progressbar_part lastred">
                            <div className="result_item_unit_left">{params.referenceRange.high}</div>
                                {parseFloat(params.value) <= parseFloat(params.referenceRange.high) ? null :
                                <img className="result_progressbar_indicator_failed" style={{left:"40%"}} src={require('../assets/images/blueIndicator.svg')} />}
                            </div>
                    </div>
            </div> 
            )
        }else if(params.referenceRange.low && !params.referenceRange.high && params.referenceRange.text){
            
            return(
            <div key={params.id} className="result_item_container_inner">
                <div className="result_item_top_mobile">
                    <div className="result_item_title_mobile">{params.name}</div>
                    <div className="result_item_right">
                        {parseFloat(params.value) <= parseFloat(params.referenceRange.low) ? 
                        <div className="result_item_value red">{params.value}</div>
                        :
                        <div className="result_item_value green">{params.value}</div>}
                </div>
            </div>
        <div className="result_detail_progressbar_container">
            <div className="result_detetail_progressbar_part firstred">
                <div className="result_item_unit_right">{params.referenceRange.low}</div>
            {parseFloat(params.value) < parseFloat(params.referenceRange.low) ? 
            <img className="result_progressbar_indicator_failed" style={{left:"40%"}} src={require('../assets/images/blueIndicator.svg')} />
            : null}
            </div>
            <div className="result_detetail_progressbar_part lastgreen">
                {parseFloat(params.value) <= parseFloat(params.referenceRange.low) ? null :
                <img className="result_progressbar_indicator" style={{left:"40%"}} src={require('../assets/images/blueIndicator.svg')} />}
                
                </div>
        </div>
    </div>
            )
        }else if(!params.referenceRange.low && !params.referenceRange.high && params.referenceRange.text){
            return(
                <div key={params.id} className="result_item_container_inner" >
                    <div className="result_item_container_boolean">
                        <div className="result_item_bool_left">
                            {/* <div className="result_item_bool_point"></div> */}
                            <div>
                                    <div className="result_item_title">{params.name}</div>
                                    {/* <div className="result_item_read_below">Read comment below</div> */}
                            </div>
                        </div>
                    <div className="result_item_bool_right">{params.value}</div>
                    </div>
                </div>
                
            )
        }
    }else if(!params.referenceRange && params.value){
            return(
                <div key={params.id} className="result_item_container_inner" >
                    <div className="result_item_container_boolean">
                        <div className="result_item_bool_left">
                            {/* <div className="result_item_bool_point"></div> */}
                            <div>
                                    <div className="result_item_title">{params.name}</div>
                                    {/* <div className="result_item_read_below">Read comment below</div> */}
                            </div>
                        </div>
                    <div className="result_item_bool_right">{params.value}</div>
                    </div>
                </div>

                
            )
            
        }
        
     })}
     </div>
     </div>
 )
})}
                </div>}
                {this.state.repeatTest ? 
                <div id="cart_button_fixed" className="cart_button_fixed" onClick={() => this.props.history.push('/cart')}>
                    <img src={require('../assets/images/plusShape.svg')} />
                    <span>{multilanguage.go_to_cart}</span>
                </div>  : null }

                {this.state.visibleNavbar ?
                  <NavBar navigation={this.props.history} />
                  : null}

           </div>
)}
}
