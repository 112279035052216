import React, { Component } from "react";
import Header from "./parts/Header";
import Menu from "./parts/Menu";
import multilanguage from '../multilanguage';
import api from '../api/api';
import auth from '../api/auth';
import moment from 'moment';
import DLoader from "./parts/DLoader";
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component';
import {NotificationContainer, NotificationManager} from 'react-notifications';

class DResults extends Component {
  constructor(props) {
    super(props)
    this.doSearch = this.doSearch.bind(this)
    this.search = this.search.bind(this)
  }
  state = {
    loading: true,
    results: [],
    search: null,
    numberOfPages: 0,
    selectedPage: 1,
    items: [],
    infinitePcrollPage: 1,
    hasMore: true,
  };

  componentDidMount(){
    if(this.props.location.state){
      this.setState({value: this.props.location.state.pesel})
      this.doSearch(this.props.location.state.pesel)
    }else{
      this.doSearch('')
    }
  }
  searchtimeout = null
  doSearch(search){
    if(this.state.value == search)
    this.setState({loading: true, selectedPage: this.state.infinitePcrollPage})
    else
    this.setState({loading: true, items: [], selectedPage: 1, value: search})
    api.get('/ServiceResult', {searchQuery: search, page: this.state.infinitePcrollPage})
            .then(res => {
              var numberOfPages = res.data.totalResults/res.data.pageSize
                    if(Number.isInteger(numberOfPages)){
                        this.setState({
                          loading: false,
                          items: this.state.items.concat(res.data.results),
                          numberOfPages : numberOfPages,
                        })
                    }else{
                        numberOfPages = Math.floor(numberOfPages)+1
                        this.setState({
                          loading: false,
                          items: this.state.items.concat(res.data.results),
                          numberOfPages : numberOfPages,
                        })
                    }
                    if(this.state.infinitePcrollPage < numberOfPages){
                      this.setState({infinitePcrollPage: this.state.infinitePcrollPage+1})
                  }else{
                      this.setState({hasMore: false})
                  }
            })
  }
  search(search){
    this.setState({items:[], infinitePcrollPage: 1, value: search})
    if (this.searchtimeout) clearTimeout(this.searchtimeout)
    this.searchtimeout = setTimeout(() => this.doSearch(search), 1000);
  }
  render() {
    return (
      <div className="desktop_container">
        <Menu screenProps={this.props.history} />
        <div className="desktop_container_content">
          <Header screenProps={this.props.history} />
          <div className="d_main_content">
            
            <div className="d_page_title_container">
                <div className="d_page_title_with_button">
                    <div className="d_page_title">{multilanguage.results}</div>
                </div>
              
              <div className="d_page_title_right_flex">
                {/* <div className="d_page_title_sort">
                  <span>{multilanguage.sort_by}: </span>
                  <select>
                    <option>{multilanguage.newest_simple}</option>
                    <option>{multilanguage.type_simple}</option>
                  </select>              
                </div> */}
                <div className="d_page_title_search_container">
                    <img src={require('../assets/images/desktop/search1.svg')} />
                      <input type="text"
                      defaultValue={this.state.value}
                      onChange={evt => this.search(evt.target.value)}
                      disabled={this.state.loading}
                      />
                </div>
              </div>

            </div>
            <div className="">

                <div className="dpat_table_header">
                    <div className="dpath_result_name">{multilanguage.name_results_patients}</div>
                    <div className="dpath_name name-margin">{multilanguage.patient}</div>
                    <div className="dpath_nr">{multilanguage.pesel_number} </div>
                    <div className="dpath_email">{multilanguage.sampling_date} </div>
                    <div className="dpath_city">{multilanguage.status_simple} </div>
                </div>
                {this.state.loading && !this.state.hasMore ? <DLoader /> : null}
                <InfiniteScroll
                  dataLength={this.state.items.length} //This is important field to render the next data
                  next={() => {if(!this.state.loading) this.doSearch(this.state.value)}}
                  hasMore={this.state.hasMore}
                  scrollableTarget="index"
                  scrollThreshold= {0.9}
                  loader={<DLoader />}
                  children
                  >
                  {this.state.items.map((rezultat)=>{
                      return(
                        <div className="dpat_table_line">
                        <div className="dpath_result_name_line" onClick={() => rezultat.status == 'Ready' ? this.props.history.push('/result_detail/'+rezultat.id) : NotificationManager.warning(multilanguage.notification_pending, '',1000)}>
                            <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M18.9415 0H5.05855C2.24824 0 0 2.20859 0 4.96933V22.0307C0 24.7914 2.24824 27 5.05855 27H13.7705C15.7939 27 17.7611 26.1166 19.1101 24.6258L22.2014 21.2025C23.3255 19.9325 24 18.2761 24 16.5644V4.96933C24 2.20859 21.6956 0 18.9415 0ZM5.05855 25.3436C3.20375 25.3436 1.68618 23.8528 1.68618 22.0307V4.96933C1.68618 3.14724 3.20375 1.65644 5.05855 1.65644H18.9415C20.7963 1.65644 22.3138 3.14724 22.3138 4.96933V16.6196C22.3138 16.8957 22.3138 17.1166 22.2576 17.3926C22.2343 17.3926 22.2014 17.3832 22.1628 17.3721C22.1083 17.3564 22.0424 17.3374 21.9766 17.3374H17.1991C15.8501 17.3374 14.7822 18.3865 14.726 19.7117L14.6136 25.2883C14.3326 25.3436 14.0515 25.3436 13.8267 25.3436H5.05855ZM16.356 24.7362C16.918 24.4601 17.4239 24.0736 17.8735 23.5767L20.9649 20.0982C21.3021 19.7669 21.5269 19.3804 21.7517 18.9939L21.7518 18.9939H17.2553C16.8056 18.9939 16.4684 19.3804 16.4684 19.7669L16.356 24.7362Z" fill="#15112C"/>
                              <path d="M6.83333 10H17.1667C17.6111 10 18 9.53333 18 9C18 8.46667 17.6111 8 17.1667 8H6.83333C6.38889 8 6 8.46667 6 9C6 9.53333 6.38889 10 6.83333 10Z" fill="#15112C"/>
                              <path d="M6.83333 14H17.1667C17.6111 14 18 13.5333 18 13C18 12.4667 17.6111 12 17.1667 12H6.83333C6.38889 12 6 12.4667 6 13C6 13.5333 6.38889 14 6.83333 14Z" fill="#15112C"/>
                              <path d="M12.1743 15H7.82569C7.38532 15 7 15.4667 7 16C7 16.5333 7.38532 17 7.82569 17H12.1743C12.6147 17 13 16.5333 13 16C13 15.4667 12.6147 15 12.1743 15Z" fill="#15112C"/>
                            </svg>
                              <span>{((rezultat.display)?.length > 70) ? (((rezultat.display).substring(0,70-3)) + '...') : rezultat.display}</span>
                          </div>
                          <div className="name-margin dpath_name_line" onClick={() => this.props.history.push('/patient_detail/'+rezultat.patient.id)}>
                              <img className="d_header_left_avatar" src={rezultat.patient.gender == 'F' ? require('../assets/images/famale.png') : require('../assets/images/male.png')} />
                              <span className="">{rezultat.patient.firstName} {rezultat.patient.lastName}</span>
                          </div>
                          
                          {rezultat.patient.externalIds.map((external)=>{   
                            if(external.system == 'PESEL'){
                              return(
                                <div className="dpath_nr_line">{external.value} </div>
                              )
                            }
                          })}
                          <div className="dpath_date_line">
                            {moment(rezultat.issued).format('DD.MM.YYYY')}
                          
                          </div>
                          <div className="dpath_status_line">
                            {rezultat.status == 'Ready' ? 
                            <div className="d_result_ready" onClick={() => this.props.history.push('/result_detail/'+rezultat.id)}>{multilanguage.ready_simple}</div>
                            :
                            <div className="d_result_pending" onClick={() => NotificationManager.warning(multilanguage.notification_pending, '',1000)}>{multilanguage.pending}</div>}
                          </div>
                          {rezultat.status == 'Ready' ? 
                          <div className="dpath_details" onClick={() => this.props.history.push('/result_detail/'+rezultat.id)}>
                          <div className="dpath_details_button dpath_details_buttons">{multilanguage.details_simple}</div>
                      </div>
                            :
                            <div className="dpath_details" onClick={() => NotificationManager.warning(multilanguage.notification_pending, '',1000)}>
                              <div className="dpath_details_button dpath_details_buttons">{multilanguage.details_simple}</div>
                          </div>}
                      </div>
                      )
                  })}
                </InfiniteScroll>
                {/* {this.state.loading ? <DLoader /> : null}
                {this.state.results.map((rezultat) => {
                  return(
                    <div className="dpat_table_line">
                    <div className="dpath_result_name_line">
                        <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M18.9415 0H5.05855C2.24824 0 0 2.20859 0 4.96933V22.0307C0 24.7914 2.24824 27 5.05855 27H13.7705C15.7939 27 17.7611 26.1166 19.1101 24.6258L22.2014 21.2025C23.3255 19.9325 24 18.2761 24 16.5644V4.96933C24 2.20859 21.6956 0 18.9415 0ZM5.05855 25.3436C3.20375 25.3436 1.68618 23.8528 1.68618 22.0307V4.96933C1.68618 3.14724 3.20375 1.65644 5.05855 1.65644H18.9415C20.7963 1.65644 22.3138 3.14724 22.3138 4.96933V16.6196C22.3138 16.8957 22.3138 17.1166 22.2576 17.3926C22.2343 17.3926 22.2014 17.3832 22.1628 17.3721C22.1083 17.3564 22.0424 17.3374 21.9766 17.3374H17.1991C15.8501 17.3374 14.7822 18.3865 14.726 19.7117L14.6136 25.2883C14.3326 25.3436 14.0515 25.3436 13.8267 25.3436H5.05855ZM16.356 24.7362C16.918 24.4601 17.4239 24.0736 17.8735 23.5767L20.9649 20.0982C21.3021 19.7669 21.5269 19.3804 21.7517 18.9939L21.7518 18.9939H17.2553C16.8056 18.9939 16.4684 19.3804 16.4684 19.7669L16.356 24.7362Z" fill="#15112C"/>
                          <path d="M6.83333 10H17.1667C17.6111 10 18 9.53333 18 9C18 8.46667 17.6111 8 17.1667 8H6.83333C6.38889 8 6 8.46667 6 9C6 9.53333 6.38889 10 6.83333 10Z" fill="#15112C"/>
                          <path d="M6.83333 14H17.1667C17.6111 14 18 13.5333 18 13C18 12.4667 17.6111 12 17.1667 12H6.83333C6.38889 12 6 12.4667 6 13C6 13.5333 6.38889 14 6.83333 14Z" fill="#15112C"/>
                          <path d="M12.1743 15H7.82569C7.38532 15 7 15.4667 7 16C7 16.5333 7.38532 17 7.82569 17H12.1743C12.6147 17 13 16.5333 13 16C13 15.4667 12.6147 15 12.1743 15Z" fill="#15112C"/>
                        </svg>
                          <span>{((rezultat.display).length > 70) ? (((rezultat.display).substring(0,70-3)) + '...') : rezultat.display}</span>
                      </div>
                      <div className="dpath_name_line" onClick={() => this.props.history.push('/patient_detail/'+rezultat.patient.id)}>
                          <img className="d_header_left_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                          <span>{rezultat.patient.firstName} {rezultat.patient.lastName}</span>
                      </div>
                      
                      {rezultat.patient.externalIds.map((external)=>{   
                        if(external.system == 'PESEL'){
                          return(
                            <div className="dpath_nr_line">{external.value} </div>
                          )
                        }
                      })}
                      <div className="dpath_email_line">
                        {moment(rezultat.issued).format('DD MMMM YYYY')}
                      
                      </div>
                      <div className="dpath_city_line">
                        {rezultat.status == 'Ready' ? 
                        <div className="d_result_ready">{multilanguage.ready_simple}</div>
                        :
                        <div className="d_result_pending">{multilanguage.pending}</div>}
                      </div>
                      {rezultat.status == 'Ready' ? 
                      <div className="dpath_details" onClick={() => this.props.history.push('/result_detail/'+rezultat.id)}>
                      <div className="dpath_details_button">{multilanguage.details_simple}</div>
                  </div>
                        :
                        <div className="dpath_details" >
                          <div className="dpath_details_button">{multilanguage.details_simple}</div>
                      </div>}
                  </div>
                  )
                })} */}
                {/* {this.state.loading || !this.state.results || this.state.numberOfPages <= 1 ? null :
                  <div class="pagination">
                    <div className="d_store_menu_buttons" onClick={() => this.doSearch(this.state.value, this.state.selectedPage > 1 ?  this.state.selectedPage-1 : this.state.selectedPage)}>
                        <span>{multilanguage.previous_page}</span>
                    </div>
                    <div className="d_store_menu_buttons" onClick={() => this.doSearch(this.state.value, this.state.selectedPage < this.state.numberOfPages ?  this.state.selectedPage+1 : this.state.selectedPage)}>
                        <span>{multilanguage.next_page}</span>
                  </div> 
                </div> } */}
            </div>
          </div>
        </div>
      </div>
)}
}

export default DResults;
