import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import Switch from '../components/parts/Switch';
import api from '../api/api';
import auth from '../api/auth';
import multilanguage from '../multilanguage';

class DMyLocation extends Component {
    constructor() {
        super();
        this.state={
            selected : 1,
        }
    }

    componentDidMount(){
            api.get('/Profile/location')
            .then(res => {
            })
    }

    changeSelected(id){
        this.setState({selected : id})
    }


    render() { 
        return ( 
            <div className="desktop_container">
                <Menu screenProps={this.props.history} />
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} />
                    <div className="d_main_content">
                    <div className="d_page_title_container_bordered">
                        <div className="d_page_title">{multilanguage.my_location}</div>
                        <div className="dp_save_button">
                            <span>{multilanguage.save_changes}</span>
                        </div>
                    </div>

                <div className="d_settings_container">

                <div className="d_settings_container_left">
  
                  <div className="dep_input_table">
                        <div className="dep_input_column_location">
                            <div className="dep_input_container">
                                <div className="dep_input_label">{multilanguage.name_of_the_facility}</div>
                                <input className="dep_input" type="text" />
                            </div>
                            <div className="dep_input_container">
                                <div className="dep_input_label">{multilanguage.post_code}</div>
                                <input className="dep_input" type="text"  />
                            </div>
                        </div>
                        <div className="dep_input_column_location">
                            <div className="dep_input_container">
                                <div className="dep_input_label">{multilanguage.street_number}</div>
                                <input className="dep_input" type="text"  />
                            </div>
                            <div className="dep_input_container">
                                <div className="dep_input_label">{multilanguage.city}</div>
                                <input className="dep_input" type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="myLocationLabel">{multilanguage.type_of_the_facility}</div>

                        <div className="myLocation_boxed_container">

                            <div className={this.state.selected == 1 ? "myLocation_box_item selected" :  "myLocation_box_item"} onClick={() => this.changeSelected(1)}>
                                <div className="myLocation_box_item_top_box">
                                     <img src={require('../assets/images/blueHospital.svg')} />
                                </div>
                                <span className="myLocation_box_item_name">{multilanguage.hospital}</span>
                            </div>

                            <div className={this.state.selected == 2 ? "myLocation_box_item selected" :  "myLocation_box_item"} onClick={() => this.changeSelected(2)}>
                                <div className="myLocation_box_item_top_box">
                                     <img src={require('../assets/images/blueCabinet.svg')} />
                                </div>
                                <span className="myLocation_box_item_name">{multilanguage.private_cabinet}</span>
                            </div>

                            <div className={this.state.selected == 3 ? "myLocation_box_item selected" :  "myLocation_box_item"} onClick={() => this.changeSelected(3)}>
                                <div className="myLocation_box_item_top_box">
                                     <img src={require('../assets/images/blueClinic.svg')} />
                                </div>
                                <span className="myLocation_box_item_name">{multilanguage.clinic}</span>
                            </div>

                            <div className={this.state.selected == 4 ? "myLocation_box_item selected" :  "myLocation_box_item"} onClick={() => this.changeSelected(4)}>
                                <div className="myLocation_box_item_top_box">
                                     <img src={require('../assets/images/blueHealthcare.svg')} />
                                </div>
                                <span className="myLocation_box_item_name">{multilanguage.healthcare_facility}</span>
                            </div>
                        </div>

                </div>

                    <div className="d_settings_container_right">

                        <div className="d_settings_container_right_title">{multilanguage.saved_locations}</div>

                        <div className="d_settings_right_addresses">
                            <div className="d_settings_right_address_item">
                                <div className="d_stradr_item_title">Address 1</div>
                                <div className="d_stradr_item_label">{multilanguage.name_of_the_facility}</div>
                                <div className="d_stradr_item_value">Cabinet privat Doctor Roberts</div>
                                <div className="d_stradr_item_label">{multilanguage.street_number}</div>
                                <div className="d_stradr_item_value">1424 Sherwood Circle</div>
                                <div className="d_stradr_item_label">{multilanguage.post_code}</div>
                                <div className="d_stradr_item_value">70583</div>
                                <div className="d_stradr_item_label">{multilanguage.city}</div>
                                <div className="d_stradr_item_value">Bucharest</div>
                                <div className="d_stradr_item_label">{multilanguage.type_of_the_facility}</div>
                                <div className="d_stradr_item_value">{multilanguage.private_cabinet}</div>
                                <div className="d_stradr_item_edit">{multilanguage.edit_simple}</div>
                            </div>
                            <div className="d_settings_right_address_item">
                                <div className="d_stradr_item_title">Address 2</div>
                                <div className="d_stradr_item_label">{multilanguage.name_of_the_facility}</div>
                                <div className="d_stradr_item_value">Cabinet privat Doctor Roberts</div>
                                <div className="d_stradr_item_label">{multilanguage.street_number}</div>
                                <div className="d_stradr_item_value">1424 Sherwood Circle</div>
                                <div className="d_stradr_item_label">{multilanguage.post_code}</div>
                                <div className="d_stradr_item_value">70583</div>
                                <div className="d_stradr_item_label">{multilanguage.city}</div>
                                <div className="d_stradr_item_value">Bucharest</div>
                                <div className="d_stradr_item_label">{multilanguage.type_of_the_facility}</div>
                                <div className="d_stradr_item_value">{multilanguage.private_cabinet}</div>
                                <div className="d_stradr_item_edit">{multilanguage.edit_simple}</div>
                            </div>
                        </div>

                    </div>

                </div>


                    </div>
                </div>
            </div>
 )}
}
 
export default DMyLocation;