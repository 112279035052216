import React from 'react';
import Chart from 'chart.js';
import annotation from 'chartjs-plugin-annotation';
import multilanguage from '../multilanguage';

export default  class ResultDetail extends React.Component {

    constructor() {
        super();
        this.state={

        }
        this.canvasRef = React.createRef();
    }
    componentDidMount(){
        this.setVisibleGraphTime();
    }
    setVisibleGraphTime(){
        var myChart = new Chart(document.getElementById('myChart').getContext('2d'), {
        type: 'line',
            data: {
                labels: ['12 April', '11 June', '12 August', '5 November', '24 December'],
                datasets: [{
                    label: 'mmol/L',
                    data: [40, 35, 60, 40, 70],
                    backgroundColor: [
                        'rgba(0, 84, 158, 0.1)',
                    ],
                    borderColor: [
                        '#00549E',
                    ],
                    borderWidth: 1,
                    lineTension: 0.00001,
                    pointBackgroundColor: '#00549E',
                    pointBorderColor: '#00549E',
                    pointHitRadius: 15,
                    pointBorderWidth: 1,
                    yAxisID: 'y-axis-1',
                }]
            },
            options: {
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        type: 'linear',
                        display: false,
                        position: 'left',
                        id: 'y-axis-1',
                    }],
                    xAxes: [
                        {
                            display: false,
                        }
                    ],           
                },
                annotation: {
                    annotations: [{
                        drawTime: 'afterDatasetsDraw',
                        id: 'a-line-1',
                        type: 'box',
                        yScaleID: 'y-axis-1',
                        yMax: 55,
                        yMin: 40,
                        borderColor: 'rgba(144, 181, 87, 0.4)',
                        backgroundColor: 'rgba(144, 181, 87, 0.4)',
                        borderWidth: 0,
                    }]
                },
            },
        });
    }

    addComment(){
        document.getElementById('add_comment').style.display = 'flex';
    }
    doneComment(){
        document.getElementById('add_comment').style.display = 'none';
    }

    render() {
        return (
           <div className="app_container">
                <div id="add_comment" className="add_comment_container">
                    <div className="add_comment_container_inner">
                        <div className="add_comment_top">
                            <span>Urine test</span>
                            <div className="close_more_menu" onClick={() => this.doneComment()}>
                                <img src={require('../assets/images/closeMenu.svg')} />
                            </div>
                        </div>
                        <textarea rows="7" placeholder="Write your comment here.."></textarea>
                        <button onClick={() => this.doneComment()}>{multilanguage.save_comment}</button>
                    </div>
                </div>
               {/* HEADER */}
                <div className="home_header">
                <div className="home_header_inner">
                    <div className="order_detail_back_icon" onClick={() => this.props.history.goBack()}>
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                   <div className="order_detail_header_title_left_align">Acid 1,4–metil-imidazolacetic (metabolit histamina)</div>
                    <div className="morE_menu_left_white_space"></div>
                </div>
               </div>

                <div className="result_detail_product_container">

                    <div className="deficent_result_alert">
                        <div className="deficent_result_alert_point"></div>
                        <div>{multilanguage.result_is_deficient}</div>
                    </div>

                    <div className="result_detail_product_description">
                         Active B12 helps your body absorb calcium and helps with maintainng healthy skin and bones. It also plays an important role in your immune system. Vitamin B12 deficiency is linked to fatigue, bone and muscle pain, as well as getting ill more often than usual. 
                    </div>

                    <div className="result_item_container">
                        <div className="result_item_top">
                            <div className="result_item_title">Acid 1,4–metil-imidazolacetic (metabolit histamina)</div>
                            <div className="result_item_right">
                                <div className="result_item_value red">32.75</div>
                                <div className="result_item_unit">mmol/L</div>
                            </div>
                        </div>
                        <div className="result_detail_progressbar_container_intreg">
                                <img className="result_progressbar_indicator_mobile" style={{left:'80%'}} src={require('../assets/images/blueIndicator.svg')} />
                                <div className="result_detetail_progressbar_part firstred"></div>
                                <div className="result_detetail_progressbar_part middlegreen"></div>
                                <div className="result_detetail_progressbar_part lastred"></div>
                        </div>
                        <div style={{width:'100%',overflow:'auto',marginTop:20}}>
                            <canvas id="myChart" ref={this.canvasRef} width="500" height="200">
                            </canvas>
                        </div>
                    </div>
                    
                    <div className="result_detail_add_comment_button" onClick={() => this.addComment()}>
                        <span>+ {multilanguage.add_simple} {multilanguage.comment_simple}</span>
                    </div>

                </div>
           

           </div>
)}
}
