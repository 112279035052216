import AppConfig from '../config';
import axios from 'axios';
import authApi from './auth';

// Synevo WP Shop API Docs:
// https://documenter.getpostman.com/view/222346/SzKQy19u

var api = {};

api.post = (url, data) => {
    return api.sendRequest("POST", url, data);
};

api.put = (url, data) => {
    return api.sendRequest("PUT", url, data);
};

api.delete = (url, data) => {
    return api.sendRequest("DELETE", url, data);
};
// API request get shorthand
api.get = (url, data) => {
    return api.sendRequest("GET", url, data);
};


api.sendRequest = (method, url, data) => {
    if(authApi.auth.logged){
        authApi.accessToken = authApi.auth.token.accessToken;
        if(Math.floor(Date.now() / 1000) > authApi.auth.token.expiresAt){
            authApi.logout().then(()=>{
                window.location.href='/login'
            })
            
            return new Promise(() => {});
        }
    }
    if (typeof headers == "undefined") var headers = {};
    let defaultHeaders = {
        'Content-Type': 'application/json',
    };
    headers = {...defaultHeaders, ...headers};
    if(authApi.accessToken){
        headers['Authorization'] = 'Bearer '+authApi.accessToken;
    }
    url = AppConfig.api.baseUrl + url;
    let options = {
        method: method,
        url: url,
        headers: headers,
    };
    if (method.toUpperCase() == 'GET') {
        options.params = data;
    } else {
        options.data = data;
    }
    return new Promise((resolve, reject) => {
        if (window.mockApiData) {
            if (options.url.includes('/MedicalProfile')) {
                return resolve({ data: require('./medicalprofiles.json') });
            }
            if (options.url.includes('/ServiceResult')) {
                if (!window.mockresults) {
                    window.mockresults = require("./allResultsTypes.json");
                }
                if (!window.mockresultsone) {
                    window.mockresultsone = require("./oneResult.json");
                }
                if (options.url.endsWith('ServiceResult')) {
                    let result = {...window.mockresults};
                    result.results = [...result.results];
                    if (options.params?.pageSize) result.results = result.results.slice(0, options.params.pageSize);
                    result.results.map(r => {
                        r.location = window.mockresultsone.location;
                        r.patient = window.mockresultsone.patient;
                    })
                    return resolve({ data: result });
                } else {
                    let result = window.mockresults.results.find((r) => r.id === options.url.split('/')[6]);
                    if (result) {
                        result.location = window.mockresultsone.location;
                        result.patient = window.mockresultsone.patient;
                    }
                    return resolve({ data: result });
                }
            }
        }
        
        axios(options)
        .then((response) => { resolve(response); })
        .catch((response) => { reject(response); });
    });
};

export default api;
