import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import multilanguage from '../multilanguage';

const rooms = [
    {
        id:1,
        name: "Doctor 1",
        time: "17:58",
        message: 'Cras malesuada mi turpis, a lobortis dui aliquet scelerisque... Aenean sit amet commodo lorem...',
    },
    {
        id:2,
        name: "Doctor 2",
        time: "Yesterday, 20:03",
        message: 'Cras malesuada mi turpis, a lobortis dui aliquet scelerisque... Aenean sit amet commodo lorem...',
    },
    {
        id:3,
        name: "Doctor 3",
        time: "28 June",
        message: 'Cras malesuada mi turpis, a lobortis dui aliquet scelerisque... Aenean sit amet commodo lorem...',
    },
];

const mesaje = [
    {
        id:1,
        content: "Thank you for the response! I'll keep in touch.",
        sent: false,
        time:'19:30',
    },
    {
        id: 2,
        content: "Suspendisse malesuada justo sed elit pharetra, eu convallis ipsum convallis. ",
        sent: true,
        time: '19:23',
    },
    {
        id: 3,
        content: "Hi Sara!",
        sent: true,
        time: '19:21',
    },
    {
        id: 4,
        content: "Hi Doctor! Praesent id pretium mauris. Suspendisse malesuada justo sed elit pharetra, eu convallis ipsum convallis",
        sent: false,
        time: '19:21',
    },
     {
         id: 1,
         content: "Thank you for the response! I'll keep in touch.",
         sent: false,
         time: '19:30',
     }, {
         id: 2,
         content: "Suspendisse malesuada justo sed elit pharetra, eu convallis ipsum convallis. ",
         sent: true,
         time: '19:23',
     }, {
         id: 3,
         content: "Hi Sara!",
         sent: true,
         time: '19:21',
     }, {
         id: 4,
         content: "Hi Doctor! Praesent id pretium mauris. Suspendisse malesuada justo sed elit pharetra, eu convallis ipsum convallis",
         sent: false,
         time: '19:21',
     },
 
]

class DMessages extends Component {
    constructor() {
        super();
        this.state={
            room : 1,
        }
        this.listRef = React.createRef();
    }

    changeRoom(i){
        this.listRef.current.style.transition = 'opacity 0.4s';
        this.listRef.current.style.opacity = '0';
        setTimeout(() => {
             this.listRef.current.style.opacity = '1';
        }, 400);   
        this.setState({room: i});
    }

    renderRooms = (items) => {
        return items.map((item,index) => {
            return(
                <div key={index} className={this.state.room == item.id ? "desktop_room_container_active" : "desktop_room_container"} onClick={() => this.changeRoom(item.id)}>
                    <div className="desktop_room_container_inner">
                        <div className="desktop_room_left">           
                            <img className="d_room_avatar" src={require('../assets/images/personPlaceholder.jpg')} />  
                            <div className="d_room_active_bullet"></div>
                        </div>
                       <div className="d_room_middle">
                           <div className="desktop_room_top">
                                <div className="desktop_room_name">{item.name}</div>
                                <div className="d_room_right">
                                    <div className="d_room_bullet"></div>
                                    <div className="desktop_room_time">{item.time}</div>
                                </div> 
                           </div>               
                            <div class="desktop_room_message">{item.message}</div>
                        </div>   
                    </div>       
                </div>
        )})
    }

    render_messages = (items) => {
        return items.map((item,index) => {
            if(item.sent)
            return(
                <div key={index} className="container_mesaj">
                    <div className="container_mesaj_trimis">
                        <div className="desktop_chat_mesaj_box">
                            <div className="desktop_chat_mesaj_content">{item.content}</div>
                            <div className="desktop_chat_mesaj_time">{item.time}</div>
                        </div>
                        <img className="d_chat_mesaj_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                    </div>   
                </div>
                )
            else
            return(
                <div key={index} className="container_mesaj">
                    <div className="container_mesaj_primit">
                         <img className="d_chat_mesaj_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                         <div className="desktop_chat_mesaj_box">
                            <div className="desktop_chat_mesaj_content">{item.content}</div>
                            <div className="desktop_chat_mesaj_time">{item.time}</div>
                        </div>
                    </div>  
                </div>
        )
        })
    }

    render() { 
        return ( 
            <div className="desktop_container">
                <Menu screenProps={this.props.history} />
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} />
                    <div className="d_main_content">

                    <div className="d_page_title_with_button">
                        <div className="d_page_title">{multilanguage.messages}</div>
                    </div>

                        
                    <div className="desktop_messages_inner">
                        <div className="desktop_messages_left">
                            <div className="d_messages_title_search_container">
                                <img src={require('../assets/images/desktop/search1.svg')} />
                                <input placeholder={multilanguage.search_simple} type="text" />
                            </div>
                            {this.renderRooms(rooms)}
                        </div>

                        <div className="desktop_messages_right">

                            <div className="desktop_chat_header">
                                <div className="desktop_chat_left">           
                                    <img className="d_room_avatar" src={require('../assets/images/personPlaceholder.jpg')} />  
                                    <div className="d_room_active_bullet"></div>
                                </div>
                                <div className="desktop_chat_header_name">Doctor 1</div>
                            </div>

                            <div ref={this.listRef} id="d_chat_window" className="desktop_chat_window">
                                <div  className="desktop_chat_window_inner">
                                    {this.render_messages(mesaje)}
                                </div>                  
                            </div>


                            <div className="desktop_chat_bottom">
                                <div className="desktop_chat_attach">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.5 0C11.58 0 12.48 0.9 12.48 1.98V8.52H19.02C20.16 8.52 21 9.42 21 10.5C21 11.58 20.16 12.48 19.02 12.48H12.48V19.02C12.48 20.16 11.58 21 10.5 21C9.42 21 8.52 20.16 8.52 19.02V12.48H1.98C0.9 12.48 0 11.58 0 10.5C0 9.42 0.9 8.52 1.98 8.52H8.52V1.98C8.52 0.9 9.42 0 10.5 0Z" fill="#00549E"/>
                                    </svg>
                                </div>
                                <div className="desktop_chat_composer">
                                    <input type="text" placeholder="Type a message"></input>
                                </div>
                                <div className="desktop_chat_send">
                                    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.9181 11.1971L2.52827 12.0533L0.0568994 19.4985C-0.0906259 19.9387 0.0544377 20.4193 0.42571 20.7205C0.796983 21.0217 1.32304 21.0854 1.76315 20.8826L22.3424 11.4949C22.7447 11.31 23 10.9252 23 10.5034C23 10.0816 22.7447 9.69674 22.3424 9.51182L1.77752 0.117372C1.3374 -0.085433 0.811352 -0.0216534 0.440079 0.279526C0.0688062 0.580705 -0.0762574 1.06134 0.0712679 1.5015L2.54264 8.94667L11.9145 9.80286C12.2931 9.83814 12.5816 10.1382 12.5816 10.4966C12.5816 10.8551 12.2931 11.1551 11.9145 11.1904L11.9181 11.1971Z" fill="#00549E"/>
                                    </svg>
                                </div>
                            </div>

                        </div>

                    </div>

                    </div>
                </div>
            </div>
        )}
}
 
export default DMessages;