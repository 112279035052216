import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import Chart from 'chart.js';
import annotation from 'chartjs-plugin-annotation';
import multilanguage from '../multilanguage';
import DLoader from "./parts/DLoader";
import api from '../api/api';
import auth from '../api/auth';
import moment from 'moment';
import {NotificationManager} from 'react-notifications';
import cart from '../api/cart';
import { result } from 'lodash';
import emitter from 'tiny-emitter/instance';
import encoding from 'encoding'

class DResultDetail extends Component {
    constructor() {
        super();
        this.state={
            pdf: [],
            showPdf: false,
            pdfurl: null,
            loading: true,
            testName: '',
            codes: '',
            result: null,
          visibleAddComment: false,
           deficient: false,
           results:[
               {id: 1,
                name:'Acid 1,4–metil-imidazolacetic (metabolit histamina)',
                value:'32.75',
                unit:'mmol/L',
                type: 'minmax',
              },
              {id: 2,
                name:'Acid delta-aminolevulinic',
                value:'8.2',
                unit:'nmol/L',
                type: 'max',
              },
              {id: 3,
                name:'Calciu ionic',
                type: 'boolean',
                comment: true,
              },
              {id: 4,
                name:'Bilirubin total',
                type: 'comment',
              },
              {id: 5,
                name:'LDL Cholesterol (calculated)',
                type: 'pending',
                date:'ETA 24.06.2019',
              },
           ],
           visibleGraph: false,
        }
        this.canvasRef = React.createRef();
    }

    setVisibleGraph(){
        var inv = !this.state.visibleGraph;
        this.setState({visibleGraph: inv});
        var vthis = this;
        if(inv)
        setTimeout(() => {
            vthis.setVisibleGraphTime()
        }, 10);
    }

    setVisibleGraphTime(){
        var myChart = new Chart(document.getElementById('myChart').getContext('2d'), {
        type: 'line',
            data: {
                labels: ['12 April', '11 June', '12 August', '5 November', '24 December'],
                datasets: [{
                    label: 'mmol/L',
                    data: [40, 35, 60, 40, 70],
                    backgroundColor: [
                        'rgba(0, 84, 158, 0.1)',
                    ],
                    borderColor: [
                        '#00549E',
                    ],
                    borderWidth: 1,
                    lineTension: 0.00001,
                    pointBackgroundColor: '#00549E',
                    pointBorderColor: '#00549E',
                    pointHitRadius: 15,
                    pointBorderWidth: 1,
                    yAxisID: 'y-axis-1',
                }]
            },
            options: {
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        type: 'linear',
                        display: false,
                        position: 'left',
                        id: 'y-axis-1',
                    }],
                    xAxes: [
                        {
                            display: false,
                        }
                    ],           
                },
                annotation: {
                    annotations: [{
                        drawTime: 'afterDatasetsDraw',
                        id: 'a-line-1',
                        type: 'box',
                        yScaleID: 'y-axis-1',
                        yMax: 55,
                        yMin: 40,
                        borderColor: 'rgba(144, 181, 87, 0.4)',
                        backgroundColor: 'rgba(144, 181, 87, 0.4)',
                        borderWidth: 0,
                    }]
                },
            },
        });
}
componentDidMount(){
    const id = this.props.match.params.id;
    var testName = ''
    var codes = ''
    api.get('/ServiceResult/'+id)
        .then(res => {
            // if (res.data.serviceRequest?.services?.length)
            // res.data.serviceRequest?.services?.map?.((item, index) =>{
            //     if(index < res.data.serviceRequest?.services?.length-1)
            //     codes +=item.code+','
            //     else{
            //     codes +=item.code
            //     }
            // });
            this.setState({result: res.data, loading: false, testName: testName, codes: codes})
        })
    emitter.on("cartUpdate", ()=>{
        this.forceUpdate()
    })
}


repeatTest(){
    api.get('/ServiceKnowledgebase/serviceByCodeMaterial', {serviceCodeMaterialList: this.state.codes})
    .then(res => {
        cart.empty()
        res.data.map((item)=>{
            cart.addItem(item)
        })
        cart.addPatient(this.state.result.patient)
        NotificationManager.success(multilanguage.go_to_cart);
    })
}

renderResults = (items) => {
    return items.map((item,index) => {
        if(item.type == 'minmax')
        return(
            <div key={item.id} className="d_result_item_container">
               <div className="result_item_top" >
                   <div className="d_result_item_title">{item.name}</div>
                    <div className="result_detail_progressbar_container">
                            <img className="result_progressbar_indicator" style={{left:'80%'}} src={require('../assets/images/blueIndicator.svg')} />
                            <div className="result_detetail_progressbar_part firstred"></div>
                            <div className="result_detetail_progressbar_part middlegreen"></div>
                            <div className="result_detetail_progressbar_part lastred"></div>
                    </div>
                   <div className="result_item_right">
                       <div className="result_item_value red">{item.value}</div>
                       <div className="result_item_unit">{item.unit}</div>
                   </div>
               </div>
               { this.state.visibleGraph  ?
                    <div>
                        <canvas id="myChart" ref={this.canvasRef} width="500" height="200">
                        </canvas>
                    </div>
                : null}
            </div>
        );
        if(item.type == 'max')
        return(
            <div key={item.id} className="d_result_item_container">
               <div className="result_item_top" >
                   <div className="d_result_item_title">{item.name}</div>
                   <div className="result_detail_progressbar_container">
                        <img className="result_progressbar_indicator" style={{left:'60%'}} src={require('../assets/images/blueIndicator.svg')} />
                        <div className="result_detetail_progressbar_part firstgreen"></div>
                        <div className="result_detetail_progressbar_part lastred"></div>
                    </div>
                   <div className="result_item_right">
                       <div className="result_item_value">{item.value}</div>
                       <div className="result_item_unit">{item.unit}</div>
                   </div>
               </div>
               <div className="result_detail_more_graph_button">
                    <img src={require('../assets/images/moreGraph.svg')} />
               </div>
            </div>
        );
        if(item.type == 'boolean')
        return(
            <div key={item.id} className="d_result_item_container" >
            <div className="result_item_container_boolean">
               <div className="result_item_bool_left">
                   <div className="result_item_bool_point"></div>
                   <div>
                        <div className="result_item_title">{item.name}</div>
                        <div className="result_item_read_below">Read comment below</div>
                   </div>
               </div>
               <div className="result_item_bool_right">Your result is negative</div>
            </div>
            </div>
        );
        if(item.type == 'comment')
        return(
            <div key={item.id} className="d_result_item_container" >
            <div className="result_item_container_boolean">
                <div className="result_item_title">{item.name}</div>
               <div className="result_item_comment_right">Read more   <img src={require('../assets/images/whiteArrow.svg')} /></div>
            </div>
            </div>
        );
        if(item.type == 'pending')
        return(
            <div key={item.id} className="d_result_item_container" >
            <div className="result_item_container_boolean">
                <div>
                    <div className="result_item_title">{item.name}</div>
                    <div className="result_item_read_below">ETA 24.06.2019</div>
                </div>
               <div className="result_item_pending_right">Result is pending</div>
            </div>
            </div>
        );
    })
}
    openPdf(){
        api.get('/ServiceResult/'+this.props.match.params.id+'/pdf/base64')
        .then(res => {
            // res.data = encoding.convert(text, toCharset, fromCharset);
            // var data = new Blob([res.data], {encoding:"UTF-8", type: "application/pdf"})
            // window.open('data:application/pdf;base64,'+res.data)
            // // var url = URL.createObjectURL(data)
            // this.setState({pdfurl: url, showPdf: true})
            
            // base64 string
            var base64str = res.data;
            // decode base64 string, remove space for IE compatibility
            var binary = atob(base64str.replace(/\s/g, ''));
            var len = binary.length;
            var buffer = new ArrayBuffer(len);
            var view = new Uint8Array(buffer);
            for (var i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }

            // create the blob object with content-type "application/pdf"               
            var blob = new Blob( [view], { type: "application/pdf" });
            var url = URL.createObjectURL(blob);
            
            this.setState({showPdf: true})
            window.open(url)
        })
        
    }
    render() {
        return ( 
            <div className="desktop_container">
                <Menu screenProps={this.props.history} />
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} />
                    <div className="d_main_content">
                        
                        
                    <div className="d_page_title_container">
                        <div className="d_page_title_with_button">
                            <div className="d_header_detail_back" onClick={() => this.props.history.goBack()}>
                                <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.515152 7.20675L6.9798 0.531902C7.66667 -0.177301 8.79798 -0.177301 9.48485 0.531902C10.1717 1.2411 10.1717 2.4092 9.48485 3.1184L4.27273 8.5L9.48485 13.8816C10.1717 14.5908 10.1717 15.7589 9.48485 16.4681C8.79798 17.1773 7.66667 17.1773 6.9798 16.4681L0.515152 9.79325C-0.171717 9.08405 -0.171717 7.91595 0.515152 7.20675Z" fill="black"/>
                                </svg>
                            </div>
                            {this.state.loading ? null:
                            <div className="d_page_title">{((this.state.result?.display)?.length > 70) ? (((this.state.result?.display)?.substring(0,70-3)) + '...') : this.state.result.display}</div>}
                        </div>
                    
                        <div className="d_page_title_right_flex">
                            {/* <div className="d_store_menu_buttons" onClick={() => this.setState({visibleAddComment : true})}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.2453 7.24546H8.75474V0.754727C8.75474 0.337903 8.41683 0 8 0C7.58317 0 7.24526 0.337903 7.24526 0.754727V7.24546H0.754745C0.337911 7.24546 0 7.58336 0 8.00019C0 8.41701 0.337911 8.75491 0.754745 8.75491H7.24526V15.2453C7.24526 15.6621 7.58317 16 8 16C8.41683 16 8.75474 15.6621 8.75474 15.2453V8.75491H15.2453C15.6621 8.75491 16 8.41701 16 8.00019C16 7.58336 15.6621 7.24546 15.2453 7.24546Z" fill="#00539F"/>
                                </svg>
                                <span>{multilanguage.add_comment}</span>
                            </div>    */}
                            <div className="d_store_menu_buttons" onClick={() => this.openPdf()}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.6667 4.66667H18C19.1 4.66667 20 5.56667 20 6.66667V13C20 14.1 19.1 15 18 15H15.6667V19.3333C15.6667 19.7 15.3667 20 15 20H5C4.63333 20 4.33333 19.7 4.33333 19.3333V15H2C0.9 15 0 14.1 0 13V6.66667C0 5.56667 0.9 4.66667 2 4.66667H4.33333V0.666667C4.33333 0.3 4.63333 0 5 0H15C15.3667 0 15.6667 0.3 15.6667 0.666667V4.66667ZM14.3333 1.33333H5.66667V4.66667H14.3333V1.33333ZM14.3333 18.6667H5.66667V12H14.3333V18.6667ZM18 13.6667C18.3667 13.6667 18.6667 13.3667 18.6667 13V6.66667C18.6667 6.3 18.3667 6 18 6H15H5H2C1.63333 6 1.33333 6.3 1.33333 6.66667V13C1.33333 13.3667 1.63333 13.6667 2 13.6667H4.33333V11.3333C4.33333 10.9667 4.63333 10.6667 5 10.6667H15C15.3667 10.6667 15.6667 10.9667 15.6667 11.3333V13.6667H18Z" fill="#00539F"/>
                                </svg>
                                <span>{multilanguage.download}</span>
                            </div> 
                            <div className="d_store_menu_buttons" style={{borderColor:'#FDB913'}} onClick={() => this.repeatTest()}>
                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.00004 0C4.03791 0 7.13592e-05 4.15334 7.13592e-05 9.25741C-0.00355252 9.52098 0.131074 9.76614 0.352401 9.89902C0.573727 10.0319 0.847463 10.0319 1.06879 9.89902C1.29012 9.76614 1.42474 9.52098 1.42112 9.25741C1.42112 4.9433 4.8059 1.4617 9.00004 1.4617C13.1942 1.4617 16.579 4.9433 16.579 9.25741C16.579 13.5715 13.1942 17.0531 9.00004 17.0531C7.43206 17.0531 5.85838 16.4124 4.58147 15.462L6.20975 15.3402C6.60211 15.3254 6.90855 14.9863 6.89424 14.5827C6.87994 14.1791 6.55031 13.8639 6.15794 13.8785C6.14059 13.8804 6.12331 13.8829 6.10613 13.8861L2.79036 14.1297C2.58501 14.1449 2.39613 14.2508 2.2726 14.4202C2.14908 14.5897 2.10305 14.8058 2.14644 15.0128L2.85697 18.4235C2.94076 18.8187 3.32018 19.0692 3.70442 18.983C4.08865 18.8968 4.33221 18.5066 4.24841 18.1113L3.97456 16.8095C5.41487 17.8302 7.15978 18.5148 9.00004 18.5148C13.9622 18.5148 18 14.3615 18 9.25741C18 4.15334 13.9622 0 9.00004 0Z" fill="#00539F"/>
                                </svg>
                                <span>{multilanguage.repeat_test}</span>
                            </div>                
                        </div>

                    </div>
                    {this.state.loading ? <DLoader /> :
                    <div className="d_result_detail_container">
                        <div className="d_result_detail_left">
                            

                        {/* {this.renderResults(this.state.results)} */}
                           
                             {this.state.result.serviceResultList.map((service)=>{

                                //  console.log("resultlist", result)
                                 return(
                                    <div>
                                        <div className="d_result_title">{service.name}</div>
                                        <div key={service.id} className="d_result_item_container">
                                    {service.parameterList.map((params)=>{
                                        if(params.referenceRange && params.value){
                                        if(params.referenceRange.low && params.referenceRange.high){
                                            var minValue = parseFloat(params.referenceRange.low)
                                            var actualValue = parseFloat(params.value)-minValue
                                            var maxValue = parseFloat(params.referenceRange.high)-minValue

                                          
                                            var value = Math.round((actualValue *100)/(maxValue))
                                         
                                            


                                            return(
                                                
                                                   <div className="result_item_top" >
                                                       <div className="d_result_item_title">{params.name}</div>
                                                        <div className="result_detail_progressbar_container">
                                                                
                                                                <div className="result_detetail_progressbar_part firstred">
                                                                <div className="result_item_unit_right">{params.referenceRange.low}</div>
                                                                {parseFloat(params.value) < parseFloat(params.referenceRange.low) ? 
                                                                <img className="result_progressbar_indicator_failed" style={{left:"40%"}} src={require('../assets/images/blueIndicator.svg')} />
                                                                : null}
                                                                </div>
                                                                <div className="result_detetail_progressbar_part middlegreen">
                                                                
                                                                    {parseFloat(params.value) < parseFloat(params.referenceRange.low) || parseFloat(params.value) > parseFloat(params.referenceRange.high) ? null :
                                                                    <img className="result_progressbar_indicator" style={{left:value-10+"%"}} src={require('../assets/images/blueIndicator.svg')} />}
                                                                    
                                                                    </div>
                                                                <div className="result_detetail_progressbar_part lastred">
                                                                    <div className="result_item_unit_left">{params.referenceRange.high}</div>
                                                                    {parseFloat(params.value) > parseFloat(params.referenceRange.high) ? 
                                                                <img className="result_progressbar_indicator_failed" style={{left:"40%"}} src={require('../assets/images/blueIndicator.svg')} />
                                                                : null}
                                                                    </div>
                                                        </div>
                                                       <div className="result_item_right">
                                                            {parseFloat(params.value) < parseFloat(params.referenceRange.low) || parseFloat(params.value) > parseFloat(params.referenceRange.high) ? 
                                                            <div className="result_item_value red">{params.value}</div> 
                                                            :
                                                            <div className="result_item_value green">{params.value}</div>}
                                                       </div>
                                                   </div>
                                                
                                                
                                            )
                                        }else if(!params.referenceRange.low && params.referenceRange.high && params.referenceRange.text){
                                            return(
                                                <div className="result_item_top" >
                                                    <div className="d_result_item_title">{params.name}</div>
                                                        <div className="result_detail_progressbar_container">
                                                                
                                                                <div className="result_detetail_progressbar_part firstgreen">
                                                                {parseFloat(params.value) <= parseFloat(params.referenceRange.high) ? 
                                                                <img className="result_progressbar_indicator" style={{left:"40%"}} src={require('../assets/images/blueIndicator.svg')} />
                                                                : null}
                                                                </div>
                                                                <div className="result_detetail_progressbar_part lastred">
                                                                <div className="result_item_unit_left">{params.referenceRange.high}</div>
                                                                    {parseFloat(params.value) <= parseFloat(params.referenceRange.high) ? null :
                                                                    <img className="result_progressbar_indicator_failed" style={{left:"40%"}} src={require('../assets/images/blueIndicator.svg')} />}
                                                                    
                                                                    </div>
                                                        </div>
                                                        <div className="result_item_right">
                                                            {parseFloat(params.value) <= parseFloat(params.referenceRange.high) ? 
                                                            <div className="result_item_value green">{params.value}</div>
                                                            :
                                                            <div className="result_item_value red">{params.value}</div>}
                                                        </div>
                                                    </div>
                                                
                                            )
                                        }else if(params.referenceRange.low && !params.referenceRange.high && params.referenceRange.text){
                                            
                                            return(
                                                <div className="result_item_top" >
                                                    <div className="d_result_item_title">{params.name}</div>
                                                        <div className="result_detail_progressbar_container">
                                                                
                                                                <div className="result_detetail_progressbar_part firstred">
                                                                    <div className="result_item_unit_right">{params.referenceRange.low}</div>
                                                                {parseFloat(params.value) < parseFloat(params.referenceRange.low) ? 
                                                                <img className="result_progressbar_indicator_failed" style={{left:"40%"}} src={require('../assets/images/blueIndicator.svg')} />
                                                                : null}
                                                                </div>
                                                                <div className="result_detetail_progressbar_part lastgreen">
                                                                    {parseFloat(params.value) <= parseFloat(params.referenceRange.low) ? null :
                                                                    <img className="result_progressbar_indicator" style={{left:"40%"}} src={require('../assets/images/blueIndicator.svg')} />}
                                                                    
                                                                    </div>
                                                        </div>
                                                    <div className="result_item_right">
                                                            {parseFloat(params.value) <= parseFloat(params.referenceRange.low) ? 
                                                            <div className="result_item_value red">{params.value}</div>
                                                            :
                                                            <div className="result_item_value green">{params.value}</div>}
                                                    </div>
                                                </div>
                                            )
                                        }else if(!params.referenceRange.low && !params.referenceRange.high && params.referenceRange.text){
                                            return(
                                                <div key={params.id} className="d_result_item_container_bool" >
                                                    <div className="result_item_container_boolean">
                                                        <div className="result_item_bool_left">
                                                            {/* <div className="result_item_bool_point"></div> */}
                                                            <div>
                                                                    <div className="result_item_title">{params.name}</div>
                                                                    {/* <div className="result_item_read_below">Read comment below</div> */}
                                                            </div>
                                                        </div>
                                                    <div className="result_item_bool_right">{params.value}</div>
                                                    </div>
                                                </div>
                                                
                                            )
                                        }
                                    }else if(!params.referenceRange && params.value){
                                            return(
                                                <div key={params.id} className="d_result_item_container_bool" >
                                                    <div className="result_item_container_boolean">
                                                        <div className="result_item_bool_left">
                                                            {/* <div className="result_item_bool_point"></div> */}
                                                            <div>
                                                                    <div className="result_item_title">{params.name}</div>
                                                                    {/* <div className="result_item_read_below">Read comment below</div> */}
                                                            </div>
                                                        </div>
                                                    <div className="result_item_bool_right">{params.value}</div>
                                                    </div>
                                                </div>
                                            )
                                            
                                        }
                                        
                                     })}
                                     </div>
                                     </div>
                                 )
                             })}
                        </div>
                        {/* CARD */}
                        <div className="d_result_detail_container_right">
                         <div className="d_result_detail_container_right_inner">
                            <div className="d_res_det_right_top">
                                <div className="d_res_det_right_top_img_container">
                                    <img src={this.state.result.patient.gender == 'F' ? require('../assets/images/famale.png') : require('../assets/images/male.png')} />
                                </div>
                                <span>{this.state.result.patient.firstName} {this.state.result.patient.lastName}</span>
                            </div>

                            <div className="d_res_det_right_first_row">
                                <div>
                                    <span className="d_res_det_right_label_black">{multilanguage.age_sample}</span>
                                    <span className="d_res_det_right_value_black">{moment().diff(moment(this.state.result.patient.birthDate), 'years')}</span>
                                </div>
                                <div className="d_res_det_right_hr"></div>
                                <div>
                                    <span className="d_res_det_right_label_black">{multilanguage.recoltation_date}</span>
                                    <span className="d_res_det_right_value_black">{moment(this.state.result.issued).format('DD.MM.YYYY')}</span>
                                </div>
                            </div>

                            
                            {/* <div className="d_res_det_right_row">
                                <span className="d_res_det_right_label_blue">{multilanguage.ssn_simple}</span>
                                <span className="d_res_det_right_value_blue">2920626134154</span>
                            </div>
                            <div className="d_res_det_right_row">
                                <span className="d_res_det_right_label_blue">{multilanguage.barcode_sample}</span>
                                <span className="d_res_det_right_value_blue">5177548922</span>
                            </div> */}
                            <div className="d_res_det_right_row">
                                <span className="d_res_det_right_label_blue">{multilanguage.order_id}</span>
                                < span className = "d_res_det_right_value_blue">{this.state.result?.serviceRequest?.id}</span>
                            </div>
                            {this.state.result?.patient?.gender == "F" ? 
                            <div className="d_res_det_right_row" >
                                
                                <span className="d_res_det_right_label_blue">{multilanguage.sex_simple}</span>
                                
                                <span className="d_res_det_right_value_blue">{multilanguage.female_simple}</span>
                            </div> 
                            :
                            <div className="d_res_det_right_row" >
                                                        
                                <span className="d_res_det_right_label_blue">{multilanguage.sex_simple}</span>
                                
                                <span className="d_res_det_right_value_blue">{multilanguage.male_simple}</span>
                            </div>
                        }
                        <div className="d_res_det_right_row" style={{border:0}}>
                                <span className="d_res_det_right_label_blue">{multilanguage.location_simple}</span>
                                <span className="d_res_det_right_value_blue">{this.state.result.labComment}</span>
                            </div>
                        </div>  
                                       
                        </div>
                         {/* CARD */}


                    </div>}

                    </div>
                </div>

                {/* {this.state.visibleAddComment ?
                <div className='d_add_note_container'>
                    <div className="d_add_note_box_container">
                        <div className="d_add_note_header">
                            <div></div>
                            <div className="daddnote_title">{multilanguage.new_comment}</div>
                            <div className="daddnote_close" onClick={() => this.setState({visibleAddComment:false})}>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.231701">
                                    <path d="M14.6786 0.3C14.25 -0.1 13.6071 -0.1 13.1786 0.3L7.5 5.6L1.82143 0.3C1.39286 -0.1 0.75 -0.1 0.321429 0.3C-0.107143 0.7 -0.107143 1.3 0.321429 1.7L6 7L0.321429 12.3C-0.107143 12.7 -0.107143 13.3 0.321429 13.7C0.535714 13.9 0.75 14 1.07143 14C1.39286 14 1.60714 13.9 1.82143 13.7L7.5 8.4L13.1786 13.7C13.3929 13.9 13.7143 14 13.9286 14C14.1429 14 14.4643 13.9 14.6786 13.7C15.1071 13.3 15.1071 12.7 14.6786 12.3L9 7L14.6786 1.7C15.1071 1.3 15.1071 0.7 14.6786 0.3Z" fill="black"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <textarea rows="10" placeholder="Write your text here..." ></textarea>
                        <div className="daddnote_save_button"  onClick={() => this.setState({visibleAddComment:false})}>{multilanguage.save_simple}</div>
                        <div className="daddnote_cancel_button" onClick={() => this.setState({visibleAddComment:false})}>{multilanguage.cancel_simple}</div>
                    </div>
                </div>
                : null} */}
            </div>
 )}
}
 
export default DResultDetail;