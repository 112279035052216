import React from 'react';
import multilanguage from '../multilanguage';

const mesaje = [
    {
        id:1,
        content: "Thank you for the response! I'll keep in touch.",
        sent: false,
        time:'19:30',
    },
    {
        id: 2,
        content: "Suspendisse malesuada justo sed elit pharetra, eu convallis ipsum convallis. ",
        sent: true,
        time: '19:23',
    },
    {
        id: 3,
        content: "Hi Sara!",
        sent: true,
        time: '19:21',
    },
    {
        id: 4,
        content: "Hi Doctor! Praesent id pretium mauris. Suspendisse malesuada justo sed elit pharetra, eu convallis ipsum convallis",
        sent: false,
        time: '19:21',
    },
     {
         id: 1,
         content: "Thank you for the response! I'll keep in touch.",
         sent: false,
         time: '19:30',
     }, {
         id: 2,
         content: "Suspendisse malesuada justo sed elit pharetra, eu convallis ipsum convallis. ",
         sent: true,
         time: '19:23',
     }, {
         id: 3,
         content: "Hi Sara!",
         sent: true,
         time: '19:21',
     }, {
         id: 4,
         content: "Hi Doctor! Praesent id pretium mauris. Suspendisse malesuada justo sed elit pharetra, eu convallis ipsum convallis",
         sent: false,
         time: '19:21',
     },
 
]
export default  class Chat extends React.Component {

    constructor() {
        super();
        this.state={
            messages: [],
        }
        this.messagesEnd = React.createRef();
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
      }
      
      componentDidMount() {
        this.scrollToBottom();
      }
      
      componentDidUpdate() {
        this.scrollToBottom(); 
      }


    render_messages = (items) => {
        return items.map((item,index) => {
            if(item.sent)
            return(
                <div key={index} className="container_mesaj">
                    <div className="container_mesaj_trimis">
                        <div className="d_chat_mesaj_box">
                            <div className="d_chat_mesaj_content">{item.content}</div>
                            <div className="d_chat_mesaj_time">{item.time}</div>
                        </div>
                        <img className="d_chat_mesaj_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                    </div>   
                </div>
            )
            else
            return(
                <div key={index} className="container_mesaj">
                    <div className="container_mesaj_primit">
                         <img className="d_chat_mesaj_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                         <div className="d_chat_mesaj_box">
                            <div className="d_chat_mesaj_content">{item.content}</div>
                            <div className="d_chat_mesaj_time">{item.time}</div>
                        </div>
                    </div>  
                </div>
            )
        })
    }


    render() {
        return (
            <div className="app_container" style={{height:'100%'}}>
            <div className="chat_container">
                <div className="d_chat_header">
                    <div className="header_with_back_icon_container" onClick={() => this.props.history.goBack()}>
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="d_chat_left">           
                        <img className="d_room_avatar" src={require('../assets/images/personPlaceholder.jpg')} />  
                        <div className="d_room_active_bullet"></div>
                    </div>
                    <div className="d_chat_header_name">Olivia Wilson</div>
                </div>
                
                <div ref={this.listRef} id="d_chat_window" className="d_chat_window">
                    <div  className="d_chat_window_inner">
                        {this.render_messages(mesaje)}
                        <div style={{ float:"left", clear: "both" }}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                    </div>                  
                </div>


                <div className="d_chat_bottom">
                <div className="d_chat_bottom_inner">
                    <div className="d_chat_attach">
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.5 0C11.58 0 12.48 0.9 12.48 1.98V8.52H19.02C20.16 8.52 21 9.42 21 10.5C21 11.58 20.16 12.48 19.02 12.48H12.48V19.02C12.48 20.16 11.58 21 10.5 21C9.42 21 8.52 20.16 8.52 19.02V12.48H1.98C0.9 12.48 0 11.58 0 10.5C0 9.42 0.9 8.52 1.98 8.52H8.52V1.98C8.52 0.9 9.42 0 10.5 0Z" fill="#00549E"/>
                        </svg>
                    </div>
                    <div className="d_chat_composer">
                        <input type="text" placeholder="Type a message"></input>
                    </div>
                    <div className="d_chat_send">
                        <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.9181 11.1971L2.52827 12.0533L0.0568994 19.4985C-0.0906259 19.9387 0.0544377 20.4193 0.42571 20.7205C0.796983 21.0217 1.32304 21.0854 1.76315 20.8826L22.3424 11.4949C22.7447 11.31 23 10.9252 23 10.5034C23 10.0816 22.7447 9.69674 22.3424 9.51182L1.77752 0.117372C1.3374 -0.085433 0.811352 -0.0216534 0.440079 0.279526C0.0688062 0.580705 -0.0762574 1.06134 0.0712679 1.5015L2.54264 8.94667L11.9145 9.80286C12.2931 9.83814 12.5816 10.1382 12.5816 10.4966C12.5816 10.8551 12.2931 11.1551 11.9145 11.1904L11.9181 11.1971Z" fill="#00549E"/>
                        </svg>
                    </div>
                </div>
                </div>

            </div>
            </div>
)}
}
