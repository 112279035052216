import React from 'react';
import NavBar from './parts/NavBar';
import AOS from 'aos';
import 'aos/dist/aos.css'
import OrderFilters from './parts/OrderFilters';
import multilanguage from '../multilanguage';
import moment from 'moment';
import DLoader from "./parts/DLoader";
import cart from '../api/cart';
import api from '../api/api';
import auth from '../api/auth';
let screenHeight = 0;

export default  class PatientOrders extends React.Component {

    constructor() {
        super();
        this.state={
           selected:1,
           visibleNavBar: true,
           filtersVisible:false,
           results: [],
           search: '',
           loading: true,
        }
        this.handleResize = this.handleResize.bind(this);
    }

    selectFiles(id){
        this.setState({selected: id});
    }

    componentDidMount(){
        AOS.init(); 
        screenHeight = document.documentElement.clientHeight;
        window.addEventListener('resize',this.handleResize);
        const id = this.props.match.params.id;
        api.get('/Patient/'+id+'/servicerequest')
            .then(res => {
                this.setState({results: res.data.results, loading: false});
            })
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.handleResize);
    }

    handleResize(){
        if(screenHeight - document.documentElement.clientHeight > 150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: false})
        }
        if(screenHeight - document.documentElement.clientHeight < -150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: true})
        }
    }

    setFilterVisible(){
        this.setState({filtersVisible: true});
    }
    handleCloseFilters(){
        this.setState({filtersVisible: false});
    }
    searchtimeout = null
    doSearch(search){
      this.setState({loading: true})
      api.get('/Patient/'+this.props.match.params.id+'/serviceresult', {searchQuery: search})
              .then(res => {
                this.setState({
                  loading: false,
                  results: res.data.results
                })
              })
    }
    search(search){
      this.setState({results: []})
      if (this.searchtimeout) clearTimeout(this.searchtimeout)
      this.searchtimeout = setTimeout(() => this.doSearch(search), 1000);
    }
    render() {
        return (
           <div className="app_container">
                 <OrderFilters navigation={this.props.history} visible={this.state.filtersVisible} onClose={() => this.handleCloseFilters()} />
               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="menu_account_avatar_container">
                        <img className="menu_account_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                    </div>
                    <div className="header_with_back_title_no_margin">{this.props.location.state.name}</div>
                </div>
               </div>
                

                <div className="patient_detail_navigator_container">
                    <div className="patient_detail_navigator_inner">
                        <div className="patient_detail_navigator_item" onClick={() => this.props.history.push('/patient_detail/'+this.props.match.params.id, {name: this.props.location.state.name})}>{multilanguage.data_simple}</div>
                        {/* <div className="patient_detail_navigator_item" onClick={() => this.props.history.push('/medical_files/')}>{multilanguage.medical_files}</div> */}
                        <div className="patient_detail_navigator_item" onClick={() => this.props.history.push('/patient_results/'+this.props.match.params.id, {name: this.props.location.state.name})}>{multilanguage.results}</div>
                        <div className="patient_detail_navigator_item active">{multilanguage.orders_simple} </div>
                    </div>
                </div>

                
                <div className="orders_search_container">
                    <div className="orders_search_input_container">
                        <img src={require('../assets/images/graysearch.svg')} />
                        <input type="text" placeholder={multilanguage.search_simple} 
                        defaultValue={this.state.search}
                        onChange={evt => this.search(evt.target.value)}
                        disabled={this.state.loading}
                        />
                    </div>
                    {/* <div className="orders_search_filter_button" onClick={() => this.setFilterVisible()}>
                        <img src={require('../assets/images/filterIcon.svg')} />
                    </div> */}
                </div>


                <div className="orders_list_container">

                {this.state.loading ? <DLoader /> : null}
                            {this.state.results ? this.state.results.map((requests)=>{
                                return(
                    <div className="orders_list_item" onClick={() => this.props.history.push('/order_detail/'+requests.id)}>
                    <div className="orders_list_item_inner">
                        <div className="orders_list_item_left">
                            <div className="orders_list_item_name">{multilanguage.order_no}. {requests.id}</div>
                            <div className="orders_list_item_bottom_info">
                                <div className="orders_list_item_personname">{requests.patient.firstName} {requests.patient.lastName}</div>
                                <div className="orders_list_item_yellowpoint"></div>
                                <div className="orders_list_item_personname">{moment(requests.validFrom).format('DD MMMM YYYY')}</div>
                            </div>
                        </div>
                        <img className="more_menu_list_arrow" src={require('../assets/images/menuArrow.svg')} />
                    </div>
                    </div>
                                )
                            }): null}
                    </div>

                    {this.state.visibleNavBar ?
                    <div className="orders_buton_fixed" onClick={() => this.props.history.push('/new_order')}>
                        <img src={require('../assets/images/plusShape.svg')} />
                        <span>{multilanguage.new_order}</span>
                    </div>
                    : null}

                    {this.state.visibleNavBar ?
                <NavBar navigation={this.props.history} />
                : null}
           </div>
)}
}
