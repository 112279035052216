import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import api from '../api/api';
import auth from '../api/auth';
import multilanguage from '../multilanguage';
import {NotificationManager} from 'react-notifications';

class DEditProfile extends Component {
    state = { 
        displayName: null,
        email: null,
        phoneNumber: null,
        education: null,
        description: null,
        profile: [],
        loading: true,
     }
    componentDidMount(){
            api.get('/Profile')
            .then(res => {
                this.setState({profile: res.data, displayName: res.data.displayName, education: res.data.education, description: res.data.description, loading: false})
            })
    }
    save(){
        api.post('/Profile', {displayName: this.state.displayName, education: this.state.education, description: this.state.description})
        .then(res => {
            NotificationManager.success("Profile Saved", '',1000)
        })
    }
    render() { 
        return ( 
            <div className="desktop_container">
                <Menu screenProps={this.props.history} />
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} />
                    <div className="d_main_content">
                    <div className="d_packages_info_container">
                             <svg width="36" height="93" viewBox="0 0 36 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="noun_Info_73316">
                                <path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M36 11.0174C36 14.0567 34.831 16.6482 32.4916 18.7827C30.1528 20.917 27.3431 21.988 24.0496 21.988C20.7691 21.988 17.9326 20.917 15.58 18.7827C13.2412 16.6482 12.0584 14.0567 12.0584 11.0174C12.0584 7.98328 13.2412 5.3927 15.58 3.23864C17.9326 1.08386 20.7691 0 24.0496 0C27.3431 0 30.1528 1.08386 32.4916 3.23864C34.831 5.3927 36 7.98328 36 11.0174ZM31.3624 82.9082C33.5539 84.5734 31.322 88.2144 27.1009 90.1514C23.4444 91.844 18.9277 93 15.7686 93C10.9025 93 6.74856 90.8255 4.04592 88.479C1.344 86.1394 0 83.1794 0 79.5905C0 78.1891 0.09408 76.7613 0.29556 75.3003C0.51036 73.8404 0.83364 72.1947 1.2636 70.3573L6.30492 52.87C6.762 51.1981 7.12428 49.6056 7.43364 48.1115C7.72956 46.6185 7.90416 45.2367 7.90416 44.0076C7.90416 41.7671 7.42044 40.2141 6.4794 39.3417C5.53824 38.4622 3.76368 38.0203 1.11552 38.0203C-0.18816 38.0203 -1.49232 32.9965 4.3824 30.9745C7.38012 29.9568 10.2433 29.5668 12.9318 29.5668C17.7713 29.5668 21.5084 30.7243 24.1165 33.0168C26.738 35.3169 28.0558 38.304 28.0558 41.9716C28.0558 42.7253 27.9612 44.0603 27.7865 45.9905C27.5986 47.9001 27.2488 49.6585 26.7784 51.2581L21.764 68.6792C21.3608 70.0799 20.9844 71.6865 20.6618 73.4833C20.3392 75.275 20.1913 76.6299 20.1913 77.5545C20.1913 79.8677 20.7022 81.4343 21.764 82.2802C22.8124 83.119 24.6412 83.5357 27.2356 83.5357C27.683 83.5357 28.1912 83.3186 28.718 83.0936C29.6152 82.7105 30.5662 82.3043 31.3624 82.9082Z" fill="#FDB913"/>
                                </g>
                            </svg>
                            <span >
                                {multilanguage.edit_profil_info} (<a href="http://synevo.pl/przedstawiciele">{multilanguage.list}</a>)
                            </span>
                        </div>
                    <div className="d_page_title_container_bordered">
                        <div className="d_page_title">{multilanguage.profile_settings}</div>
                        <div className="dp_save_button" onClick= {()=> this.save()}>
                            <span>{multilanguage.save_changes} </span>
                        </div>
                    </div>
                
                    <div>
                    {this.state.loading ? null : 
                    <>
                    
                        <div className="dep_avt_container">
                            {/* <div className="dep_avt">
                                <img className="dep_avt_image" src={require('../assets/images/desktop/avatarPlaceholder.png')} />
                                <div className="dep_avt_edit">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                     <path fillRule="evenodd" clipRule="evenodd" d="M16.964 1.03424C15.5826 -0.344748 13.3222 -0.344748 11.9408 1.03424L1.18295 11.7319C1.09924 11.8154 1.01552 11.9408 1.01552 12.0662L0.0108889 17.2478C-0.0309706 17.4568 0.0527484 17.6657 0.178327 17.8328C0.303905 17.9582 0.471343 18 0.638781 18H0.76436L5.95494 16.9553C6.08051 16.9135 6.20609 16.8717 6.28981 16.7882L16.964 6.09054C18.3453 4.71155 18.3453 2.45502 16.964 1.03424ZM5.49448 15.7435L1.43411 16.5374L2.22944 12.484L11.1037 3.62507L14.2431 7.00987L5.49448 15.7435ZM15.1222 6.17411L16.0849 5.213C16.964 4.29367 16.964 2.83111 16.0431 1.99536C15.1222 1.11782 13.6989 1.11782 12.778 1.99536L11.9827 2.78932L15.1222 6.17411Z" fill="white"/>
                                    </svg>
                                </div>
                                <input type="file" />
                            </div> */}
                            <div className="dep_avt_name_container">
                                <div className="dep_avt_name">Dr. {auth.auth.user.FirstName}</div>
                                {/* <div className="dep_avt_location">{auth.auth.user.role}</div> */}
                            </div>
                        </div>


                    <div className="dep_input_table">
                        <div className="dep_input_column">

                            <div className="dep_input_container">
                                <div className="dep_input_label">{multilanguage.display_name}</div>
                                <input className="dep_input" type="text" 
                                value={this.state.displayName}
                                onChange={(e) => this.setState({displayName: e.target.value})}
                                />
                            </div>
                            <div className="dep_input_container">
                                <div className="dep_input_label">{multilanguage.education}</div>
                                <input className="dep_input" type="text" 
                                value={this.state.education}
                                onChange={(e) => this.setState({education: e.target.value})}
                                />
                            </div>


                            <div className="dep_input_container">
                                <div className="dep_input_label">{multilanguage.name}</div>
                                <input className="dep_input" type="text" 
                                value={this.state.profile.firstName}
                                disabled = {true}
                                />
                            </div>
                            
                            {/* <div className="dep_input_container">
                                <div className="dep_input_label">{multilanguage.location_simple}</div>
                                <input className="dep_input" type="text" 
                                value={this.state.location} 
                                onchange={this.setState.location}
                                />
                            </div> */}
                        </div>
                        <div className="dep_input_column">
                            
                            <div className="dep_input_container">
                                <div className="dep_input_label">{multilanguage.phone_number}</div>
                                <input className="dep_input" type="text"  
                                value={this.state.profile.phoneNumber}
                                disabled = {true}
                                />
                            </div>
                            <div className="dep_input_container">
                                <div className="dep_input_label">{multilanguage.email_address}</div>
                                <input className="dep_input" type="text" 
                                value={this.state.profile.email} 
                                disabled = {true}
                                />
                            </div>
                            <div className="dep_input_container">
                                <div className="dep_input_label">{multilanguage.last_name}</div>
                                <input className="dep_input" type="text" 
                                value={this.state.profile.lastName} 
                                disabled = {true}
                                />
                            </div>
                        </div>
                        <div className="dep_textarea_column">
                            <div className="dep_textarea_container">
                                <div className="dep_input_label">{multilanguage.short_description}</div>
                                <textarea className="dep_textarea" 
                                value={this.state.description}
                                onChange={(e) => this.setState({description: e.target.value})}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    </>
}
                    </div>


                    </div>
                </div>
            </div>
 )}
}
 
export default DEditProfile;