import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import api from '../api/api';
import auth from '../api/auth';
import multilanguage from '../multilanguage';
import moment from 'moment';
import DLoader from "./parts/DLoader";
import cart from '../api/cart';
class DPatientDetail extends Component {
    state = { 
        loadingRequests: true,
        loadingResults: true,
        loadingPatient: true,
        serviceRequests: [],
        serviceResults: [],
        patient: [],
        parselNumber: '',
     }


    componentDidMount(){
        var parsel = ''
        const id = this.props.match.params.id;
            api.get('/Patient/'+id+'/serviceresult', {pageSize: 2})
            .then(res => {
                this.setState({serviceResults: res.data.results, loadingResults: false});
            })
            api.get('/Patient/'+id+'/servicerequest')
            .then(res => {
                this.setState({serviceRequests: res.data.results, loadingRequests: false});
            })
            
            api.get('/Patient/'+id)
            .then(res => {
                res.data.externalIds.map((external)=>{   
                    if(external.system == 'PESEL'){
                        parsel = external.value
                    }
                })
                this.setState({patient: res.data, loadingPatient: false, parselNumber: parsel});
            })
    }

    addpatienttoorder(patient){
        cart.addPatient(patient)
        this.props.history.push('/library')
    }

    render() { 
        return ( 
            <div className="desktop_container">
                <Menu screenProps={this.props.history} />
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} />
                    <div className="d_main_content">
                        {this.state.loadingPatient ? null :
                        <div className="d_patient_detail_boxes_container">
                           
                            <div className="d_patient_detail_box_item" onClick={() => this.addpatienttoorder(this.state.patient)}>
                                <img src={require('../assets/images/blueBag.svg')} />
                                <div>
                                    <div className="patient_detail_box_title">{multilanguage.order_simple}</div>
                                    <div className="patient_detail_box_subtitle">{multilanguage.send_a_recomandation}</div>
                                </div>
                            </div>
                            {/* <div className="d_patient_detail_box_item"  onClick={() => this.props.history.push('/messages')}>
                                <img src={require('../assets/images/patientChat.svg')} />
                                <div>
                                    <div className="patient_detail_box_title">{multilanguage.chat_simple}</div>
                                    <div className="patient_detail_box_subtitle">{multilanguage.send_a_message}</div>
                                </div>
                            </div> */}
                            {/* <div className="d_patient_detail_box_item" onClick={() => this.props.history.push('/contract')}>
                                <img src={require('../assets/images/contract.svg')} />
                                <div>
                                    <div className="patient_detail_box_title">{multilanguage.contract_details}</div>
                                    <div className="patient_detail_box_subtitle">{multilanguage.see_contract_details}</div>
                                </div>
                            </div>
                            <div className="d_patient_detail_box_item">
                                <img src={require('../assets/images/blueShare.svg')} />
                                <div>
                                    <div className="patient_detail_box_title">{multilanguage.share_request}</div>
                                    <div className="patient_detail_box_subtitle">{multilanguage.send_share_request}</div>
                                </div>
                            </div> */}
                            {/* <div className="d_patient_detail_box_item" onClick={() => this.props.history.push('/health_status')}>
                                <img src={require('../assets/images/blueHealthStatus.svg')} />
                                <div>
                                    <div className="patient_detail_box_title">{multilanguage.health_status}</div>
                                    <div className="patient_detail_box_subtitle">{multilanguage.edit_healts_status}</div>
                                </div>
                            </div> */}
                            <div className="d_patient_detail_box_item"  onClick={() => this.props.history.push('/notes/'+this.props.match.params.id)}>
                                <img src={require('../assets/images/blueNote.svg')} />
                                <div>
                                    <div className="patient_detail_box_title">{multilanguage.notes}</div>
                                    <div className="patient_detail_box_subtitle">{multilanguage.add_personal_notes}</div>
                                </div>
                            </div>
                        </div>}
                        {this.state.loadingResults || this.state.loadingPatient ? <DLoader /> : 
                        <div className="d_patient_detail_column_container">

                            <div className="d_patient_detail_column">
                                <div className="d_patient_detail_column_title">{multilanguage.personal_data}</div>
                                {/* <div className="patient_detail_tags_container">
                                    <div className="patient_detail_tag_item">{multilanguage.smoker}</div>
                                    <div className="patient_detail_tag_item">{multilanguage.diabetic}</div>
                                    <div className="patient_detail_tag_item">{multilanguage.cardiovascular}</div>
                                    <div className="patient_detail_tag_item" onClick={() => this.props.history.push('/health_status')}>
                                        <img src={require('../assets/images/bluePencil.svg')} />
                                    </div>
                                </div> */}
                                
                                <div className="patient_detail_info_container">
                                
                                <div className="patient_detail_info_line">
                                    <div className="patient_info_label">{multilanguage.date_of_birth}</div>
                                    <div className="patient_info_value">{moment(this.state.patient.birthDate).format('DD.MM.YYYY')}</div>
                                </div>
                                <div className="patient_detail_info_line">
                                    <div className="patient_info_label">{multilanguage.phone_simple}</div>
                                    <div className="patient_info_value">{this.state.patient.phoneNumber}</div>
                                </div>
                                <div className="patient_detail_info_line">
                                    <div className="patient_info_label">{multilanguage.email_simple}</div>
                                    <div className="patient_info_value">{this.state.patient.email}</div>
                                </div>
                                <div className="patient_detail_info_line">
                                    <div className="patient_info_label">{multilanguage.pesel_number}</div> 
                                    <div className="patient_info_value">{this.state.parselNumber}</div>
                                </div>
                                {/* <div className="patient_detail_info_line">
                                    <div className="patient_info_label">{multilanguage.last_location}</div>
                                    <div className="patient_info_value">Centrul Synevo "Mama si Copilul"</div>
                                </div> */}
                            </div>
                           
                                
                                <div onClick={() => this.props.history.push('/edit_patient/'+this.state.patient.id)} className="d_patient_detail_column_edit_button">{multilanguage.edit_simple}</div>
                               
                                </div>

                            <div className="d_patient_detail_column">
                                <div className="d_patient_detail_column_title">{multilanguage.results}</div>
                                {this.state.serviceResults ? this.state.serviceResults.map((result)=>{
                                    var nameResult = result.display
                                    
                                    return(
                                        <div className="result_box" onClick={() => result.status == "Ready" ? this.props.history.push('/result/'+result.id): null}>
                                            <div className="patients_box_inner">
                                                <div className="patients_box_top">
                                                    <div className="result_item_first_line">
                                                        <img src={require('../assets/images/blackFile.svg')} />
                                                        <span className="patients_box_value_name">{((nameResult).length > 70) ? (((nameResult).substring(0,70-3)) + '...') : nameResult}</span>
                                                    </div>
                                                </div>
                                                <div className="patients_box_top">
                                                    <div className="patients_box_line">
                                                        <span className="patients_box_label">{multilanguage.name}</span>
                                                        <span className="patients_box_value">{this.state.patient.firstName} {this.state.patient.lastName}</span>
                                                    </div>
                                                    <div className="patients_box_line end">
                                                        <span className="patients_box_label">{multilanguage.pesel_number}</span>
                                                        <span className="patients_box_value">{this.state.parselNumber}</span>                            
                                                    </div>
                                                </div>
                                                <div className="patients_box_top">
                                                <div className="patients_box_line">
                                                    <span className="patients_box_label">{multilanguage.sampling_date} </span>
                                                    <span className="patients_box_value">{moment(result.issued).format('DD.MM.YYYY')}</span>
                                                </div>
                                                <div className="patients_box_line">
                                                {result.status == "Ready" ?
                                                    <div className="result_ready_tag">
                                                        {multilanguage.ready_simple}
                                                    </div>
                                                    :
                                                    <div className="result_pending_tag">
                                                        {multilanguage.pending}
                                                    </div>}
                                                </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    )
                                }) : null}

                                <div className="d_patient_detail_column_edit_button" onClick={() => this.props.history.push({pathname: '/results', state: { pesel: this.state.parselNumber}})}>{multilanguage.view_all}</div>
                            </div>

                            {/* <div className="d_patient_detail_column">
                                <div className="d_patient_detail_column_title">{multilanguage.medical_files}</div>
                                <div className="medical_files_item">
                                    <div className="messages_list_item_left">
                                        <img className="medical_files_picture" src={require('../assets/images/medical_files_placeholder.png')} />
                                        <div className="messages_list_item_middle">
                                            <div className="medical_files_item_name">img_02-radiografie pulmonara</div>
                                            <div className="messages_list_item_text">22.05.2018</div>
                                        </div>
                                    </div>
                                    <div className="messages_list_item_right">
                                        <img className="medical_files_download" src={require('../assets/images/blueDownload.svg')} />
                                    </div>
                                </div>
                                <div className="medical_files_item">
                                    <div className="messages_list_item_left">
                                        <img className="medical_files_picture" src={require('../assets/images/medical_files_placeholder.png')} />
                                        <div className="messages_list_item_middle">
                                            <div className="medical_files_item_name">img_02-radiografie pulmonara</div>
                                            <div className="messages_list_item_text">22.05.2018</div>
                                        </div>
                                    </div>
                                    <div className="messages_list_item_right">
                                        <img className="medical_files_download" src={require('../assets/images/blueDownload.svg')} />
                                    </div>
                                </div>
                                <div className="medical_files_item">
                                    <div className="messages_list_item_left">
                                        <img className="medical_files_picture" src={require('../assets/images/medical_files_placeholder.png')} />
                                        <div className="messages_list_item_middle">
                                            <div className="medical_files_item_name">img_02-radiografie pulmonara</div>
                                            <div className="messages_list_item_text">22.05.2018</div>
                                        </div>
                                    </div>
                                    <div className="messages_list_item_right">
                                        <img className="medical_files_download" src={require('../assets/images/blueDownload.svg')} />
                                    </div>
                                </div>
                                <div className="d_patient_detail_mf_buttons">
                                    <div className="d_patient_detail_column_edit_button" onClick={() => this.props.history.push({pathname: '/medical_files', state: { pesel: this.state.parselNumber}})}>{multilanguage.view_all}</div>
                                    <div className="d_add_patient_button" onClick={() => this.props.history.push('/medical_files')}>
                                        <img  src={require('../assets/images/desktop/plus.svg')} />
                                        <span>{multilanguage.new_file}</span>
                                    </div>
                                </div>
                            
                            </div> */}

                        </div>
                        }
                        {/* LAST ORDERS */}

                        <div className="d_pat_detail_last_orders_container">
                            <div className="d_patient_detail_column_title">{multilanguage.last_simple} {multilanguage.orders_simple} </div>
                            <div className="dpat_table_header">
                            <div className="dpath_nr">{multilanguage.order_no}.</div>
                            <div className="dpath_name">{multilanguage.patient}</div>     
                    <div className="dpath_email">{multilanguage.date_simple}</div>
                    <div className="dpath_city">{multilanguage.services}</div>
                            </div>
                            {this.state.loadingRequests ? <DLoader /> : null}
                            {this.state.serviceRequests ? this.state.serviceRequests.map((requests)=>{
                                var numeservice = ''
                                requests.services.map((service)=>{
                                  numeservice=numeservice+service.name+", "
                                })
                                return(
                                    <div className="dpat_table_line">
                        <div className="dpath_nr_line_order" onClick={() => this.props.history.push('/order_detail/'+requests.id)}>{requests.id}</div>
                        {requests.patient.id ? 
                          <div className="dpath_name_line" onClick={() => this.props.history.push('/patient_detail/'+requests.patient.id)}>
                            <img className="d_header_left_avatar" src={requests.patient.gender == 'F' ? require('../assets/images/famale.png') : require('../assets/images/male.png')} />
                            <span>{requests.patient.firstName} {requests.patient.lastName}</span>
                          </div>
                        : 
                        <div className="dpath_name_line" > 
                            <img className="d_header_left_avatar" src={requests.patient.gender == 'F' ? require('../assets/images/famale.png') : require('../assets/images/male.png')} />
                            <span>{requests.patient.firstName} {requests.patient.lastName}</span>
                        </div>}
                        <div className="dpath_date_line">{moment(requests.validFrom).format('DD.MM.YYYY')}</div>
                        <div className="dpath_city_line" onClick={() => this.props.history.push('/order_detail/'+requests.id)}>{((numeservice).length > 60) ? (((numeservice).substring(0,60-3)) + '...') : numeservice}</div>
                        {/* <div className="dpath_location_line">{requests.locationScope}</div> */}
                        <div className="dpath_details bigger-space" onClick={() => this.props.history.push('/order_detail/'+requests.id)}>
                            <div className="dpath_details_button dpath_details_buttons">{multilanguage.details_simple}</div>
                        </div>
                    </div>
                                )
                            }): null}
                            
                            {this.state.loadingRequests ? '' :
                            <div onClick={() => this.props.history.push({pathname: '/orders', state: { pesel: this.state.parselNumber}})} className="d_patient_detail_column_edit_button">{multilanguage.view_all}</div>
                            }
                            </div>

                    </div>
                </div>
            </div>
 )}
}
 
export default DPatientDetail;