import React from 'react';
import NavBar from './parts/NavBar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import OrderFilters from './parts/OrderFilters';
import multilanguage from '../multilanguage';
import api from '../api/api';
import auth from '../api/auth';
import moment from 'moment';
import DLoader from "./parts/DLoader";
import cart from '../api/cart';
let screenHeight = 0;

export default  class Orders extends React.Component {

    constructor() {
        super();
        this.state={
           visibleNavBar: true,
           filtersVisible:false,
           loading: true,
            requests: [],
            search: null,
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount(){
        AOS.init(); 
        screenHeight = document.documentElement.clientHeight;
        window.addEventListener('resize',this.handleResize);
        if(this.props.location.state){
            this.setState({search: this.props.location.state.pesel})
            api.get('/ServiceRequest', {searchQuery: this.props.location.state.pesel})
                    .then(res => {
                      this.setState({
                        loading: false,
                        requests: res.data.results
                      })
                    })
          }else{
          api.get('/ServiceRequest')
                    .then(res => {
                        this.setState({requests: res.data.results, loading: false});
                    })
          }
    }
    searchtimeout = null
    doSearch(search){
      this.setState({loading: true})
      api.get('/ServiceRequest', {searchQuery: search})
              .then(res => {
                this.setState({
                  loading: false,
                  requests: res.data.results
                })
              })
    }
    search(search){
      this.setState({search: search, requests: []})
      if (this.searchtimeout) clearTimeout(this.searchtimeout)
      this.searchtimeout = setTimeout(() => this.doSearch(search), 1000);
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.handleResize);
    }

    handleResize(){
        if(screenHeight - document.documentElement.clientHeight > 150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: false})
        }
        if(screenHeight - document.documentElement.clientHeight < -150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: true})
        }
    }

    setFilterVisible(){
        this.setState({filtersVisible: true});
    }
    handleCloseFilters(){
        this.setState({filtersVisible: false});
    }

    render() {
        return (
           <div className="app_container">
                <OrderFilters navigation={this.props.history} visible={this.state.filtersVisible} onClose={() => this.handleCloseFilters()} />
               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner space-flex-header" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.orders_simple} </div>
                    <div className="header_cart_container"  onClick={() => this.props.history.push('/cart')}>
                        <img src={require('../assets/images/cartIcon.svg')} />
                        <div className="header_cart_container_bullet">{cart.itemCount}</div>
                    </div>
                </div>
                
                
               </div>

                <div className="orders_search_container">
                    <div className="orders_search_input_container">
                        <img src={require('../assets/images/graysearch.svg')} />
                        <input  type="text" placeholder={multilanguage.search_simple} 
                        value={this.state.search}
                        onChange={evt => this.search(evt.target.value)}
                        disabled={this.state.loading}
                        />
                    </div>
                    {/* <div className="orders_search_filter_button" onClick={() => this.setFilterVisible()}>
                        <img src={require('../assets/images/filterIcon.svg')} />
                    </div> */}
                </div>

                <div className="orders_list_container">

                {/* <div className="orders_letter_delimitator">
                    <div className="orders_letter_delimitator_inner">A</div>
                </div> */}
                {this.state.loading ? <DLoader /> : null}
                {this.state.requests.map((result)=>{
                    return(
                <div className="orders_list_item" onClick={() => this.props.history.push('/order_detail/'+result.id)}>
                <div className="orders_list_item_inner">
                    <div className="orders_list_item_left">
                        <div className="orders_list_item_name">{multilanguage.order_no}. {result.id}</div>
                        <div className="orders_list_item_bottom_info">
                            <div className="orders_list_item_personname">{result.patient.firstName} {result.patient.lastName}</div>
                            <div className="orders_list_item_yellowpoint"></div>
                            <div className="orders_list_item_personname">{moment(result.validFrom).format('DD.MM.YYYY')}</div>
                        </div>
                    </div>
                    <img className="more_menu_list_arrow" src={require('../assets/images/menuArrow.svg')} />
                </div>
                </div>
                    )
                })}
                

                
               

                </div>
                {this.state.visibleNavBar ?
                <div className="orders_buton_fixed" onClick={() => this.props.history.push('/new_order')}>
                    <img src={require('../assets/images/plusShape.svg')} />
                    <span>{multilanguage.new_order}</span>
                </div>
                : null}
                {this.state.visibleNavBar ?
                    <NavBar navigation={this.props.history} />
                : null}

           </div>
)}
}
