import React from 'react';
import multilanguage from '../multilanguage';


export default  class HealthStatus extends React.Component {

    constructor() {
        super();
        this.state={
           selected: [1, 4],
        }
    }

    checkInlist(id){
        var array = this.state.selected;
        var idx = array.indexOf(id);
        if(idx != -1){
            array.splice(idx,1); 
        }
        else{
            array.push(id);
        }
        this.setState({selected : array});
    }

    addComment(){
        document.getElementById('add_comment').style.display = 'flex';
    }
    doneComment(){
        document.getElementById('add_comment').style.display = 'none';
    }

    render() {
        return (
           <div className="app_container">
                <div id="add_comment" className="add_comment_container">
                    <div className="add_comment_container_inner">
                        <div className="add_comment_top">
                            <span>{multilanguage.new_health_status}</span>
                            <div className="close_more_menu" onClick={() => this.doneComment()}>
                                <img src={require('../assets/images/closeMenu.svg')} />
                            </div>
                        </div>
                        <textarea rows="1" placeholder="Complete here.."></textarea>
                        <button onClick={() => this.doneComment()}>{multilanguage.save_simple}</button>
                    </div>
                </div>
               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.choose_tags}</div>
                </div>
               </div>


                <div className="tags_list_container">
                    <div className="tag_list_item">
                        <div className="tag_list_item_inner">
                            <span>{multilanguage.smoker}</span>
                            <div 
                            className={this.state.selected.indexOf(1) != -1 ? "new_order_checked_pacient" : "new_order_unchecked_pacient"}
                            onClick={() => this.checkInlist(1)}>
                                <img src={require('../assets/images/whiteCheck.svg')} />
                            </div>
                        </div>
                    </div>
                    <div className="tag_list_item">
                        <div className="tag_list_item_inner">
                            <span>{multilanguage.diabetic}</span>
                            <div 
                            className={this.state.selected.indexOf(2) != -1 ? "new_order_checked_pacient" : "new_order_unchecked_pacient"}
                            onClick={() => this.checkInlist(2)}>
                                <img src={require('../assets/images/whiteCheck.svg')} />
                            </div>
                        </div>
                    </div>
                    <div className="tag_list_item">
                        <div className="tag_list_item_inner">
                            <span>{multilanguage.stressful_simple}</span>
                            <div 
                            className={this.state.selected.indexOf(3) != -1 ? "new_order_checked_pacient" : "new_order_unchecked_pacient"}
                            onClick={() => this.checkInlist(3)}>
                                <img src={require('../assets/images/whiteCheck.svg')} />
                            </div>
                        </div>
                    </div>
                    <div className="tag_list_item">
                        <div className="tag_list_item_inner">
                            <span>{multilanguage.cardiovascular}</span>
                            <div 
                            className={this.state.selected.indexOf(4) != -1 ? "new_order_checked_pacient" : "new_order_unchecked_pacient"}
                            onClick={() => this.checkInlist(4)}>
                                <img src={require('../assets/images/whiteCheck.svg')} />
                            </div>
                        </div>
                    </div>
                    <div className="tag_list_item" onClick={() => this.addComment()}>
                        <div className="tag_list_item_inner" style={{paddingTop:'5px',paddingBottom:'5px'}}>
                            <span>+ {multilanguage.add_simple} {multilanguage.new_simple}</span>
                        </div>
                    </div>


                </div>

                <div className="orders_buton_fixed_simple" onClick={() => this.props.history.goBack()}>
                    <span>{multilanguage.save_simple}</span>
                </div>


           </div>
)}
}
