import React from 'react';
import NavBar from './parts/NavBar';
import multilanguage from '../multilanguage';
import api from '../api/api';
import auth from '../api/auth';
import moment from 'moment';
import DLoader from "./parts/DLoader";

export default  class OrderDetail extends React.Component {

    constructor() {
        super();
        this.state={
            loading: true,
            order: [],
        }
    }

    componentDidMount(){
        const id = this.props.match.params.id;
        api.get('/ServiceRequest/'+id)
        .then(res => {
            this.setState({order: res.data, loading: false});
        })
        
     }


    render() {
        var total = 0
        
        var totalDiscount = 0
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="home_header_inner">
                    <div className="order_detail_back_icon" onClick={() => this.props.history.goBack()}>
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                   <div className="order_detail_header_title">{multilanguage.preorder_no}. {this.props.match.params.id}</div>
                    <div className="morE_menu_left_white_space"></div>
                </div>
               </div>
               {this.state.loading ? <DLoader /> :
                <div className="medical_library_container">
                    <div className="order_detail_qr_container">
                        <img src={this.state.order.patient.gender == 'F' ? require('../assets/images/famale.png') : require('../assets/images/male.png')} />
                        <span>{this.state.order.patient.firstName} {this.state.order.patient.lastName}</span>
                    </div>

                    <div className="order_detail_line">
                        <img src={require('../assets/images/blueCalendar.svg')} />
                        <span>{moment(this.state.order.validFrom).format('DD.MM.YYYY')}</span>
                    </div>
                    {/* <div className="order_detail_line">
                        <img src={require('../assets/images/bluePin.svg')} />
                        <span>{this.state.order.locationScope}</span>
                    </div> */}
                    <div className="order_detail_line_between">
                        <div className="order_detail_line_left">
                            <img src={require('../assets/images/blueBag.svg')} />
                            <span>{multilanguage.services}</span>
                        </div> 
                        <img src={require('../assets/images/dropdownArrow.svg')} /> 
                    </div>
                    {this.state.order.services.map((service)=>{
                                total = total + service.price
                                totalDiscount = totalDiscount + service.basePrice
                                return(
                    <div className="order_detail_line_product">
                        <span className="order_detail_product_name">{service.name}</span>
                        {service.basePrice == service.price ?
                        <span className="order_detail_product_price">{service.price} {multilanguage.currency}</span>:
                        <span className="order_detail_product_price"><span className="discount-text">{service.basePrice} {multilanguage.currency}</span> {service.price} {multilanguage.currency}</span>}
                    </div>
                                )
                    })}
{total == totalDiscount ?
                    <div className="order_detail_list_total">
                        {multilanguage.total_simple}  {total} {multilanguage.currency} 
                    </div>:
                    <div className="order_detail_list_total">
                    {multilanguage.total_simple}  <span className="discount-text">{totalDiscount} {multilanguage.currency}</span> {total} {multilanguage.currency} 
                    </div>
                    }

                    <div className="order_detail_buttons_container">
                        <div onClick={() => window.print()} className="order_detail_yellow_button">{multilanguage.print_sample}</div>
                        {/* <div className="order_detail_blue_button">{multilanguage.send_on_email}</div> */}
                    </div>

                </div>}

                  {/* swiper */}

                  <NavBar navigation={this.props.history} />

           </div>
)}
}
