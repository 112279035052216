import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import multilanguage from '../multilanguage';
import DLoader from "./parts/DLoader";
import api from '../api/api';
import auth from '../api/auth';
import {NotificationManager} from 'react-notifications';
class DNotes extends Component {
    state = { 
        visibleAddNew: false,
        visibleDetail: false,
        loading: true,
        notes: [],
        title: null,
        text: null,
        selectedNoteTitle: null,
        selectedNoteText: null,
        selectedNote: null,
     }
     componentDidMount(){
        this.getNewNotes()
     }
     addNote(){
        this.setState({loading: true})
        // this.setState({visibleAddNew:false})
        const id = this.props.match.params.id
        if(this.state.title && this.state.text){
            api.put('/Patient/'+id+'/note', {title: this.state.title, text: this.state.text})
            .then(res => {
                this.getNewNotes()
                this.setState({visibleAddNew:false, loading: false, text: null, title: null})
            })
        }
     }
     getNewNotes(){
        const id = this.props.match.params.id
        api.get('/Patient/'+id+'/note')
        .then(res => {
            
            this.setState({loading: false, notes: res.data})
        })
     }
     editNote(){
        const id = this.props.match.params.id
         
         api.put('/Patient/'+id+'/note', {id: this.state.selectedNote, text: this.state.selectedNoteText, title: this.state.selectedNoteTitle})
            .then(res => {
                this.getNewNotes()
                this.setState({visibleDetail:false, loading: false})
            })
     }
    render() { 
        return ( 
            <div className="desktop_container">
                <Menu screenProps={this.props.history} />
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} detail={true} />
                    <div className="d_main_content">
                    <div className="d_packages_info_container">
                             <svg width="36" height="93" viewBox="0 0 36 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="noun_Info_73316">
                                <path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M36 11.0174C36 14.0567 34.831 16.6482 32.4916 18.7827C30.1528 20.917 27.3431 21.988 24.0496 21.988C20.7691 21.988 17.9326 20.917 15.58 18.7827C13.2412 16.6482 12.0584 14.0567 12.0584 11.0174C12.0584 7.98328 13.2412 5.3927 15.58 3.23864C17.9326 1.08386 20.7691 0 24.0496 0C27.3431 0 30.1528 1.08386 32.4916 3.23864C34.831 5.3927 36 7.98328 36 11.0174ZM31.3624 82.9082C33.5539 84.5734 31.322 88.2144 27.1009 90.1514C23.4444 91.844 18.9277 93 15.7686 93C10.9025 93 6.74856 90.8255 4.04592 88.479C1.344 86.1394 0 83.1794 0 79.5905C0 78.1891 0.09408 76.7613 0.29556 75.3003C0.51036 73.8404 0.83364 72.1947 1.2636 70.3573L6.30492 52.87C6.762 51.1981 7.12428 49.6056 7.43364 48.1115C7.72956 46.6185 7.90416 45.2367 7.90416 44.0076C7.90416 41.7671 7.42044 40.2141 6.4794 39.3417C5.53824 38.4622 3.76368 38.0203 1.11552 38.0203C-0.18816 38.0203 -1.49232 32.9965 4.3824 30.9745C7.38012 29.9568 10.2433 29.5668 12.9318 29.5668C17.7713 29.5668 21.5084 30.7243 24.1165 33.0168C26.738 35.3169 28.0558 38.304 28.0558 41.9716C28.0558 42.7253 27.9612 44.0603 27.7865 45.9905C27.5986 47.9001 27.2488 49.6585 26.7784 51.2581L21.764 68.6792C21.3608 70.0799 20.9844 71.6865 20.6618 73.4833C20.3392 75.275 20.1913 76.6299 20.1913 77.5545C20.1913 79.8677 20.7022 81.4343 21.764 82.2802C22.8124 83.119 24.6412 83.5357 27.2356 83.5357C27.683 83.5357 28.1912 83.3186 28.718 83.0936C29.6152 82.7105 30.5662 82.3043 31.3624 82.9082Z" fill="#FDB913"/>
                                </g>
                            </svg>
                            <span >
                                {multilanguage.notes_info}
                            </span>
                        </div>
                    <div className="d_page_title_container_bordered">
                        <div className="d_page_title_with_button">
                            <div className="d_page_title">{multilanguage.notes}</div>
                            <div className="d_add_patient_button" onClick={() => this.setState({visibleAddNew:true})}>
                                <img  src={require('../assets/images/desktop/plus.svg')} />
                                <span>{multilanguage.new_note}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div className="d_notes_container">
                        {this.state.loading ? <DLoader/> :
                        this.state.notes.map((note)=>{
                            return(
                            <div className="d_notes_line" onClick={() => this.setState({visibleDetail:true, selectedNoteTitle: note.title, selectedNote: note.id, selectedNoteText: note.text})}>
                                <div className="d_notes_line_left">
                                <svg width="19" height="26" viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.6119 2.06138H16.6643C17.9522 2.06138 19 3.21927 19 4.64256V23.4188C19 24.8421 17.9522 26 16.6643 26H2.33569C1.04777 26 0 24.8421 0 23.4188V4.64256C0 3.2193 1.0478 2.06138 2.33569 2.06138H2.3881V0.746371C2.3881 0.334154 2.70483 0 3.09556 0C3.48629 0 3.80302 0.334154 3.80302 0.746371V2.06138H5.59028V0.746371C5.59028 0.334154 5.90701 0 6.29774 0C6.68847 0 7.0052 0.334154 7.0052 0.746371V2.06138H8.79249V0.746371C8.79249 0.334154 9.10923 0 9.49995 0C9.89065 0 10.2074 0.334154 10.2074 0.746371V2.06138H11.9947V0.746371C11.9947 0.334154 12.3114 0 12.7022 0C13.0929 0 13.4097 0.334154 13.4097 0.746371V2.06138H15.197V0.746371C15.197 0.334154 15.5137 0 15.9044 0C16.2952 0 16.6119 0.334154 16.6119 0.746371V2.06138ZM16.6643 24.5073C17.172 24.5073 17.5851 24.019 17.5851 23.4188H17.5851V4.64256C17.5851 4.04237 17.172 3.55409 16.6643 3.55409H16.6119V4.8691C16.6119 5.28132 16.2952 5.61548 15.9045 5.61548C15.5137 5.61548 15.197 5.28132 15.197 4.8691V3.55409H13.4097V4.8691C13.4097 5.28132 13.093 5.61548 12.7022 5.61548C12.3115 5.61548 11.9948 5.28132 11.9948 4.8691V3.55409H10.2074V4.8691C10.2074 5.28132 9.89071 5.61548 9.49998 5.61548C9.10926 5.61548 8.79252 5.28132 8.79252 4.8691V3.55409H7.00523V4.8691C7.00523 5.28132 6.6885 5.61548 6.29777 5.61548C5.90704 5.61548 5.59031 5.28132 5.59031 4.8691V3.55409H3.80302V4.8691C3.80302 5.28132 3.48629 5.61548 3.09556 5.61548C2.70483 5.61548 2.3881 5.28132 2.3881 4.8691V3.55409H2.33569C1.82796 3.55409 1.41492 4.04237 1.41492 4.64256V23.4188C1.41492 24.019 1.82799 24.5073 2.33569 24.5073H16.6643Z" fill="#00549E"/>
                                    <path d="M15.8329 9.4541H3.16865C3.03344 9.4541 2.92383 9.63048 2.92383 9.84804C2.92383 10.0656 3.03341 10.242 3.16865 10.242H15.8329C15.9681 10.242 16.0777 10.0657 16.0777 9.84804C16.0777 9.63043 15.9681 9.4541 15.8329 9.4541Z" fill="#00549E"/>
                                    <path d="M15.8329 14.9697H3.16865C3.03344 14.9697 2.92383 15.1462 2.92383 15.3637C2.92383 15.5813 3.03341 15.7576 3.16865 15.7576H15.8329C15.9681 15.7576 16.0777 15.5813 16.0777 15.3637C16.0777 15.1462 15.9681 14.9697 15.8329 14.9697Z" fill="#00549E"/>
                                    <path d="M15.8329 19.6973H3.16865C3.03344 19.6973 2.92383 19.8736 2.92383 20.0913C2.92383 20.3088 3.03341 20.4851 3.16865 20.4851H15.8329C15.9681 20.4851 16.0777 20.3088 16.0777 20.0913C16.0777 19.8736 15.9681 19.6973 15.8329 19.6973Z" fill="#00549E"/>
                                </svg>
                                    <span>{note.title}</span>
                                </div>
                            <   div className="d_notes_line_center">{note.text}</div>
                                {/* <div className="d_notes_line_right">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.5">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0 9C0 4.06047 4.0186 0 9 0C13.9814 0 18 4.06047 18 9C18 13.9814 13.9814 18 9 18C4.0186 18 0 13.9814 0 9ZM1.25581 9C1.25581 13.2698 4.73023 16.7442 9 16.7442C13.2698 16.7442 16.7442 13.2698 16.7442 9C16.7442 4.73023 13.2698 1.25581 9 1.25581C4.73023 1.25581 1.25581 4.73023 1.25581 9Z" fill="#2F2842"/>
                                    <path d="M9.63057 3.42871C9.28116 3.42871 8.97543 3.73251 8.97543 4.07971V8.98386L6.13649 12.6728C5.91811 12.9766 5.96178 13.3672 6.26752 13.5842C6.39854 13.671 6.52957 13.7144 6.6606 13.7144C6.87898 13.7144 7.05369 13.6276 7.18471 13.454L10.1547 9.59146C10.242 9.46126 10.2857 9.33106 10.2857 9.20086V4.07971C10.2857 3.73251 9.97998 3.42871 9.63057 3.42871Z" fill="#2F2842"/>
                                    </g>
                                </svg>
                                    <span>24 Nov 2018 at 9:30 AM</span>
                                </div> */}
                            </div>
                            )
                        })}
                    </div>

                    </div>
                </div>

                {this.state.visibleAddNew ?
                <div className='d_add_note_container'>
                    <div className="d_add_note_box_container">
                        <div className="d_add_note_header">
                            <div></div>
                            <div className="daddnote_title">{multilanguage.new_note}</div>
                            <div className="daddnote_close" onClick={() => this.setState({visibleAddNew:false, title: null, text: null})}>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.231701">
                                    <path d="M14.6786 0.3C14.25 -0.1 13.6071 -0.1 13.1786 0.3L7.5 5.6L1.82143 0.3C1.39286 -0.1 0.75 -0.1 0.321429 0.3C-0.107143 0.7 -0.107143 1.3 0.321429 1.7L6 7L0.321429 12.3C-0.107143 12.7 -0.107143 13.3 0.321429 13.7C0.535714 13.9 0.75 14 1.07143 14C1.39286 14 1.60714 13.9 1.82143 13.7L7.5 8.4L13.1786 13.7C13.3929 13.9 13.7143 14 13.9286 14C14.1429 14 14.4643 13.9 14.6786 13.7C15.1071 13.3 15.1071 12.7 14.6786 12.3L9 7L14.6786 1.7C15.1071 1.3 15.1071 0.7 14.6786 0.3Z" fill="black"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <input type="text" placeholder="Title" onChange={(e) => this.setState({title: e.target.value})} defaultValue={this.state.title}/>
                        <textarea rows="10" placeholder="Write your text here..." onChange={(e) => this.setState({text: e.target.value})} defaultValue={this.state.text}></textarea>
                        <div className="daddnote_save_button" onClick={() => this.addNote()}>{multilanguage.save_simple}</div>
                        <div className="daddnote_cancel_button" onClick={() => this.setState({visibleAddNew:false, title:null, text: null})}>{multilanguage.cancel_simple}</div>
                    </div>
                </div>
                : null}
                {this.state.visibleDetail ?
                <div className='d_add_note_container'>
                    <div className="d_add_note_box_container">
                        <div className="d_add_note_header">
                            <div></div>
                            <div className="daddnote_title">{multilanguage.to_buy}</div>
                            <div className="daddnote_close" onClick={() => this.setState({visibleDetail:false})}>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.231701">
                                    <path d="M14.6786 0.3C14.25 -0.1 13.6071 -0.1 13.1786 0.3L7.5 5.6L1.82143 0.3C1.39286 -0.1 0.75 -0.1 0.321429 0.3C-0.107143 0.7 -0.107143 1.3 0.321429 1.7L6 7L0.321429 12.3C-0.107143 12.7 -0.107143 13.3 0.321429 13.7C0.535714 13.9 0.75 14 1.07143 14C1.39286 14 1.60714 13.9 1.82143 13.7L7.5 8.4L13.1786 13.7C13.3929 13.9 13.7143 14 13.9286 14C14.1429 14 14.4643 13.9 14.6786 13.7C15.1071 13.3 15.1071 12.7 14.6786 12.3L9 7L14.6786 1.7C15.1071 1.3 15.1071 0.7 14.6786 0.3Z" fill="black"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <textarea rows="10" onChange={(e) => this.setState({selectedNoteText: e.target.value})} defaultValue={this.state.selectedNoteText} placeholder={this.state.selectedNoteText}></textarea>
                        <div className="daddnote_save_button" onClick={() => this.editNote()}>{multilanguage.edit_simple}</div>
                        <div className="daddnote_cancel_button" onClick={() => this.setState({visibleDetail:false})}>{multilanguage.cancel_simple}</div>
                    </div>
                </div>
                : null}
            </div>
         );
    }
}
 
export default DNotes;