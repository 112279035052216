import React, { Component } from "react";
import Header from "./parts/Header";
import Menu from "./parts/Menu";
import multilanguage from '../multilanguage';
import api from '../api/api';
import auth from '../api/auth';
import moment from 'moment';
import DLoader from "./parts/DLoader";
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

class DOrders extends Component {
  state = {
    loading: true,
    requests: [],
    search: '',
    selectedPage: 1,
    infinitePcrollPage: 1,
    items: [],
    hasMore: true,
  };
componentDidMount(){
  if(this.props.location.state){
    this.doSearch(this.props.location.state.pesel, 1)
  }else{
  this.doSearch('')
  }
}
searchtimeout = null
  doSearch(search){
    if(this.state.value == search)
    this.setState({loading: true})
    else
    this.setState({loading: true, items: [], infinitePcrollPage: 1, value: search})
    api.get('/ServiceRequest', {searchQuery: search, page: this.state.infinitePcrollPage})
            .then(res => {
              var numberOfPages = res.data.totalResults/res.data.pageSize
                    if(Number.isInteger(numberOfPages)){
                        this.setState({
                          loading: false,
                          items: this.state.items.concat(res.data.results),
                          numberOfPages : numberOfPages,
                        })
                    }else{
                        numberOfPages = Math.floor(numberOfPages)+1
                        this.setState({
                          loading: false,
                          items: this.state.items.concat(res.data.results),
                          numberOfPages : numberOfPages,
                        })
                    }
                    if(this.state.infinitePcrollPage < numberOfPages){
                      this.setState({infinitePcrollPage: this.state.infinitePcrollPage+1})
                  }else{
                      this.setState({hasMore: false})
                  }
              
            })
  }
  search(search){
    this.setState({items: [], value: search, infinitePcrollPage: 1})
    if (this.searchtimeout) clearTimeout(this.searchtimeout)
    this.searchtimeout = setTimeout(() => this.doSearch(search), 1000);
  }
  
  render() {
    return (
      <div className="desktop_container">
        <Menu screenProps={this.props.history} />
        <div className="desktop_container_content">
          <Header screenProps={this.props.history} />
          <div className="d_main_content">
            <div className="d_page_title_container">
                <div className="d_page_title_with_button">
                    <div className="d_page_title">{multilanguage.orders_simple} </div>
                    <div className="d_add_patient_button" onClick={() => this.props.history.push('/library')}>
                        <img  src={require('../assets/images/desktop/plus.svg')} />
                        <span>{multilanguage.new_order}</span>
                    </div>
                </div>
              
              <div className="d_page_title_right_flex">
                {/* <div className="d_page_title_sort">
                  <span>{multilanguage.sort_by}: </span>
                  <select>
                    <option>{multilanguage.newest_simple}</option>
                    <option>{multilanguage.type_simple}</option>
                  </select>              
                </div> */}
                <div className="d_page_title_search_container">
                    <img src={require('../assets/images/desktop/search1.svg')} />
                      <input 
                      value={this.state.value}
                      onChange={evt => this.search(evt.target.value)}
                      disabled={this.state.loading}
                      type="text" />
                </div>
              </div>

            </div>
            <div className="">

                <div className="dpat_table_header">
                <div className="dpath_nr">{multilanguage.order_no}.</div>
                    <div className="dpath_name">{multilanguage.patient}</div>     
                    <div className="dpath_email">{multilanguage.date_simple}</div>
                    <div className="dpath_city">{multilanguage.services}</div>
                    {/* <div className="dpath_location">{multilanguage.location_simple}</div> */}
                </div>
                {this.state.loading && !this.state.hasMore ? <DLoader /> : null}
                <InfiniteScroll
                  dataLength={this.state.items.length} //This is important field to render the next data
                  next={() => {if(!this.state.loading) this.doSearch(this.state.value)}}
                  hasMore={this.state.hasMore}
                  scrollableTarget="index"
                  scrollThreshold= {0.9}
                  loader={<DLoader />}
                  children
                  >
                  {this.state.items.map((result)=>{
                      var numeservice = ''
                      result.services.map((service)=>{
                        numeservice=numeservice+service.name+", "
                      })
                      return(
                        <div className="dpat_table_line">
                        <div className="dpath_nr_line_order" onClick={() => this.props.history.push('/order_detail/'+result.id)}>{result.id}</div>
                        {result.patient.id ? 
                          <div className="dpath_name_line" onClick={() => this.props.history.push('/patient_detail/'+result.patient.id)}>
                            <img className="d_header_left_avatar" src={result.patient.gender == 'F' ? require('../assets/images/famale.png') : require('../assets/images/male.png')} />
                            <span>{result.patient.firstName} {result.patient.lastName}</span>
                          </div>
                        : 
                        <div className="dpath_name_line" > 
                            <img className="d_header_left_avatar" src={result.patient.gender == 'F' ? require('../assets/images/famale.png') : require('../assets/images/male.png')} />
                            <span>{result.patient.firstName} {result.patient.lastName}</span>
                        </div>}
                        <div className="dpath_date_line">{moment(result.validFrom).format('DD.MM.YYYY')}</div>
                        <div className="dpath_city_line" onClick={() => this.props.history.push('/order_detail/'+result.id)}>{((numeservice).length > 60) ? (((numeservice).substring(0,60-3)) + '...') : numeservice}</div>
                        {/* <div className="dpath_location_line">{result.locationScope}</div> */}
                        <div className="dpath_details bigger-space" onClick={() => this.props.history.push('/order_detail/'+result.id)}>
                            <div className="dpath_details_button dpath_details_buttons">{multilanguage.details_simple}</div>
                        </div>
                    </div>
                  
                      )
                  })}
                </InfiniteScroll>
                {/* {this.state.items.map((result)=>{
                  return(
                
                    <div className="dpat_table_line">
                        <div className="dpath_nr_line_order">{result.id}</div>
                        {result.patient.id ? 
                          <div className="dpath_name_line" onClick={() => this.props.history.push('/patient_detail/'+result.patient.id)}>
                            <img className="d_header_left_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                            <span>{result.patient.firstName} {result.patient.lastName}</span>
                          </div>
                        : 
                        <div className="dpath_name_line" > 
                            <img className="d_header_left_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                            <span>{result.patient.firstName} {result.patient.lastName}</span>
                        </div>}
                        <div className="dpath_email_line">{moment(result.validFrom).format('DD.MM.YYYY')}</div>
                        <div className="dpath_city_line">Bucharest</div>
                        <div className="dpath_location_line">{result.locationScope}</div>
                        <div className="dpath_details" onClick={() => this.props.history.push('/order_detail/'+result.id)}>
                            <div className="dpath_details_button">{multilanguage.details_simple}</div>
                        </div>
                    </div>
                  
                  )
                })} */}
                {/* {this.state.loading || isEmpty(this.state.requests) || this.state.numberOfPages <= 1 ? null :
                <div class="pagination">
                    <div className="d_store_menu_buttons" onClick={() => this.doSearch(this.state.value, this.state.selectedPage > 1 ?  this.state.selectedPage-1 : this.state.selectedPage)}>
                        <span>{multilanguage.previous_page}</span>
                    </div>
                    <div className="d_store_menu_buttons" onClick={() => this.doSearch(this.state.value, this.state.selectedPage < this.state.numberOfPages ?  this.state.selectedPage+1 : this.state.selectedPage)}>
                        <span>{multilanguage.next_page}</span>
                    </div> 
                </div> } */}

            </div>
          </div>
        </div>
      </div>
)}
}

export default DOrders;
