import React from 'react';
import MoreMenu from './MoreMenu';
import multilanguage from '../../multilanguage';



export default  class NavBar extends  React.PureComponent {

    constructor() {
        super();
        this.state={
            openMenu: false,
        }

    }

    openMenu(){
        var inv = !this.state.openMenu;
        this.setState({openMenu : inv});
    }

    closeMenu(){
        this.setState({openMenu : false});
    }

    navigate(to){
        this.props.navigation.push(to);
    }

  

    render() {
        return (
            <div>
            <MoreMenu navigation={this.props.navigation} open={this.state.openMenu} onCloseMenu={() => this.closeMenu()} />
            <div className="navbar">
                <div className="navbar_inner">
                    <div className="navbar_item"  onClick={() => this.navigate('/home')}>
                        <img src={require('../../assets/images/home.svg')} />
                        <span>{multilanguage.home_simple} </span>
                    </div>
                    <div className="navbar_item" onClick={() => this.navigate('/orders')}>
                        <img src={require('../../assets/images/orders.svg')} />
                        <span>{multilanguage.orders_simple} </span>
                    </div>
                    <div className="navbar_item" onClick={() => this.navigate('/latest_results')}>
                        <img  className="navbar_item_image_more" src={require('../../assets/images/file-black.svg')} />
                        <span>{multilanguage.results}</span>
                    </div>
                    <div className="navbar_item" onClick={() => this.navigate('/patients')}>
                        <img src={require('../../assets/images/patinetsTab.svg')} />
                        <span>{multilanguage.patients_simple} </span>
                    </div>
                    <div className={this.state.openMenu ? "navbar_item_active" : "navbar_item"} onClick={() => this.openMenu()}>
                        <img src={this.state.openMenu ?  require('../../assets/images/menuMoreBlue.svg') : require('../../assets/images/menuMore.svg')} />
                        <span>{multilanguage.more_simple} </span>
                    </div>
                </div>
            </div>
            </div>
)}
}
