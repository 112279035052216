import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import { FilePicker } from 'react-file-picker';
import api from '../api/api';
import auth from '../api/auth';
import multilanguage from '../multilanguage';

class DCreateTemplate extends Component {
    state = {  }

    componentDidMount(){
            api.get('/Profile/ordertemplate')
            .then(res => {
            })
    }
    render() { 
        return ( 
            <div className="desktop_container">
                <Menu screenProps={this.props.history} />
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} />
                    <div className="d_main_content">
                    <div className="d_page_title_container_bordered">
                        <div className="d_page_title">{multilanguage.order_template}</div>
                        <div className="dp_save_button">
                            <span>{multilanguage.save_changes}</span>
                        </div>
                    </div>

                    <div className="dot_container">
                        
                        <div className="dot_left_container">

                            <div className="dot_left_title">{multilanguage.upload_your_logo}</div>
                            <div className="dot_left_subtitle">{multilanguage.logo_must_be_in_png}</div>

                            <div className="dot_boxes_container">
                            <FilePicker
                                extensions={[]}
                                // onChange={}
                                // onError={}
                            >
                            <div className="orders_template_item orders_template_item_add">
                                <img src={require('../assets/images/bluePlus.svg')} />
                                <span>{multilanguage.add_file}</span>
                            </div>
                            </FilePicker>

                            <div className="orders_template_item orders_template_item_selected">
                                <img src={require('../assets/images/templatePlaceholder.png')} />
                                <div className="order_template_item_delete">
                                    <img src={require('../assets/images/closeMenu.svg')} />
                                </div>
                                <div className="order_template_item_selected">{multilanguage.selected}</div>
                            </div>
                            <div className="orders_template_item">
                                <img src={require('../assets/images/templatePlaceholder.png')} />
                                <div className="order_template_item_delete">
                                    <img src={require('../assets/images/closeMenu.svg')} />
                                </div>
                            </div>

                            </div>


                        </div>


                        <div className="dot_right_container">
                            <div className="dot_right_title">{multilanguage.preview}</div>
                            <div className="dot_preview_box">
                                <div className="dot_preview_header">
                                    <img src={require('../assets/images/templatePlaceholder.png')} />
                                    <div className="dot_preview_header_right">
                                        <div>12-02-2020</div>
                                        <div>S.C. Calico Medical S.R.L.</div>
                                        <div>Dr. Roberts</div>
                                    </div>
                                </div>
                                <div className="dot_preview_name">
                                     {multilanguage.order_number} 454500
                                </div>
                                <div className="dot_preview_line"></div>
                                <div className="dot_preview_line"></div>
                                <div className="dot_preview_line"></div>
                                <div className="dot_preview_line"></div>
                                <div className="dot_preview_line"></div>
                                <div className="dot_preview_line"></div>
                                <div className="dot_preview_line"></div>
                            </div>
                        </div>


                    </div>


                    </div>
                </div>
            </div>
 )}
}
 
export default DCreateTemplate;