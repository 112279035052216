import React from 'react';
import NavBar from './parts/NavBar';
import { FilePicker } from 'react-file-picker';
import multilanguage from '../multilanguage';

export default  class CreateTemplate extends React.Component {

    constructor() {
        super();
        this.state={
           selected : 1,
        }
    }

    changeSelected(id){
        this.setState({selected : id})
    }

    render() {
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.create_orders_template}</div>
                </div>
               </div>

                <div className="editProfile_container_intreg">

                    <div className="edit_profile_form_container_padding">
                        <div className="myLocationLabel">{multilanguage.upload_logo}</div>
                        <div className="myLocationSubLabel">{multilanguage.upload_photo_png_format}</div>
                    </div> 

                    <div className="horizontal_scroll_container">
                    <div className="orders_template_scroll_container">
                        <FilePicker
                            extensions={[]}
                            // onChange={}
                            // onError={}
                        >
                        <div className="orders_template_item orders_template_item_add">
                            <img src={require('../assets/images/bluePlus.svg')} />
                            <span>{multilanguage.add_file}</span>
                        </div>
                        </FilePicker>

                        <div className="orders_template_item orders_template_item_selected">
                            <img src={require('../assets/images/templatePlaceholder.png')} />
                            <div className="order_template_item_delete">
                                <img src={require('../assets/images/closeMenu.svg')} />
                            </div>
                            <div className="order_template_item_selected">{multilanguage.selected}</div>
                        </div>

                            
                        <div className="orders_template_item">
                            <img src={require('../assets/images/templatePlaceholder.png')} />
                            <div className="order_template_item_delete">
                                <img src={require('../assets/images/closeMenu.svg')} />
                            </div>
                        </div>

                    </div>
                    </div>
                   
                    <div className="edit_profile_form_container_padding">
                        <div className="myLocationLabel">{multilanguage.preview}</div>

                        <div className="order_template_preview_container">
                            <img src={require('../assets/images/templatePlaceholder.png')} />
                            <span>{multilanguage.order_number} 45400</span>
                            <span>Dr. Roberts</span>
                        </div>
 

                        <button>{multilanguage.save_simple}</button>
                    </div>
                </div>
                

                <NavBar navigation={this.props.history} />

           </div>
        );
    }
}
