import React from 'react';
import NavBar from './parts/NavBar';
import multilanguage from '../multilanguage';
import DLoader from "./parts/DLoader";
import _ from 'lodash';
import api from '../api/api';
import auth from '../api/auth';
import Fuse from 'fuse.js'
import cart from '../api/cart';
let screenHeight = 0;
export default  class NewOrder extends React.Component {

    constructor() {
        super();
        this.state={
            patients: [],
            loading: true,
            search: null,
           selectedID: '',
            phoneNumber: '',
           selectedPatient: null,
           visibleNavBar: true,
        }
        this.handleResize = this.handleResize.bind(this);
    }

    selectPatient(id, selectedPatient){
        this.setState({selectedID: id, selectedPatient: selectedPatient});
    }
    saveNumber(){
        var patient = {
            phoneNumber: this.state.phoneNumber
        }
        cart.addPatient(patient)
        this.setState({showphonefield: false, phoneNumber: ""})
        this.props.history.push('/library')
    }
    componentDidMount(){
        screenHeight = document.documentElement.clientHeight;
        window.addEventListener('resize',this.handleResize);
        api.get('/Patient')
        .then(res => {   
        this.setState({patients: res.data.results, loading: false});
        })
    }
    searchtimeout = null
    doSearch(search){
      this.setState({loading: true})
      api.get('/Patient', {searchQuery: search})
              .then(res => {
                this.setState({
                  loading: false,
                  patients: res.data.results
                })
              })
    }
    search(search){
      this.setState({patients: []})
      if (this.searchtimeout) clearTimeout(this.searchtimeout)
      this.searchtimeout = setTimeout(() => this.doSearch(search), 1000);
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.handleResize);
    }
    createOrder(){
        cart.addPatient(this.state.selectedPatient)
        this.props.history.push('/library')

    }
    handleResize(){
        if(screenHeight - document.documentElement.clientHeight > 150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: false})
        }
        if(screenHeight - document.documentElement.clientHeight < -150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: true})
        }
    }

    render() {
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.new_order}</div>
                </div>
               </div>

               {/* <div className="new_order_top_intro">
                    <div className="new_order_top_intro_text">{multilanguage.please_select_the_patient_or_add}</div>
                    <div className="cart_phone_input">
                        <input className="d_add_pateint_input" type="text" placeholder={multilanguage.phone_number} onChange={(e) => this.setState({phoneNumber: e.target.value})} value={this.state.phoneNumber} />
                        <div className="d_add_patient_button button_margin" onClick={() => this.saveNumber()}>
                            <img  src={require('../assets/images/desktop/plus.svg')} />
                            <span>{multilanguage.save_patient}</span>
                        </div>
                    </div>
               </div> */}

                <div className="orders_search_container">
                    <div className="messages_search_input_container">
                        <img src={require('../assets/images/graysearch.svg')} />
                        <input 
                        onChange={evt => this.search(evt.target.value)}
                        disabled={this.state.loading}
                        type="text" placeholder={multilanguage.search_simple} />
                    </div>
                </div>

                <div className="orders_list_container">
                {this.state.loading ? <DLoader /> : null}
                {this.state.patients.map((persoana) => {
                    var pesel = ''
                    persoana.externalIds.map((external)=>{   
                        if(external.system == 'PESEL'){
                            pesel = external.value
                        }
                    })
                  return(
                <div className="patients_box_container" onClick={() => this.selectPatient(persoana.id, persoana)}>
                    <div className="patients_box_inner">
                        <div className="patients_box_top">
                            <div className="patients_box_line">
                                <span className="patients_box_label">{multilanguage.name}</span>
                                <span className="patients_box_value_name">{persoana.firstName} {persoana.lastName}</span>
                            </div>
                        </div>
                        <div className="patients_box_top">
                            <div className="patients_box_line">
                                <span className="patients_box_label">{multilanguage.pesel_number}</span>
                                <span className="patients_box_value">{pesel}</span>
                            </div>
                            <div className={this.state.selectedID == persoana.id ? "new_order_checked_pacient" : "new_order_unchecked_pacient"}>
                                <img src={require('../assets/images/whiteCheck.svg')} />
                            </div>
                        </div>
                        {/* <div className="patients_box_line">
                            <span className="patients_box_label">{multilanguage.last_location}</span>
                            <span className="patients_box_value">Centrul Synevo "Mama si Copilul"</span>
                        </div> */}
                    </div>
                </div>
                  )
                })}
                </div>
                {this.state.visibleNavBar ?
                <div className="orders_buton_fixed_simple" onClick={() => this.createOrder()}>
                    <span>{multilanguage.create_order}</span>
                </div>
                : null}
                {this.state.visibleNavBar ?
                <NavBar navigation={this.props.history} />
                : null}

           </div>
)}
}
