import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import Switch from '../components/parts/Switch';
import multilanguage from '../multilanguage';
import api from '../api/api';
import auth from '../api/auth';
import moment from 'moment';
import DLoader from "./parts/DLoader";
import {NotificationManager} from 'react-notifications';
import cart from '../api/cart';

class DAddPatient extends Component {
    constructor() {
        super();
        this.state={
            patient: {},
            loading: false,
            pesel: null,
            type: 'text',
        }
    }

    componentDidMount(){
        var pesel = ''
        // console.log(this.props)
        if(this.props.location.state){
            if(this.props.location.state.patient){
            this.props.location.state.patient.externalIds.map((item)=>{
                if(item.system == "PESEL")
                pesel = item.value
            })
            this.setState({pesel: pesel, patient: this.props.location.state.patient})
        }
        }
    }

    save(){
        this.setState({loading: true})
        var patient = this.state.patient
        // console.log(patient.birthDate)
        patient.externalIds = [];
        patient.externalIds.push({system: "PESEL", value: this.state.pesel})
        cart.addPatient(patient)
        this.props.history.push("/library")

    }
    onFocus(){
        this.setState({type:'date'})
    }
    onBlur(){
        this.setState({type:'text'})
    }
    render() { 
        return ( 
               <div className="desktop_container">
                
                <Menu screenProps={this.props.history} />
                
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} />
                    <div className="d_main_content">
                    <div className="d_page_title_container_bordered">
                    
                        <div className="d_page_title">{multilanguage.add_new_patient}</div>
                        {this.state.loading ? null : 
                        <div className="dp_save_button" onClick={() => this.save()}>
                            <span>{multilanguage.add_to_order}</span>
                        </div>}
                    </div>
                    {this.state.loading ? null : 
                    <div className="d_add_patient_title">{multilanguage.personal_data}</div>}
                    {this.state.loading ? <DLoader /> : 
                <div className="d_settings_container">
               
                    <div className="d_settings_container_left">
                        <div className="d_product_detail_content_title">{multilanguage.mandatory2}</div>
                        <input className="d_add_pateint_input" type="text" placeholder={multilanguage.phone_number} onChange={(e) => this.setState({patient:{...this.state.patient, phoneNumber: e.target.value}})} defaultValue={this.state.patient.phoneNumber}/>
                        <input className="d_add_pateint_input" type="text" placeholder={multilanguage.pesel_number} onChange={(e) => this.setState({pesel: e.target.value})} defaultValue={this.state.pesel}/>

                        {/* <div className="d_add_patient_form_note">{multilanguage.note}</div>
                        <div className="d_add_patient_form_note_text">In euismod vehicula dictum. Aenean nec nunc non erat sagittis malesuada. Duis facilisis lectus non lorem sollicitudin sodales. Suspendisse vulputate lacinia congue. Nullam rhoncus eu metus vel dapibus. Maecenas quam orci, auctor et ultrices a, commodo et arcu. Vestibulum vulputate scelerisque neque, pharetra dignissim dolor facilisis ut.</div> */}
                        {/* <div className="d_add_patient_form_add_button">{multilanguage.save_patient}</div> */}
                        

                    </div>

                    <div className="d_settings_container_right">
                            <div className="d_product_detail_content_title">{multilanguage.non_mandatory2}</div>
                            <input className="d_add_pateint_input" type="text" placeholder={multilanguage.name} onChange={(e) => this.setState({patient:{...this.state.patient, firstName: e.target.value}})} defaultValue={this.state.patient.firstName}/>
                            <input className="d_add_pateint_input" type="text" placeholder={multilanguage.last_name} onChange={(e) => this.setState({patient:{...this.state.patient, lastName: e.target.value}})} defaultValue={this.state.patient.lastName}/>
                            <input className="d_add_pateint_input" type="text" placeholder={multilanguage.city} onChange={(e) => this.setState({patient:{...this.state.patient, address: e.target.value}})} defaultValue={this.state.patient.address} />
                            <input className="d_add_pateint_input" type={ this.state.type } onFocus={() => this.onFocus() } onBlur={() => this.onBlur() } placeholder={multilanguage.date_of_birth} onChange={(e) => this.setState({patient:{...this.state.patient, birthDate: e.target.value}})} defaultValue={this.state.patient.birthDate ? moment(this.state.patient.birthDate).format('YYYY-MM-DD'): null}/>
                            {/* <input className="d_add_pateint_input" type="text" placeholder={multilanguage.last_location} /> */}
                            <input className="d_add_pateint_input" type="text" placeholder={multilanguage.email_address} onChange={(e) => this.setState({patient:{...this.state.patient, email: e.target.value}})} defaultValue={this.state.patient.email}/>
                            

                    </div>


                </div>}
                {/* {this.state.loading ? null : 
                <div className="d_add_patient_bottom_container">
                    <div className="d_add_patient_title">{multilanguage.medical_information}</div>
                    <div className="d_add_patient_health_status">{multilanguage.health_status}</div>
                    <div className="patient_detail_tags_container">
                        <div className="patient_detail_tag_item">{multilanguage.smoker}</div>
                        <div className="patient_detail_tag_item">{multilanguage.diabetic}</div>
                        <div className="patient_detail_tag_item">{multilanguage.cardiovascular}</div>
                        <div className="patient_detail_tag_item" onClick={() => this.props.history.push('/health_status')}>
                            <img src={require('../assets/images/bluePencil.svg')} />
                        </div>
                    </div>
                    <div className="d_add_patient_med_info_flex">
                        <div className="dep_input_container">
                            <div className="dep_input_label">{multilanguage.known_diseases}</div>
                            <input className="dep_input" type="text" placeholder={multilanguage.add_notes} />
                        </div>
                        <div className="dep_input_container">
                            <div className="dep_input_label">{multilanguage.family_history}</div>
                            <input className="dep_input" type="text" placeholder={multilanguage.history_here} />
                        </div>
                        <div className="dep_input_container">
                            <div className="dep_input_label">{multilanguage.notes}</div>
                            <div className="d_add_pat_mi_notes">
                                <svg width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.3605 2.14067H18.4184C19.8419 2.14067 21 3.34309 21 4.82112V24.3195C21 25.7976 19.8419 27 18.4184 27H2.58155C1.15806 27 0 25.7976 0 24.3195V4.82112C0 3.34312 1.1581 2.14067 2.58155 2.14067H2.63948V0.775077C2.63948 0.347006 2.98955 0 3.42141 0C3.85326 0 4.20334 0.347006 4.20334 0.775077V2.14067H6.17873V0.775077C6.17873 0.347006 6.5288 0 6.96066 0C7.39252 0 7.74259 0.347006 7.74259 0.775077V2.14067H9.71802V0.775077C9.71802 0.347006 10.0681 0 10.4999 0C10.9318 0 11.2819 0.347006 11.2819 0.775077V2.14067H13.2573V0.775077C13.2573 0.347006 13.6074 0 14.0393 0C14.4711 0 14.8212 0.347006 14.8212 0.775077V2.14067H16.7967V0.775077C16.7967 0.347006 17.1467 0 17.5786 0C18.0104 0 18.3605 0.347006 18.3605 0.775077V2.14067ZM18.4184 25.4499C18.9796 25.4499 19.4361 24.9428 19.4361 24.3195H19.4362V4.82112C19.4362 4.19784 18.9796 3.69079 18.4184 3.69079H18.3606V5.05638C18.3606 5.48445 18.0105 5.83146 17.5786 5.83146C17.1468 5.83146 16.7967 5.48445 16.7967 5.05638V3.69079H14.8212V5.05638C14.8212 5.48445 14.4712 5.83146 14.0393 5.83146C13.6074 5.83146 13.2574 5.48445 13.2574 5.05638V3.69079H11.2819V5.05638C11.2819 5.48445 10.9318 5.83146 10.5 5.83146C10.0681 5.83146 9.71805 5.48445 9.71805 5.05638V3.69079H7.74262V5.05638C7.74262 5.48445 7.39255 5.83146 6.96069 5.83146C6.52884 5.83146 6.17876 5.48445 6.17876 5.05638V3.69079H4.20334V5.05638C4.20334 5.48445 3.85326 5.83146 3.42141 5.83146C2.98955 5.83146 2.63948 5.48445 2.63948 5.05638V3.69079H2.58155C2.02038 3.69079 1.56386 4.19784 1.56386 4.82112V24.3195C1.56386 24.9428 2.02041 25.4499 2.58155 25.4499H18.4184Z" fill="#00539F"/>
                                    <path d="M17.7209 10H3.27918C3.125 10 3 10.2239 3 10.5C3 10.7761 3.12496 11 3.27918 11H17.7209C17.875 11 18 10.7762 18 10.5C18 10.2238 17.8751 10 17.7209 10Z" fill="#00539F"/>
                                    <path d="M17.7209 15H3.27918C3.125 15 3 15.2239 3 15.5C3 15.7762 3.12496 16 3.27918 16H17.7209C17.875 16 18 15.7762 18 15.5C18 15.2239 17.8751 15 17.7209 15Z" fill="#00539F"/>
                                    <path d="M17.7209 20H3.27918C3.125 20 3 20.2238 3 20.5001C3 20.7762 3.12496 21 3.27918 21H17.7209C17.875 21 18 20.7762 18 20.5001C18 20.2238 17.8751 20 17.7209 20Z" fill="#00539F"/>
                                </svg>
                                <span>{multilanguage.add_notes}</span>
                            </div>
                        </div>
                    </div>
                </div>} */}


                    </div>
                </div>
            </div>
 )}
}
 
export default DAddPatient;