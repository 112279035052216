import React from 'react';
import Calendar from './Calendar';
import moment from 'moment';
import multilanguage from '../../multilanguage';

export default  class PatientFilters extends React.Component {

    constructor() {
        super();
        this.state={
            collectDay:null,
            collectDayEnd:null,
            collectDayString : null,
            resultDay: null,
            resultDayEnd: null,
            resultDayString: null,
            visibleCollect : false,
            visibleResult : false,
            barCode: '',
            visibleBarCode : false,
            ready : true,
            collectDaysObject: {},
            resultDaysObject: {},
        }
    }

    betweenDates(s, e){
        var obj = {};
        if(moment(s).format('YYYY-MM-DD') > moment(e).format('YYYY-MM-DD')){
            obj = {
                ...obj,
                [this.state.collectDayEnd]: {
                    startingDay: true, color: '#5566FB', textColor: '#fff',
                }
            };

            obj = {
                ...obj,
                [this.state.collectDay]: {
                    endingDay: true, color: '#5566FB', textColor: '#fff'
                }
            };
            var aux = e;
            e = s;
            s = aux;
        }
        else if(moment(s).format('YYYY-MM-DD') == moment(e).format('YYYY-MM-DD')){
            obj = {
                ...obj,
                [this.state.collectDayEnd]: {
                    startingDay: true,  endingDay: true, color: '#5566FB', textColor: '#fff',
                }
            };
        }
        else{
            obj = {
                ...obj,
                [this.state.collectDay]: {
                    startingDay: true, color: '#5566FB', textColor: '#fff',
                }
            };

            obj = {
                ...obj,
                [this.state.collectDayEnd]: {
                    endingDay: true, color: '#5566FB', textColor: '#fff'
                }
            };
        }

        let currentDate = new Date(s);
        currentDate.setDate(currentDate.getDate() + 1);
        let end = new Date(e);
        while (currentDate < end) {
          let dum = new Date(currentDate);
          let dummy = moment(dum).format('YYYY-MM-DD');
          obj = {
                ...obj,
                [dummy] : {
                   color: '#5566FB', textColor: '#fff',
                }
            };
          currentDate.setDate(currentDate.getDate() + 1);
        }
        this.setState({collectDaysObject : obj});
      }

      betweenDatesResult(s, e){
        var obj = {};
        if(moment(s).format('YYYY-MM-DD') > moment(e).format('YYYY-MM-DD')){
            obj = {
                ...obj,
                [this.state.resultDayEnd]: {
                    startingDay: true, color: '#5566FB', textColor: '#fff',
                }
            };

            obj = {
                ...obj,
                [this.state.resultDay]: {
                    endingDay: true, color: '#5566FB', textColor: '#fff'
                }
            };
            var aux = e;
            e = s;
            s = aux;
        }
        else if(moment(s).format('YYYY-MM-DD') == moment(e).format('YYYY-MM-DD')){
            obj = {
                ...obj,
                [this.state.resultDayEnd]: {
                    startingDay: true,  endingDay: true, color: '#5566FB', textColor: '#fff',
                }
            };
        }
        else{
            obj = {
                ...obj,
                [this.state.resultDay]: {
                    startingDay: true, color: '#5566FB', textColor: '#fff',
                }
            };

            obj = {
                ...obj,
                [this.state.resultDayEnd]: {
                    endingDay: true, color: '#5566FB', textColor: '#fff'
                }
            };
        }

        let currentDate = new Date(s);
        currentDate.setDate(currentDate.getDate() + 1);
        let end = new Date(e);
        while (currentDate < end) {
          let dum = new Date(currentDate);
          let dummy = moment(dum).format('YYYY-MM-DD');
          obj = {
                ...obj,
                [dummy] : {
                   color: '#5566FB', textColor: '#fff',
                }
            };
          currentDate.setDate(currentDate.getDate() + 1);
        }
        this.setState({resultDaysObject : obj});
      }


    onDayPressCollect(day){
        if(this.state.collectDay == day.dateString){
            this.state.collectDay = null;
            this.state.collectDayEnd = null;
            this.setState({collectDaysObject : {}});
            return;
        }
        if(this.state.collectDay){
            this.state.collectDayEnd = day.dateString;
        }
        else{
         this.state.collectDay = day.dateString;
        } 

        if(this.state.collectDayEnd)
           this.betweenDates(this.state.collectDay, this.state.collectDayEnd);
        else{
            var obj = {};
            obj = {
                ...obj,
                [this.state.collectDay]: {
                    startingDay: true,endingDay : true,  color: '#5566FB', textColor: '#fff',
                }
            };
            this.setState({collectDaysObject : obj});
        }
     }

     onDayPressResult(day){
        if(this.state.resultDay == day.dateString){
            this.state.resultDay = null;
            this.state.resultDayEnd = null;
            this.setState({resultDaysObject : {}});
            return;
        }
        if(this.state.resultDay){
            this.state.resultDayEnd = day.dateString;
        }
        else{
         this.state.resultDay = day.dateString;
        } 

        if(this.state.resultDayEnd)
           this.betweenDatesResult(this.state.resultDay, this.state.resultDayEnd);
        else{
            var obj = {};
            obj = {
                ...obj,
                [this.state.resultDay]: {
                    startingDay: true,endingDay : true,  color: '#5566FB', textColor: '#fff',
                }
            };
            this.setState({resultDaysObject : obj});
        }
     }


    setVisibleCollect(){
        var inv = !this.state.visibleCollect;
        if(inv){
            this.setState({visibleResult : false});
        }
        this.setState({visibleCollect : inv});
    }

    setVisibleResult(){
        var inv = !this.state.visibleResult;
        if(inv){
            this.setState({visibleCollect : false});
        }
        this.setState({visibleResult : inv});
    }

    setVisibleBarCode(){
        var inv = !this.state.visibleBarCode;
        this.setState({visibleBarCode : inv});
    }

    setReadyFilter(bool){
        this.setState({ready : bool});
    }


    render() {
        return (
           <div className="filters_container" style={this.props.visible ? {width:'100%'}: {height:0}}>
            <div className="filters_container_inner" style={this.props.visible ? {opacity:1}: {opacity:0}}>
               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.onClose()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.filter_simle}&nbsp;{multilanguage.patients_simple}</div>
                </div>
               </div>    

                <div className="filters_store_container">
                <div className="result_filters_category_item">
                    <img src={require('../../assets/images/blackFile.svg')} />
                    <div>
                        <div className="result_filter_label">{multilanguage.contract_type}</div>
                        <div className="result_filter_sublabel">{multilanguage.choose_type_of_contract}</div>
                    </div>
                </div>
                <div className="result_filters_category_item" onClick={() => this.setVisibleCollect()}>
                    <img src={require('../../assets/images/blackCalendar.svg')} />
                    <div>
                        <div className="result_filter_label">{multilanguage.choose_date}</div>
                        <div className="result_filter_sublabel">{multilanguage.select_the_date_of}</div>
                    </div>
                </div>
                {this.state.visibleCollect ?
                    <Calendar
                        className="calendarFiltrare"
                        onDayPress={(day) => this.onDayPressResult(day)}
                        onDayLongPress={(day) => this.onDayPressResult(day)}
                        monthFormat={'MMM, yyyy'}
                        hideArrows={false}
                        hideExtraDays={false}
                        firstDay={1}
                        markedDates={this.state.resultDaysObject}
                        markingType={'period'}
                        theme={{
                            textSectionTitleColor: '#1A1F38',
                            textDayHeaderFontFamily:'Roboto Bold',
                            monthTextColor: '#003148',
                            textMonthFontFamily:'Poppins SemiBold',
                            selectedDayBackgroundColor: '#333248',
                            arrowColor: '#D3D6DC',
                            }}
                    />
                    : null}
                    <div className="result_filters_category_item">
                        <img src={require('../../assets/images/otherFilter.svg')} />
                        <div>
                            <div className="result_filter_label">{multilanguage.account_filter}</div>
                        </div>
                    </div>


                    <div className="button_full_blue_result_filters" onClick={() => this.props.onClose()}>{multilanguage.patients_simple}</div>
                </div>  

            </div> 
           </div>
)}
}
