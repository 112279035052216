import React from 'react';
import multilanguage from '../../multilanguage';


export default  class Switch extends React.Component {

    constructor() {
        super();
        this.state={

        }
    }

    changeValue(){
        var inv = !this.props.value;
        this.props.onChangeValue(inv);
    }

    render() {
        const activeBackground = this.props.activeBackground ? this.props.activeBackground : '#00549E';
        if(this.props.smaller)
        return (
            <div onClick={() => this.changeValue()} className="switch_container_smaller" style={{width:this.props.width, height:this.props.height}}>
                 <div className="switch_thumb_smaller"
                     style={this.props.value ? {left:'18px',backgroundColor:activeBackground} : {left:0,backgroundColor:'#9D9D9D'}}
                 >
 
                 </div>
            </div>
        )
        return (
           <div onClick={() => this.changeValue()} className="switch_container" style={{width:this.props.width, height:this.props.height}}>
                <div className="switch_thumb"
                    style={this.props.value ? {left:'22px',backgroundColor:activeBackground} : {left:0,backgroundColor:'#9D9D9D'}}
                >

                </div>
           </div>
)}
}
