import React, { PureComponent } from 'react'
import multilanguage from '../../multilanguage';

const notifications = [
    {
        id : 1,
        type: 'family',
        content:'Darren Smith sent a new message',
        time: 'today',
    },
    {
        id: 2,
        type:'family',
        content: 'Olivia Wilson {multilanguage.commented_on_your_post}',
        time: 'yesterday',
    },
    {
        id:3,
        type:'appointment',
        location: 'Synevo Centrul "Mama si Copilul"',
        date: 'Saturday 13 July',
        time: '13:15',
    },
    {
        id: 4,
        type: 'cancel',
        content: '{multilanguage.new_result_ready} on Maya Cooper',
        time: 'Sun',
    }
];

export default class NotificationPopup extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
    }
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.notificationsRef = React.createRef();
  }

  componentDidUpdate(){
    if(this.props.active){
        document.addEventListener('click', this.handleOutsideClick, false);
        this.notificationsRef.current.style.display = 'block';
    }
  }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, false);
    }

    handleOutsideClick(e) {
        if (this.props.active) {
            if(!this.notificationsRef.current.contains(e.target)){
                document.removeEventListener('click', this.handleOutsideClick, false);
                this.notificationsRef.current.style.display = 'none';
                this.props.onClose();
            }
           
            
        }
    }

    renderNotifications = (items) => {
        return items.map((item,index) => {
            if(item.type == 'family')
            return(
                <div key={index} className="popnotificationitemcontainer">
                    <div className="popup_not_item_left">
                        <img className="popup_not_item_avatar" src={require('../../assets/images/personPlaceholder.jpg')} />
                        <div>{item.content}</div>
                    </div>
                    <div className="popup_not_item_date">{item.time}</div>
                </div>
            )
            if(item.type == 'appointment')
            return(
                <div key={index} className="popnotificationitemcontainer">
                    <div className="popup_not_item_left">
                        <div>{multilanguage.remember_the} <span>{multilanguage.appointment} </span>{multilanguage.on} <span>{item.date}</span>{multilanguage.at} <span>{item.location}</span></div>
                    </div>
                    <div className="popup_not_item_date">{item.time}</div>
                </div>
            )
            if(item.type == 'cancel')
            return(
                <div key={index} className="popnotificationitemcontainer">
                    <div className="popup_not_item_left">
                        <div>{item.content}</div>
                    </div>
                    <div className="popup_not_item_date">{item.time}</div>
                </div>
            )
        })
    }
  

  render() {
    return( 
        <div ref={this.notificationsRef} id="popupnotificationscontainer" style={this.props.active ? null : {display:"none"}}>
            <div className="popup_not_title">{multilanguage.you_have} <span>4</span>{multilanguage.notifications_short}</div>
            {this.renderNotifications(notifications)}
            <div className="poup_not_viewall_container" onClick={() => this.props.onViewAll()}>
                <span className="poup_not_viewall_button">{multilanguage.view_all}</span>
            </div>
        </div> 
)}
}
