import React from 'react';
import { FilePicker } from 'react-file-picker';
import multilanguage from '../multilanguage';
import api from '../api/api';
import auth from '../api/auth';
import moment from 'moment';
import DLoader from "./parts/DLoader";
import {NotificationManager} from 'react-notifications';
import cart from '../api/cart';

export default  class NewPatient extends React.Component {

    constructor() {
        super();
        this.state={
            patient: {},
            loading: false,
            pesel: null,
        }
    }

    componentDidMount(){
        var pesel = ''
        // console.log(this.props)
        if(this.props.location.state){
            if(this.props.location.state.patient){
            this.props.location.state.patient.externalIds.map((item)=>{
                if(item.system == "PESEL")
                pesel = item.value
            })
            this.setState({pesel: pesel, patient: this.props.location.state.patient})
        }
        }
    }

    save(){
        this.setState({loading: true})
        var patient = this.state.patient
        // console.log(patient.birthDate)
        patient.externalIds = [];
        patient.externalIds.push({system: "PESEL", value: this.state.pesel})
        cart.addPatient(patient)
        this.props.history.push("/library")

    }

    onFocus(){
        this.setState({type:'date'})
    }
    onBlur(){
        this.setState({type:'text'})
    }
    render() {
        return (
            <div className="app_container" style={{height:'100%'}}>
                <div className="more_menu_container_inner">
                    <div className="more_menu_header">
                        <div className="morE_menu_left_white_space"></div>
                        <div className="order_detail_header_title">{multilanguage.new_patient_simple}</div>
                        <div className="close_more_menu" onClick={() => this.props.history.goBack()}>
                            <img src={require('../assets/images/closeMenu.svg')} />
                        </div>
                    </div>

                </div>

                <div className="successContainer">
                    <FilePicker
                        extensions={[]}
                        // onChange={}
                        // onError={}
                    >
                    <div className="edit_profile_top_container">
                        <div className="edit_profile_avatar_container">
                            <img className="edit_profile_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                            <div className="home_intro_edit_button">
                                <img src={require('../assets/images/pencil.svg')} />
                            </div>
                        </div>
                        <span>{multilanguage.change_photo}</span>
                    </div>
                    </FilePicker>
                    {/* <img className="success_image" src={require('../assets/images/newpatient.png')} /> */}
                    <div className="newpatient_container">
                        <div className="newpatient_title">{multilanguage.mandatory_informations}</div>
                        {/* <div className="newpatient_subtitle">In order to withdraw the data from our system
                         we need to know <span>{multilanguage.phone_number}</span> of a patient.
                        If you want to save immediately data of a patient, you can fill them now. </div> */}
                        <div className="edit_profile_form_container">
                        <input type="text" placeholder={multilanguage.pesel_number} onChange={(e) => this.setState({pesel: e.target.value})} defaultValue={this.state.pesel} />      
                            <input type="text" placeholder={multilanguage.phone_number} onChange={(e) => this.setState({patient:{...this.state.patient, phoneNumber: e.target.value}})} defaultValue={this.state.patient.phoneNumber} /> 
                                    
                                  
                            <div className="newpatient_title">{multilanguage.non_mandatory_informations}</div>
                            <input type="text" placeholder={multilanguage.name} onChange={(e) => this.setState({patient:{...this.state.patient, firstName: e.target.value}})} defaultValue={this.state.patient.firstName}/>
                            <input type="text" placeholder={multilanguage.last_name} onChange={(e) => this.setState({patient:{...this.state.patient, lastName: e.target.value}})} defaultValue={this.state.patient.lastName}/>
                            <input type="text" placeholder={multilanguage.city} onChange={(e) => this.setState({patient:{...this.state.patient, address: e.target.value}})} defaultValue={this.state.patient.address} />
                            <input type="date" placeholder={multilanguage.date_of_birth} onChange={(e) => this.setState({patient:{...this.state.patient, birthDate: e.target.value}})} defaultValue={moment(this.state.patient.birthDate).format('YYYY-MM-DD')}/>
                            {/* <input type="text" placeholder={multilanguage.last_location} /> */}
                            <input type="text" placeholder={multilanguage.email_address} onChange={(e) => this.setState({patient:{...this.state.patient, email: e.target.value}})} defaultValue={this.state.patient.email}/>   
                            <div className="newpatient_infoform">{multilanguage.patient_data_data_will_load}</div>
                            <button onClick={() => this.save()}>{multilanguage.save_simple}</button>
                        </div>
                       
                    </div>
                
                </div>

            </div>
)}
}
