import React from 'react';
import NavBar from './parts/NavBar';
import Switch from './parts/Switch';
import multilanguage from '../multilanguage';
import DLoader from "./parts/DLoader";
import api from '../api/api';
import auth from '../api/auth';

export default  class Settings extends React.Component {

    constructor() {
        super();
        this.state={
            loader: true,
            allresults: true,
            onrange: true,
            entry: true,
            tests: true,           
            NOTIFICATION_ALL_RESULTS_SMS: null,
            NOTIFICATION_ALL_RESULTS_PUSH: null,
            NOTIFICATION_OUT_OF_RANGE: null,
            NOTIFICATION_NEW_MESSAGES: null,
            NOTIFICATION_NEW_PATIENT_SMS: null,
            NOTIFICATION_NEW_PATIENT_PUSH: null,
            NOTIFICATION_RESULTS_NEW_COMMENT: null,
            NOTIFICATION_NEW_TEST_PUSH: null,
            NOTIFICATION_NEW_TEST_DAILY: null,
            PROFILE_SHOW_PICTURE_IN_COMMENTS: null,
            PROFILE_SHOW_PERSONAL_DETAILS: null,
           reminders: true,
           sms: false,
           push: false,
        }
    }

    chooseAll(val){
        if(val){
            if(this.state.onrange)
            this.state.onrange = false;
        }
        this.setState({allresults: val});
    }
    chooseRange(val){
        if(val){
            if(this.state.allresults)
            this.state.allresults = false;
        }
        this.setState({onrange: val});
    }
    sendData(){
        let newSettings = []
        let settingKeys = [
            'NOTIFICATION_ALL_RESULTS_SMS',
            'NOTIFICATION_ALL_RESULTS_PUSH',
            'NOTIFICATION_OUT_OF_RANGE',
            'NOTIFICATION_NEW_MESSAGES',
            'NOTIFICATION_NEW_PATIENT_SMS',
            'NOTIFICATION_NEW_PATIENT_PUSH',
            'NOTIFICATION_RESULTS_NEW_COMMENT',
            'NOTIFICATION_NEW_TEST_PUSH',
            'NOTIFICATION_NEW_TEST_DAILY',
            'PROFILE_SHOW_PICTURE_IN_COMMENTS',
            'PROFILE_SHOW_PERSONAL_DETAILS',
        ]
        settingKeys.map((key)=>{
            newSettings.push({key: key, value:this.state[key]})
        })
        api.post('/Profile/setting', newSettings)
        .then(res=>{
            let settings = {};
            res.data.map((notification)=>{
                settings[notification.key] = notification.value
            })
            this.setState(settings)
        })
    }
    changeLanguage = (event) => {
        multilanguage.setLanguage(event.target.value);
        this.setState({});
      };
      componentDidMount(){
        api.get('/Profile/setting')
        .then(res => {
            this.setState({loading: false})
            let settings = {};
            res.data.map((notification)=>{
                settings[notification.key] = notification.value
            })
            this.setState(settings)
            })
}
    render() {
        const {value} = this.state;
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.settings}</div>
                </div>
               </div>


                <div className="messages_list_container">
                    <div className="seetings_list_title">{multilanguage.notifications}</div>
                    <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.about_all_results}</span>
                        </div>
                        <div className="messages_list_item_right">
                            
                        </div>
                    </div>

                    {/* {this.state.allresults ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.sms_alerts}</span>
                        </div>
                        <div className="messages_list_item_right">
                        {this.state.NOTIFICATION_ALL_RESULTS_SMS !== null ?
                            <Switch 
                                smaller={true}
                                activeBackground={'#FDB913'}
                                value={this.state.NOTIFICATION_ALL_RESULTS_SMS}
                                onChangeValue={(NOTIFICATION_ALL_RESULTS_SMS) => this.setState({NOTIFICATION_ALL_RESULTS_SMS})}
                            />
                            : null}
                        </div>
                    </div>
                    : null} */}

                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.sms_alerts}</span>
                        </div>
                        <div className="messages_list_item_right">
                        {this.state.NOTIFICATION_ALL_RESULTS_SMS !== null ?
                            <Switch 
                                smaller={true}
                                activeBackground={'#FDB913'}
                                value={this.state.NOTIFICATION_ALL_RESULTS_SMS}
                                onChangeValue={(e) => {this.state.NOTIFICATION_ALL_RESULTS_SMS = e; this.sendData()}}
                            />: null}
                        </div>
                    </div>
                    

                    <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.range_results}</span>
                        </div>
                        
                    </div>
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.sms_alerts}</span>
                        </div>
                        <div className="messages_list_item_right">
                            {this.state.NOTIFICATION_OUT_OF_RANGE !== null ?
                             <Switch 
                             smaller={true}
                             activeBackground={'#FDB913'}
                             value={this.state.NOTIFICATION_OUT_OF_RANGE}
                             onChangeValue={(e) => {this.state.NOTIFICATION_OUT_OF_RANGE = e; this.sendData()}}
                         />
                        :null}
                        </div>
                    </div>
                    {/* {this.state.allresults ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.sms_alerts}</span>
                        </div>
                        <div className="messages_list_item_right">
                        <Switch 
                                smaller={true}
                                activeBackground={'#FDB913'}
                                value={this.state.onrangesms}
                                onChangeValue={(onrangesms) => this.setState({onrangesms})}
                            />
                        </div>
                    </div>
                     : null} */}

                    {/* <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.new_messages}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                activeBackground={'#FDB913'}
                                value={this.state.messages}
                                onChangeValue={(messages) => this.setState({messages})}
                            />
                        </div>
                    </div> */}
                    {/* {this.state.messages ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.sms_alerts}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                smaller={true}
                                activeBackground={'#FDB913'}
                                value={this.state.messagessms}
                                onChangeValue={(messagessms) => this.setState({messagessms})}
                            />
                        </div>
                    </div>
                    : null}
                      {this.state.messages ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.push_notifications}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                smaller={true}
                                activeBackground={'#FDB913'}
                                value={this.state.messagespush}
                                onChangeValue={(messagespush) => this.setState({messagespush})}
                            />
                        </div>
                    </div>
                     : null}

                    <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.new_patient_entry}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                activeBackground={'#FDB913'}
                                value={this.state.entry}
                                onChangeValue={(entry) => this.setState({entry})}
                            />
                        </div>
                    </div>

                    {this.state.entrysms ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.sms_alerts}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                smaller={true}
                                activeBackground={'#FDB913'}
                                value={this.state.entrysms}
                                onChangeValue={(entrysms) => this.setState({entrysms})}
                            />
                        </div>
                    </div>
                    : null}
                    {this.state.entrysms ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.push_notifications}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                smaller={true}
                                activeBackground={'#FDB913'}
                                value={this.state.entrypush}
                                onChangeValue={(entrypush) => this.setState({entrypush})}
                            />
                        </div>
                    </div>
                    : null} */}

                    {/* <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.new_comments_results}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                activeBackground={'#FDB913'}
                                value={this.state.comments}
                                onChangeValue={(comments) => this.setState({comments})}
                            />
                        </div>
                    </div> */}
                    {/* {this.state.comments ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.sms_alerts}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                smaller={true}
                                activeBackground={'#FDB913'}
                                value={this.state.commentssms}
                                onChangeValue={(commentssms) => this.setState({commentssms})}
                            />
                        </div>
                    </div>
                    : null}
                    {this.state.comments ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.push_notifications}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                smaller={true}
                                activeBackground={'#FDB913'}
                                value={this.state.commentspush}
                                onChangeValue={(commentspush) => this.setState({commentspush})}
                            />
                        </div>
                    </div>
                    : null} */}

                    {/* <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.nitified_about_new}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                activeBackground={'#FDB913'}
                                value={this.state.tests}
                                onChangeValue={(tests) => this.setState({tests})}
                            />
                        </div>
                    </div> */}

                     {/* {this.state.tests ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.daily_reminders}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                smaller={true}
                                activeBackground={'#FDB913'}
                                value={this.state.testsdaily}
                                onChangeValue={(testsdaily) => this.setState({testsdaily})}
                            />
                        </div>
                    </div>
                    : null} */}
                    
                    {/* {this.state.tests ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.push_notifications}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                smaller={true}
                                activeBackground={'#FDB913'}
                                value={this.state.testspush}
                                onChangeValue={(testspush) => this.setState({testspush})}
                            />
                        </div>
                    </div>
                     : null} */}

                    {/* <div className="seetings_list_title">{multilanguage.profile_simple}</div>
                    <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.show_my_picture}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                activeBackground={'#FDB913'}
                                value={this.state.picture_comments}
                                onChangeValue={(picture_comments) => this.setState({picture_comments})}
                            />
                        </div>
                    </div>
                    <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.show_my_personal_details}</span>
                        </div>
                        <div className="messages_list_item_right">
                            <Switch 
                                activeBackground={'#FDB913'}
                                value={this.state.personal_detail}
                                onChangeValue={(personal_detail) => this.setState({personal_detail})}
                            />
                        </div>
                    </div> */}

                    <div className="seetings_list_title">{multilanguage.account}</div>
                    
                    <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.language}</span>
                        </div>
                        <div className="seting_list_language_right">
                            {/* <span>English</span>
                            <img src={require('../assets/images/dropdownArrow.svg')} /> */}
                            <select className="settings_language_select" onChange={this.changeLanguage} value={value}>
                                <option key={'polish_lang'} value='pl'>Polski</option>
                                <option test key={'en_lang'} value='en'>English</option>
                            </select>
                        </div>
                    </div>
                </div>


                <NavBar navigation={this.props.history} />

           </div>
)}
}
