import React from 'react';
import NavBar from './parts/NavBar';
import multilanguage from '../multilanguage';
import moment from 'moment';
import DLoader from "./parts/DLoader";
import _ from 'lodash'
import api from '../api/api';
import auth from '../api/auth';
import cart from '../api/cart';
let screenHeight = 0;

export default  class LatestResults extends React.Component {

    constructor() {
        super();
        this.state={
            loading: true,
            results: [],
            search: null,
           selected : 1,
           visibleNavBar: true,
        }
        this.handleResize = this.handleResize.bind(this);
    }

    changeSelected(id){
        this.setState({selected : id})
    }

    componentDidMount(){
        screenHeight = document.documentElement.clientHeight;
        window.addEventListener('resize',this.handleResize);
        api.get('/ServiceResult')
              .then(res => {
                this.setState({
                  loading: false,
                  results: res.data.results
                })
              })
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.handleResize);
    }

    handleResize(){
        if(screenHeight - document.documentElement.clientHeight > 150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: false})
        }
        if(screenHeight - document.documentElement.clientHeight < -150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: true})
        }
    }
    searchtimeout = null
    doSearch(search){
      this.setState({loading: true})
      api.get('/ServiceResult', {searchQuery: search})
              .then(res => {
                this.setState({
                  loading: false,
                  results: res.data.results
                })
              })
    }
    search(search){
      this.setState({results: []})
      if (this.searchtimeout) clearTimeout(this.searchtimeout)
      this.searchtimeout = setTimeout(() => this.doSearch(search), 1000);
    }
    render() {
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner space-flex-header" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.latest_results}</div>
                    <div className="header_cart_container"  onClick={() => this.props.history.push('/cart')}>
                        <img src={require('../assets/images/cartIcon.svg')} />
                        <div className="header_cart_container_bullet">{cart.itemCount}</div>
                    </div>
                </div>
               </div>

               {/* <div className="result_filter_tags_container">
                    <div onClick={() => this.changeSelected(1)} className={this.state.selected == 1 ? "result_filter_tag active" : "result_filter_tag"}>{multilanguage.name}</div>
                    <div onClick={() => this.changeSelected(2)}  className={this.state.selected == 2 ? "result_filter_tag active" : "result_filter_tag"}>{multilanguage.location_simple}</div>
                    <div onClick={() => this.changeSelected(3)}  className={this.state.selected == 3 ? "result_filter_tag active" : "result_filter_tag"}>{multilanguage.simple_pesel}</div>
                    <div onClick={() => this.changeSelected(4)}   className={this.state.selected == 4 ? "result_filter_tag active" : "result_filter_tag"}>{multilanguage.phone_simple}</div>
                    <div onClick={() => this.changeSelected(5)}  className={this.state.selected == 5 ? "result_filter_tag active" : "result_filter_tag"}>{multilanguage.email_simple}</div>
                </div> */}

               <div className="orders_search_container">
                    <div className="messages_search_input_container">
                        <img src={require('../assets/images/graysearch.svg')} />
                        <input type="text" placeholder={multilanguage.search_simple} 
                        defaultValue={this.state.search}
                        onChange={evt => this.search(evt.target.value)}
                        disabled={this.state.loading}
                        />
                    </div>
                </div>

                <div className="latest_results_container">
                    {/*<div className="patient_detail_info_title">Synevo Cabinet</div>*/}
                    
                        
                    {this.state.loading ? <DLoader /> : null}
                {this.state.results.map((rezultat) => {
                    var nameResult = rezultat.display
                    var pesel = ''
                    rezultat.patient.externalIds.map((external)=>{   
                        if(external.system == 'PESEL'){
                            pesel = external.value
                        }
                      })
                    
                  return(
                    <div className="result_box"  onClick={() => rezultat.status == "Ready" ? this.props.history.push('/result/'+rezultat.id) : null}>
                        <div className="patients_box_inner">
                            <div className="patients_box_top">
                                <div className="result_item_first_line">
                                    <img src={require('../assets/images/blackFile.svg')} />
                                    <span className="patients_box_value_name">{((nameResult).length > 70) ? (((nameResult).substring(0,70-3)) + '...') : nameResult}</span>
                                </div>
                            </div>
                            <div className="patients_box_top">
                                <div className="patients_box_line">
                                    <span className="patients_box_label">{multilanguage.name}</span>
                                    <span className="patients_box_value">{rezultat.patient.firstName} {rezultat.patient.lastName}</span>
                                </div>
                                <div className="patients_box_line end">
                                    <span className="patients_box_label">{multilanguage.pesel_number}</span>
                                    
                                    <span className="patients_box_value">{pesel}</span>
                                   
                                </div>
                            </div>
                            <div className="patients_box_top">
                            <div className="patients_box_line">
                                <span className="patients_box_label">{multilanguage.sampling_date} </span>
                                 <span className="patients_box_value">{moment(rezultat.issued).format('DD.MM.YYYY')}</span>
                            </div>
                            <div className="patients_box_line">
                                {rezultat.status == "Ready" ? 
                                <div className="result_ready_tag">
                                {multilanguage.result_is_ready}
                            </div>
                            :
                            <div className="result_pending_tag">
                                    {multilanguage.result_is_pending}
                                </div>
                            }
                                
                            </div>
                            </div>
                            
                        </div>
                    </div>
                  )
                })}
                </div>

                
                
                {this.state.visibleNavBar ?
                <NavBar navigation={this.props.history} />
                : null}

           </div>
)}
}
