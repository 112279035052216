import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import Switch from '../components/parts/Switch';
import api from '../api/api';
import auth from '../api/auth';
import multilanguage from '../multilanguage';
import DLoader from "./parts/DLoader";

class DSettings extends Component {
    constructor() {
        super();
        this.state={
            loader: true,
            allresults: true,
            onrange: true,
            entry: true,
            tests: true,           
            NOTIFICATION_ALL_RESULTS_SMS: null,
            NOTIFICATION_ALL_RESULTS_PUSH: null,
            NOTIFICATION_OUT_OF_RANGE: null,
            NOTIFICATION_NEW_MESSAGES: null,
            NOTIFICATION_NEW_PATIENT_SMS: null,
            NOTIFICATION_NEW_PATIENT_PUSH: null,
            NOTIFICATION_RESULTS_NEW_COMMENT: null,
            NOTIFICATION_NEW_TEST_PUSH: null,
            NOTIFICATION_NEW_TEST_DAILY: null,
            PROFILE_SHOW_PICTURE_IN_COMMENTS: null,
            PROFILE_SHOW_PERSONAL_DETAILS: null,
        }
    }


    componentDidMount(){
            api.get('/Profile/setting')
            .then(res => {
                this.setState({loading: false})
                let settings = {};
                res.data.map((notification)=>{
                    settings[notification.key] = notification.value
                })
                this.setState(settings)
                })
    }
    chooseAll(val){
        if(val){
            if(this.state.onrange)
            this.state.onrange = false;
        }
        this.setState({allresults: val});
    }
    chooseRange(val){
        if(val){
            if(this.state.allresults)
            this.state.allresults = false;
        }
        this.setState({onrange: val});
    }

changeLanguage(event){
    multilanguage.setLanguage(event.target.value)
    localStorage.setItem('lang', event.target.value)
    this.forceUpdate()
}
    sendData(){
        let newSettings = []
        let settingKeys = [
            'NOTIFICATION_ALL_RESULTS_SMS',
            'NOTIFICATION_ALL_RESULTS_PUSH',
            'NOTIFICATION_OUT_OF_RANGE',
            'NOTIFICATION_NEW_MESSAGES',
            'NOTIFICATION_NEW_PATIENT_SMS',
            'NOTIFICATION_NEW_PATIENT_PUSH',
            'NOTIFICATION_RESULTS_NEW_COMMENT',
            'NOTIFICATION_NEW_TEST_PUSH',
            'NOTIFICATION_NEW_TEST_DAILY',
            'PROFILE_SHOW_PICTURE_IN_COMMENTS',
            'PROFILE_SHOW_PERSONAL_DETAILS',
        ]
        settingKeys.map((key)=>{
            newSettings.push({key: key, value:this.state[key]})
        })
        api.post('/Profile/setting', newSettings)
            .then()
    }

    render() { 
        const {value} = this.state;
        
        return ( 
            
            <div className="desktop_container">
                
                <Menu screenProps={this.props.history} />
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} />
                    <div className="d_main_content">
                    <div className="d_page_title_container_bordered">
                        <div className="d_page_title">{multilanguage.settings}</div>
                        <div onClick={() => this.sendData()} className="dp_save_button">
                            <span>{multilanguage.save_settings}</span>
                        </div>
                    </div>
                
                <div className="d_settings_container">

                <div className="d_settings_container_left">
                    <div className="seetings_list_title">{multilanguage.notifications}</div>
                    <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.about_all_results}</span>
                        </div>
                    </div>
                    
                    {this.state.allresults ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.sms_alerts}</span>
                        </div>
                        <div className="messages_list_item_right">
                         
                            {this.state.NOTIFICATION_ALL_RESULTS_SMS !== null ?
                             <Switch 
                                
                             activeBackground={'#FDB913'}
                             value={this.state.NOTIFICATION_ALL_RESULTS_SMS}
                             onChangeValue={(NOTIFICATION_ALL_RESULTS_SMS) => this.setState({NOTIFICATION_ALL_RESULTS_SMS})}
                         />
                        :null}
                           
                        </div>
                    </div>
                    : null}
                    {/* {this.state.allresults ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.push_notifications}</span>
                        </div>
                        <div className="messages_list_item_right">
                        {this.state.NOTIFICATION_ALL_RESULTS_PUSH !== null ?
                             <Switch 
                                
                             activeBackground={'#FDB913'}
                             value={this.state.NOTIFICATION_ALL_RESULTS_PUSH}
                             onChangeValue={(NOTIFICATION_ALL_RESULTS_PUSH) => this.setState({NOTIFICATION_ALL_RESULTS_PUSH})}
                         />
                        :null}
                         
                        </div>
                    </div>
                     : null} */}

                    <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.range_results}</span>
                        </div>
                        {/* <div className="messages_list_item_right">
                        {this.state.NOTIFICATION_OUT_OF_RANGE !== null ?
                             <Switch 
                                
                             activeBackground={'#FDB913'}
                             value={this.state.NOTIFICATION_OUT_OF_RANGE}
                             onChangeValue={(NOTIFICATION_OUT_OF_RANGE) => this.setState({NOTIFICATION_OUT_OF_RANGE})}
                         />
                        :null}
                        </div> */}
                    </div>
                    {this.state.allresults ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.sms_alerts}</span>
                        </div>
                        <div className="messages_list_item_right">
                         
                        {this.state.NOTIFICATION_OUT_OF_RANGE !== null ?
                             <Switch 
                                
                             activeBackground={'#FDB913'}
                             value={this.state.NOTIFICATION_OUT_OF_RANGE}
                             onChangeValue={(NOTIFICATION_OUT_OF_RANGE) => this.setState({NOTIFICATION_OUT_OF_RANGE})}
                         />
                        :null}
                           
                        </div>
                    </div>
                    : null}
                    {/* <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.new_messages}</span>
                        </div>
                        <div className="messages_list_item_right">
                        {this.state.NOTIFICATION_NEW_MESSAGES !== null ?
                             <Switch 
                                
                             activeBackground={'#FDB913'}
                             value={this.state.NOTIFICATION_NEW_MESSAGES}
                             onChangeValue={(NOTIFICATION_NEW_MESSAGES) => this.setState({NOTIFICATION_NEW_MESSAGES})}
                         />
                        :null}
                        </div>
                    </div> */}
                    {/* <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.new_patient_entry}</span>
                        </div>
                    </div> */}

                    {/* {this.state.entry ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.sms_alerts}</span>
                        </div>
                        <div className="messages_list_item_right">
                        {this.state.NOTIFICATION_NEW_PATIENT_SMS !== null ?
                             <Switch 
                                
                             activeBackground={'#FDB913'}
                             value={this.state.NOTIFICATION_NEW_PATIENT_SMS}
                             onChangeValue={(NOTIFICATION_NEW_PATIENT_SMS) => this.setState({NOTIFICATION_NEW_PATIENT_SMS})}
                         />
                        :null}
                        </div>
                    </div>
                    : null}
                    {this.state.entry ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.push_notifications}</span>
                        </div>
                        <div className="messages_list_item_right">
                        {this.state.NOTIFICATION_NEW_PATIENT_PUSH !== null ?
                             <Switch 
                                
                             activeBackground={'#FDB913'}
                             value={this.state.NOTIFICATION_NEW_PATIENT_PUSH}
                             onChangeValue={(NOTIFICATION_NEW_PATIENT_PUSH) => this.setState({NOTIFICATION_NEW_PATIENT_PUSH})}
                         />
                        :null}
                        </div>
                    </div>
                    : null} */}

                    {/* <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.new_comments_results}</span>
                        </div>
                        <div className="messages_list_item_right">
                        {this.state.NOTIFICATION_RESULTS_NEW_COMMENT !== null ?
                             <Switch 
                                
                             activeBackground={'#FDB913'}
                             value={this.state.NOTIFICATION_RESULTS_NEW_COMMENT}
                             onChangeValue={(NOTIFICATION_RESULTS_NEW_COMMENT) => this.setState({NOTIFICATION_RESULTS_NEW_COMMENT})}
                         />
                        :null}
                        </div>
                    </div> */}
                    {/* <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.nitified_about_new}</span>
                        </div>
                    </div> */}

                     {/* {this.state.tests ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.daily_reminders}</span>
                        </div>
                        <div className="messages_list_item_right">
                        {this.state.NOTIFICATION_NEW_TEST_DAILY !== null ?
                             <Switch 
                                
                             activeBackground={'#FDB913'}
                             value={this.state.NOTIFICATION_NEW_TEST_DAILY}
                             onChangeValue={(NOTIFICATION_NEW_TEST_DAILY) => this.setState({NOTIFICATION_NEW_TEST_DAILY})}
                         />
                        :null}
                        </div>
                    </div>
                    : null} */}
                    
                    {/* {this.state.tests ?
                    <div className="settings_list_item">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.push_notifications}</span>
                        </div>
                        <div className="messages_list_item_right">
                        {this.state.NOTIFICATION_NEW_TEST_PUSH !== null ?
                             <Switch 
                                
                             activeBackground={'#FDB913'}
                             value={this.state.NOTIFICATION_NEW_TEST_PUSH}
                             onChangeValue={(NOTIFICATION_NEW_TEST_PUSH) => this.setState({NOTIFICATION_NEW_TEST_PUSH})}
                         />
                        :null}
                        </div>
                    </div>
                     : null} */}

                </div>
                <div className="d_settings_container_right">

                    {/* <div className="seetings_list_title">{multilanguage.profile_simple}</div>
                    <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.show_my_picture}</span>
                        </div>
                        <div className="messages_list_item_right">
                        {this.state.PROFILE_SHOW_PICTURE_IN_COMMENTS !== null ?
                             <Switch 
                                
                             activeBackground={'#FDB913'}
                             value={this.state.PROFILE_SHOW_PICTURE_IN_COMMENTS}
                             onChangeValue={(PROFILE_SHOW_PICTURE_IN_COMMENTS) => this.setState({PROFILE_SHOW_PICTURE_IN_COMMENTS})}
                         />
                        :null}
                        </div>
                    </div>
                    <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.show_my_personal_details}</span>
                        </div>
                        <div className="messages_list_item_right">
                        {this.state.PROFILE_SHOW_PERSONAL_DETAILS !== null ?
                             <Switch 
                                
                             activeBackground={'#FDB913'}
                             value={this.state.PROFILE_SHOW_PERSONAL_DETAILS}
                             onChangeValue={(PROFILE_SHOW_PERSONAL_DETAILS) => this.setState({PROFILE_SHOW_PERSONAL_DETAILS})}
                         />
                        :null}
                        </div>
                    </div> */}

                    <div className="seetings_list_title">{multilanguage.account}</div>
                    
                    <div className="settings_list_item settings_list_item_category">
                        <div className="messages_list_item_left">
                            <span className="settings_list_item_name">{multilanguage.language}</span>
                        </div>
                        <div className="seting_list_language_right">
                            {/* <span>English</span>
                            <img src={require('../assets/images/dropdownArrow.svg')} /> */}
                            <select className="settings_language_select" onChange={e => this.changeLanguage(e)} value={localStorage.getItem('lang')}>
                                <option key={'polish_lang'} value='pl'>Polish</option>
                                <option test key={'en_lang'} value='en'>English</option>
                            </select>
                        </div>
                    </div>
                    </div>
                    
                        </div>


                    </div>
                </div>
                
            </div>
            
 )}
}
 
export default DSettings;