import React, { Component } from 'react';
import multilanguage from '../../multilanguage';
import api from '../../api/api';
import auth from '../../api/auth';
class Menu extends Component {
    state = {  }


    render() { 
        return ( 
            <div className="desktop_menu">
            <div className="desktop_menu_inner">
                <svg width="142" height="35" viewBox="0 0 142 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M52.9227 17.5383C52.4738 17.1331 51.9351 16.7943 51.3114 16.5216C50.6857 16.2509 49.9796 16.0243 49.1894 15.8429C47.9185 15.528 46.9805 15.2295 46.3773 14.9463C45.7741 14.665 45.4749 14.2743 45.4749 13.777C45.4749 13.3709 45.6554 13.0263 46.0163 12.7431C46.3773 12.4618 47.0198 12.3207 47.9391 12.3207C48.8612 12.3207 49.6617 12.4512 50.3406 12.7114C51.0196 12.9696 51.5685 13.2586 51.9847 13.5744L53.9925 10.7616C53.2247 10.177 52.3625 9.7373 51.4105 9.44066C50.4557 9.1469 49.2998 9.00098 47.9391 9.00098C47.1722 9.00098 46.41 9.11522 45.6554 9.3389C44.8979 9.56546 44.218 9.88706 43.6148 10.3056C43.0116 10.7223 42.5244 11.2435 42.1512 11.8637C41.7781 12.4848 41.5929 13.1895 41.5929 13.9795C41.5929 14.7495 41.751 15.4099 42.0699 15.9629C42.3869 16.5178 42.7937 16.9911 43.2865 17.3866C43.7794 17.7792 44.3106 18.0931 44.881 18.3168C45.4515 18.5453 45.9995 18.7248 46.5251 18.8602C47.3808 19.0627 48.0606 19.2557 48.5656 19.4371C49.0678 19.6147 49.4644 19.7971 49.7496 19.9767C50.033 20.1581 50.2191 20.3443 50.3079 20.5373C50.3958 20.7293 50.4398 20.9367 50.4398 21.1623C50.4398 21.6826 50.1873 22.057 49.6841 22.2807C49.1791 22.5072 48.5105 22.6186 47.6772 22.6186C46.7346 22.6186 45.8948 22.4573 45.1606 22.129C44.4256 21.8035 43.8177 21.3888 43.3361 20.8915L41 23.5008C41.7014 24.313 42.6338 24.9303 43.7953 25.3469C44.9568 25.7655 46.2623 25.9728 47.709 25.9728C48.6087 25.9728 49.4578 25.8567 50.2583 25.6176C51.0589 25.3805 51.7593 25.0483 52.3625 24.6192C52.9657 24.192 53.4445 23.6659 53.7952 23.0439C54.144 22.4227 54.3208 21.7296 54.3208 20.9597C54.3208 20.1927 54.1992 19.5303 53.9589 18.9783C53.7176 18.4243 53.3716 17.9443 52.9227 17.5383" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M64.1217 20.516L59.7151 9.64014H55.2402L62.1802 25.3448L61.6874 26.6101C61.3143 27.5576 60.9589 28.2449 60.6175 28.6971C60.2771 29.1502 59.8442 29.3681 59.3186 29.3681C58.9249 29.3681 58.5966 29.2798 58.332 29.1109C58.0692 28.9419 57.8288 28.7643 57.6091 28.5848L55.8668 31.3957C56.3035 31.7787 56.8151 32.0763 57.3958 32.2923C57.9766 32.5064 58.7051 32.6139 59.5823 32.6139C60.5914 32.6139 61.4246 32.3893 62.0811 31.9371C62.7404 31.4849 63.2922 30.9051 63.742 30.1928C64.1918 29.4824 64.5696 28.7086 64.8773 27.8715C65.185 27.0363 65.4908 26.2731 65.7985 25.4821L72.2438 9.64014H68.0008L64.1217 20.516Z" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M82.7683 9.20361C82.1539 9.20361 81.6059 9.27081 81.1243 9.40809C80.6417 9.54249 80.2087 9.71721 79.8253 9.93321C79.4409 10.1463 79.1127 10.3796 78.8377 10.6283C78.5647 10.8759 78.3281 11.162 78.1317 11.3876L78.0335 9.64041H74.2432V25.4516H78.1569V16.826C78.1569 16.2404 78.2691 15.6923 78.4562 15.1835C78.6432 14.6756 78.9097 14.2292 79.2408 13.8443C79.569 13.4622 79.9674 13.1636 80.4275 12.9457C80.8895 12.7345 81.3842 12.627 81.9098 12.627C82.7655 12.627 83.4211 12.9035 83.9588 13.4574C84.4965 14.0094 84.7219 14.7937 84.7219 15.8113V25.4516H88.6357V15.7767C88.6357 13.7665 88.1568 12.1681 87.1497 10.9835C86.1415 9.79785 84.6976 9.20361 82.7683 9.20361" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M105.462 11.8824C104.869 11.0222 104.084 10.3282 103.109 9.79729C102.135 9.26641 100.944 9.00049 99.5404 9.00049C98.3789 9.00049 97.3212 9.21649 96.3673 9.64465C95.4125 10.0747 94.5914 10.6603 93.9003 11.4062C93.2102 12.1531 92.6724 13.0277 92.289 14.0328C91.9046 15.037 91.7139 16.1256 91.7139 17.3016C91.7139 18.587 91.9046 19.7621 92.289 20.8238C92.6724 21.8875 93.2205 22.7966 93.9331 23.5512C94.6457 24.3106 95.507 24.9029 96.5151 25.332C97.5251 25.7592 98.6417 25.9742 99.8706 25.9742C101.076 25.9742 102.112 25.8389 102.978 25.5662C103.845 25.2955 104.557 24.9931 105.115 24.6533C105.675 24.3125 106.103 23.9909 106.397 23.6885C106.696 23.3822 106.876 23.196 106.941 23.1288L104.836 20.6904C104.705 20.8238 104.512 20.9995 104.261 21.2146C104.009 21.4286 103.684 21.6341 103.291 21.8232C102.896 22.0162 102.42 22.187 101.86 22.333C101.301 22.4798 100.649 22.5509 99.9042 22.5509C99.3992 22.5509 98.8877 22.4626 98.3743 22.2821C97.8571 22.1006 97.3979 21.8424 96.993 21.5026C96.5871 21.1627 96.2589 20.7691 96.0064 20.293C95.7539 19.8197 95.6286 19.3598 95.6286 18.7118H107.196V18.1157C107.196 16.9858 107.036 15.8789 106.773 14.796C106.508 13.7112 106.053 12.7387 105.462 11.8824V11.8824ZM95.5958 15.8606C95.5958 15.3422 95.6819 15.0216 95.8577 14.6155C96.0326 14.2075 96.2851 13.8312 96.6133 13.5019C96.9444 13.1755 97.3549 12.8971 97.8468 12.683C98.3415 12.468 98.9036 12.3566 99.5404 12.3566C100.133 12.3566 100.659 12.4632 101.119 12.6792C101.581 12.8923 101.958 13.164 102.253 13.4894C102.552 13.8197 102.775 14.2075 102.93 14.6165C103.081 15.0226 103.16 15.3422 103.16 15.8606H95.5958Z" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M116.346 21.0257L112.202 9.64014H107.729L114.275 25.4513H118.219L124.733 9.64014H120.49L116.346 21.0257Z" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M141.392 14.2013C140.985 13.1741 140.421 12.2746 139.698 11.5075C138.975 10.7405 138.102 10.1309 137.082 9.67872C136.064 9.2256 134.939 9 133.712 9C132.484 9 131.359 9.2256 130.342 9.67872C129.322 10.1309 128.449 10.7405 127.727 11.5075C127.002 12.2746 126.438 13.1741 126.034 14.2013C125.628 15.2304 125.424 16.3181 125.424 17.4701C125.424 18.6221 125.628 19.7136 126.034 20.7389C126.438 21.768 127.002 22.6714 127.727 23.449C128.449 24.2294 129.322 24.8448 130.342 25.295C131.359 25.7472 132.484 25.9738 133.712 25.9738C134.939 25.9738 136.064 25.7472 137.082 25.295C138.102 24.8448 138.975 24.2294 139.698 23.449C140.421 22.6714 140.985 21.768 141.392 20.7389C141.797 19.7136 142 18.6221 142 17.4701C142 16.3181 141.797 15.2304 141.392 14.2013M137.823 19.2307C137.647 19.8211 137.379 20.3606 137.016 20.8598C136.655 21.3542 136.199 21.7584 135.651 22.0608C135.103 22.367 134.457 22.5197 133.712 22.5197C132.967 22.5197 132.32 22.367 131.772 22.0608C131.222 21.7584 130.768 21.3542 130.407 20.8598C130.044 20.3606 129.777 19.8211 129.601 19.2307C129.425 18.6451 129.338 18.0605 129.338 17.4701C129.338 16.9075 129.425 16.3238 129.601 15.7258C129.777 15.1267 130.044 14.5862 130.407 14.0995C130.768 13.6138 131.222 13.2202 131.772 12.9139C132.32 12.6086 132.967 12.457 133.712 12.457C134.457 12.457 135.103 12.6086 135.651 12.9139C136.199 13.2202 136.655 13.6138 137.016 14.0995C137.379 14.5862 137.647 15.1267 137.823 15.7258C137.997 16.3238 138.085 16.9075 138.085 17.4701C138.085 18.0605 137.997 18.6451 137.823 19.2307" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M2.53268 15.631C3.28091 16.0611 4.09503 16.2789 4.90256 16.3079L4.90539 16.3089L13.9426 16.3182L9.4588 8.55391L9.45409 8.55952C9.02774 7.87976 8.43103 7.29071 7.68374 6.86154C5.24609 5.45903 2.11668 6.28651 0.696444 8.70725C-0.724732 11.1289 0.0987973 14.2285 2.53268 15.631" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.7568 5.04161C10.7559 5.89995 10.9423 6.70873 11.3234 7.41934V7.42121L15.8618 15.2014L20.403 7.46235V7.46048C20.7823 6.75361 21.0015 5.94483 21.0015 5.08836C21.0053 2.29082 18.707 0.0122039 15.8872 4.88439e-05C13.0655 -0.0121063 10.7615 2.24594 10.7568 5.04161" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M24.0413 6.85533C23.2921 7.28357 22.6954 7.87449 22.2662 8.55518L22.2644 8.55705L17.7373 16.327L26.7472 16.3522L26.7453 16.3447C27.551 16.3185 28.3632 16.0998 29.1105 15.6715C31.5519 14.2765 32.3952 11.1695 30.9957 8.7375C29.5952 6.30368 26.4808 5.46124 24.0413 6.85533" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M29.1802 19.1251C28.4319 18.695 27.6178 18.4771 26.8103 18.4472L26.8178 18.4416L17.79 18.4238L22.2729 26.1872H22.2691C22.6945 26.9446 23.2875 27.4616 24.0338 27.8898C26.4715 29.2924 29.598 28.4667 31.0183 26.0469C32.4404 23.6253 31.614 20.5267 29.1802 19.1251" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M20.9276 29.7145C20.9285 28.8561 20.7111 28.0473 20.3327 27.3377L20.3421 27.3405L15.8443 19.5649L11.32 27.3021L11.3172 27.2993C10.9483 27.9996 10.7148 28.8122 10.7148 29.6686C10.7111 32.4671 12.9934 34.7448 15.8132 34.757C18.6348 34.7691 20.9228 32.5101 20.9276 29.7145" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.67122 27.9006C8.42039 27.4724 9.0171 26.8805 9.44627 26.2008L9.44816 26.2101L13.978 18.4524L4.97004 18.4281L4.97192 18.4243C4.17757 18.457 3.35216 18.6627 2.60486 19.091C0.163451 20.4869 -0.680784 23.5902 0.718746 26.0222C2.11922 28.4551 5.23169 29.2938 7.67122 27.9006" fill="white"/>
                </svg>

                <ul className="desktop_menu_list">
                    <li className={this.props.screenProps.location.pathname == '/home' ?
                    "desktop_menu_list_item_active" : "desktop_menu_list_item"}
                    onClick={() => this.props.screenProps.push('/home')}>
                        <svg width="19" height="21" viewBox="0 0 19 21" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M17.4095 5.71519L17.4525 5.75949C18.4412 6.73418 18.957 8.01899 19 9.43671V15.9051C19 18.7405 16.7647 21 14.0566 21H4.94344C2.19231 21 0 18.6962 0 15.9051V9.43671C0 8.06329 0.515837 6.73418 1.46154 5.75949L1.50452 5.71519L7.30769 0.886076C7.9095 0.310127 8.64027 0 9.45701 0C10.2738 0 11.0475 0.310127 11.6063 0.886076L17.4095 5.71519ZM14.0136 19.6709C16.0339 19.6709 17.6674 17.9873 17.6674 15.9051H17.5814V9.43671C17.5814 8.41772 17.1946 7.44304 16.5068 6.73418L10.7036 1.94937L10.6606 1.90506C10.3167 1.55063 9.88688 1.37342 9.41403 1.37342C8.94118 1.37342 8.51131 1.55063 8.16742 1.90506L8.12443 1.94937L2.32127 6.73418C1.63348 7.44304 1.24661 8.41772 1.24661 9.43671V15.9051C1.24661 17.9873 2.88009 19.6709 4.90045 19.6709H14.0136Z" fill="white"/>
                            <path d="M12.3182 15H6.68182C6.31818 15 6 15.4667 6 16C6 16.5333 6.31818 17 6.68182 17H12.3182C12.6818 17 13 16.5333 13 16C13 15.4667 12.7273 15 12.3182 15Z" fill="white"/>
                        </svg>
                        <span>{multilanguage.dashboard}</span>
                    </li>
                    <li className={
                    this.props.screenProps.location.pathname == '/library' ||
                    this.props.screenProps.location.pathname == '/packages' 
                    ?
                    "desktop_menu_list_item_active" : "desktop_menu_list_item"}
                    onClick={() => this.props.screenProps.push('/library')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="44" viewBox="0 0 57 44" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.52072 0H12.8082C13.6318 0 14.4294 0.291025 15.0621 0.822441L18.1119 3.3839C18.2385 3.49018 18.398 3.54839 18.5627 3.54839H47.1777C49.1221 3.54839 50.6984 5.13705 50.6984 7.09677V13.4839C50.6984 13.8758 51.0137 14.1935 51.4025 14.1935H54.2191C55.755 14.1935 57 15.4484 57 16.9963C57 17.2149 56.9746 17.4328 56.9244 17.6454L51.3425 41.2735C50.9649 42.8719 49.5478 44 47.9175 44H3.52072C1.57628 44 0 42.4113 0 40.4516V3.54839C0 1.58867 1.57628 0 3.52072 0ZM53.076 18.616C53.0887 18.5622 53.0951 18.507 53.0951 18.4516C53.0951 18.0597 52.7798 17.7419 52.3909 17.742L12.4489 17.7428C11.8127 17.7428 11.2554 18.1728 11.0894 18.7919L5.52105 39.5568C5.50486 39.6171 5.49666 39.6794 5.49666 39.7419C5.49666 40.1339 5.81191 40.4516 6.2008 40.4516H47.3614C47.6874 40.4516 47.9708 40.226 48.0464 39.9063L53.076 18.616ZM4.22487 3.54842V3.54839H12.5533C12.718 3.54839 12.8775 3.60659 13.0041 3.71288L16.0538 6.27433C16.6866 6.80575 17.4841 7.09677 18.3078 7.09677H46.4735C46.8624 7.09677 47.1777 7.41451 47.1777 7.80645V13.4839C47.1777 13.8758 46.8624 14.1935 46.4735 14.1935H11.4973C9.90664 14.1935 8.51355 15.2685 8.09853 16.8161L4.07305 31.8274C4.03247 31.9788 3.87783 32.0683 3.72767 32.0274C3.60482 31.9939 3.51949 31.8816 3.51949 31.7533L3.52069 4.25806H3.52072C3.52074 3.86613 3.83599 3.54842 4.22487 3.54842Z" fill="white"/>
                        </svg>
                        <span>{multilanguage.medical_library}</span>
                        <svg className="dmi_arrow" width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5 9.5L8.5 1.5L1.5 9.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </li>
                    {this.props.screenProps.location.pathname == '/library'  ||
                    this.props.screenProps.location.pathname == '/packages' ||
                    this.props.screenProps.location.pathname == '/my_packages'
                    ?
                    <li className= "desktop_menu_sublist">
                       <div onClick={() => this.props.screenProps.push('/library')} className={this.props.screenProps.location.pathname == '/library' ? "deskop_menu_list_item_sublist_active" : "deskop_menu_list_item_sublist"}>{multilanguage.single_tests}</div>
                    <div onClick={() => this.props.screenProps.push('/packages')} className={this.props.screenProps.location.pathname == '/packages' ? "deskop_menu_list_item_sublist_active" : "deskop_menu_list_item_sublist"}>{multilanguage.packages}</div>
                       {/* <div className="deskop_menu_list_item_sublist">{multilanguage.medical_diseases}</div> */}
                       {auth.auth.user.role == 'practitioner' ? 
                       <div onClick={() => this.props.screenProps.push('/my_packages')} className={this.props.screenProps.location.pathname == '/my_packages' ? "deskop_menu_list_item_sublist_active" : "deskop_menu_list_item_sublist"}>{multilanguage.my_packages}</div>: null}
                    </li>
                    : null}
                    {/* <li className={this.props.screenProps.location.pathname == '/messages' ?
                    "desktop_menu_list_item_active" : "desktop_menu_list_item"}
                    onClick={() => this.props.screenProps.push('/messages')}>
                        <div className="desktop_menu_bullet"></div>
                         <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.6593 1.30943C19.8041 1.94909 20.5058 3.15317 20.7643 4.80876C21.1231 7.0388 20.9939 9.26884 20.8846 11.1538C20.8814 11.2089 20.8782 11.2637 20.8751 11.3183C20.7643 12.7105 20.5797 14.178 19.4718 15.1563C18.5116 16.0217 17.1082 16.3603 15.7418 16.5485C13.7107 16.8495 12.0488 17 10.4608 17H10.35C8.57738 17 6.84167 16.8119 5.06903 16.5861C3.88726 16.4732 2.18848 16.0969 1.15444 14.479C0.378907 13.2749 0.194257 11.8451 0.0834668 10.7162C0.0465367 10.1518 0.00960661 9.5498 0.00960661 9.06065C-0.0273235 7.70606 0.0465367 6.42674 0.157327 5.33555C0.231187 4.31961 0.415837 3.11554 1.15444 2.17486C1.5976 1.64807 2.22541 1.19655 3.0748 0.895529C3.77647 0.632138 4.51508 0.481629 5.06903 0.406375C8.09729 -0.0451527 10.9778 -0.120407 13.9692 0.180611L14.2646 0.218238L14.3147 0.223338C15.7768 0.372228 17.3083 0.52818 18.6593 1.30943ZM18.6962 14.4037C19.4718 13.6888 19.6195 12.56 19.7303 11.3183C19.878 9.39929 20.0257 7.14166 19.6564 5.07216C19.4348 3.7552 18.9178 2.85215 18.1053 2.40062C17.0418 1.77143 15.7233 1.62933 14.446 1.49165C14.3483 1.48113 14.2509 1.47063 14.1538 1.45994L13.8584 1.42231C10.9778 1.12129 8.13422 1.19655 5.21675 1.64807C4.69973 1.72333 4.03498 1.83621 3.40717 2.06197C2.77936 2.28774 2.29927 2.58876 1.9669 3.00266C1.44988 3.67995 1.30216 4.62063 1.2283 5.56131C1.11751 6.61487 1.04365 7.85657 1.08058 9.1359C1.08058 9.58743 1.11751 10.1895 1.15444 10.7162C1.2283 11.6946 1.41295 12.9739 2.04076 13.9522C2.63164 14.8552 3.59182 15.382 5.17982 15.5325C6.91553 15.7583 8.61431 15.9464 10.3131 15.9464C11.8642 15.9464 13.4891 15.8336 15.5202 15.5325C16.6281 15.382 17.8838 15.1186 18.6962 14.4037Z" fill="white"/>
                            <path d="M16.1523 3.12297L10.7348 7.77082C10.5932 7.88605 10.4161 7.88605 10.2745 7.77082L4.85694 3.12297C4.60908 2.93091 4.2904 2.96932 4.11336 3.1998C3.93631 3.46868 3.97172 3.81439 4.18417 4.00645L9.60171 8.65429C9.88498 8.88476 10.1682 9 10.4869 9C10.8056 9 11.0889 8.88476 11.3721 8.65429L16.7897 4.00645C17.0375 3.81439 17.073 3.43027 16.8605 3.1998C16.7189 2.96932 16.4002 2.93091 16.1523 3.12297Z" fill="white"/>
                        </svg>
                        <span>{multilanguage.messages}</span>
                    </li> */}
                    {/* <li className={this.props.screenProps.location.pathname == '/notifications' ?
                    "desktop_menu_list_item_active" : "desktop_menu_list_item"} 
                    onClick={() => this.props.screenProps.push('/notifications')}>
                        <div className="desktop_menu_bullet"></div>
                        <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.37427 5.0071C6.17544 4.91751 4.07018 5.67902 2.47953 7.11244C0.888889 8.59067 0 10.5168 0 12.6222V18.5351C0 19.7893 1.07602 20.8196 2.38596 20.8196H4.81871V20.954C4.81871 22.6114 6.22222 24 8 24C9.73099 24 11.1813 22.6562 11.1813 20.954V20.8196H13.614C14.924 20.8196 16 19.7893 16 18.5351V12.8909C16 8.68025 12.6784 5.18628 8.37427 5.0071ZM9.77778 20.9988C9.77778 21.9394 8.98246 22.701 8 22.701C7.01754 22.701 6.22222 21.9394 6.22222 20.9988V20.8644H9.77778V20.9988ZM13.614 19.5205C14.1754 19.5205 14.5965 19.1174 14.5965 18.5799H14.5497V12.8909C14.5497 9.44176 11.7427 6.48532 8.2807 6.35094H8C6.31579 6.35094 4.67836 6.97806 3.46199 8.09793C2.15205 9.30738 1.45029 10.92 1.45029 12.667V18.5799C1.45029 19.1174 1.87135 19.5205 2.43275 19.5205H13.614Z" fill="white"/>
                            <path d="M8.5 4C8.76667 4 9 3.68889 9 3.33333V0.666667C9 0.311111 8.76667 0 8.5 0C8.23333 0 8 0.311111 8 0.666667V3.33333C8 3.73333 8.2 4 8.5 4Z" fill="white"/>
                        </svg>
                        <span>{multilanguage.notifications}</span>
                    </li> */}
                    {auth.auth.user.role == 'practitioner' ?
                    <li className={this.props.screenProps.location.pathname == '/latest_results' ?
                    "desktop_menu_list_item_active" : "desktop_menu_list_item"} 
                    onClick={() => this.props.screenProps.push('/latest_results')}>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M14.2061 0H3.79391C1.68618 0 0 1.71779 0 3.86503V17.135C0 19.2822 1.68618 21 3.79391 21H10.3279C11.8454 21 13.3208 20.3129 14.3326 19.1534L16.6511 16.4908C17.4941 15.5031 18 14.2147 18 12.8834V3.86503C18 1.71779 16.2717 0 14.2061 0ZM3.79391 19.7117C2.40281 19.7117 1.26464 18.5521 1.26464 17.135V3.86503C1.26464 2.44785 2.40281 1.28834 3.79391 1.28834H14.2061C15.5972 1.28834 16.7354 2.44785 16.7354 3.86503V12.9264C16.7354 13.1411 16.7354 13.3129 16.6932 13.5276C16.6757 13.5276 16.6511 13.5202 16.6221 13.5116C16.5812 13.4994 16.5318 13.4847 16.4824 13.4847H12.8993C11.8876 13.4847 11.0867 14.3006 11.0445 15.3313L10.9602 19.6687C10.7494 19.7117 10.5386 19.7117 10.37 19.7117H3.79391ZM12.267 19.2393C12.6885 19.0245 13.0679 18.7239 13.4052 18.3374L15.7237 15.6319C15.9766 15.3742 16.1452 15.0736 16.3138 14.773L16.3138 14.773H12.9415C12.6042 14.773 12.3513 15.0736 12.3513 15.3742L12.267 19.2393Z" fill="white"/>
                            <path d="M5.625 7H13.375C13.7083 7 14 6.76667 14 6.5C14 6.23333 13.7083 6 13.375 6H5.625C5.29167 6 5 6.23333 5 6.5C5 6.76667 5.29167 7 5.625 7Z" fill="white"/>
                            <path d="M5.625 10H13.375C13.7083 10 14 9.76667 14 9.5C14 9.23333 13.7083 9 13.375 9H5.625C5.29167 9 5 9.23333 5 9.5C5 9.76667 5.29167 10 5.625 10Z" fill="white"/>
                            <path d="M9.31193 12H5.68807C5.3211 12 5 12.2333 5 12.5C5 12.7667 5.3211 13 5.68807 13H9.31193C9.6789 13 10 12.7667 10 12.5C10 12.2333 9.6789 12 9.31193 12Z" fill="white"/>
                        </svg>
                        <span>{multilanguage.results}</span>
                    </li> : null}
                    {auth.auth.user.role == 'practitioner' ?
                    <li className={this.props.screenProps.location.pathname == '/patients' ?
                    "desktop_menu_list_item_active" : "desktop_menu_list_item"} 
                    onClick={() => this.props.screenProps.push('/patients')}>
                        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M3 7C3 9.20558 4.79439 11 6.99994 11C9.20561 11 11 9.20558 11 7C11 4.79442 9.20561 3 6.99994 3C4.79439 3 3 4.79442 3 7ZM4.06419 7C4.06419 5.38122 5.38118 4.06421 6.99994 4.06421C8.61882 4.06421 9.93581 5.38122 9.93581 7C9.93581 8.61878 8.61882 9.93579 6.99994 9.93579C5.38118 9.93579 4.06419 8.61878 4.06419 7Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 3.99994C12 6.20561 13.7945 8 16 8C18.2055 8 20 6.20561 20 3.99994C20 1.79439 18.2055 0 16 0C13.7945 0 12 1.79439 12 3.99994ZM13.0642 3.99994C13.0642 2.38118 14.3811 1.06419 16 1.06419C17.6189 1.06419 18.9358 2.38118 18.9358 3.99994C18.9358 5.61882 17.6189 6.93581 16 6.93581C14.3811 6.93581 13.0642 5.61882 13.0642 3.99994Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.1238 21C11.7884 21 12.4045 20.6413 12.8144 20.0918C13.2157 19.5538 13.3561 18.8468 13.1998 18.1908C13.198 18.1833 13.1959 18.1707 13.1937 18.158L13.1937 18.158C13.1915 18.1447 13.1892 18.1314 13.1873 18.1236H19.8624C20.4588 18.1236 21.0381 17.8054 21.4521 17.2505C21.9217 16.621 22.108 15.7875 21.9383 15.0748C21.0869 11.498 18.3859 9 15.3696 9C12.9002 9 10.7634 10.502 9.56066 13.0487C8.66319 12.5362 7.668 12.2467 6.63111 12.2467C3.59362 12.2467 0.892469 14.6821 0.0623875 18.1691C-0.0939004 18.8253 0.0466611 19.5538 0.447987 20.0918C0.857847 20.6413 1.47398 21 2.13838 21H11.1238ZM10.4129 13.6042C11.4373 11.3675 13.2657 10.046 15.3696 10.046C17.9263 10.046 20.2291 12.2178 20.9694 15.3276C21.0647 15.7288 20.9455 16.2308 20.6653 16.6065C20.4417 16.9058 20.1492 17.0776 19.8624 17.0776H12.8623C12.4298 15.9253 11.7826 14.9267 10.9883 14.1398C10.7984 13.9421 10.606 13.7584 10.4129 13.6042ZM6.63111 13.2926C4.05309 13.2926 1.75035 15.4019 1.03132 18.4219C0.95001 18.7638 1.02425 19.1653 1.23491 19.4478C1.45423 19.742 1.7835 19.954 2.13838 19.954H11.1238C11.4787 19.954 11.8081 19.742 12.0273 19.4478C12.238 19.1653 12.3123 18.7638 12.2309 18.4221C12.1556 18.1058 12.0592 17.8021 11.9506 17.5072C11.5924 16.5842 10.9975 15.643 10.3668 14.9618C9.33994 13.915 8.02716 13.2926 6.63111 13.2926Z" fill="white"/>
                        </svg>
                        <span>{multilanguage.my_patients}</span>
                    </li>: null}
                    
                    {auth.auth.user.role == 'practitioner' ?
                    <li className={this.props.screenProps.location.pathname == '/orders' ?
                    "desktop_menu_list_item_active" : "desktop_menu_list_item"}
                    onClick={() => this.props.screenProps.push('/orders')}>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M19.4503 6.71778C19.0024 6.14155 18.3102 5.77112 17.5365 5.77112H15.2155V5.31837C15.2155 3.87779 14.6047 2.47838 13.5867 1.44941C12.528 0.420426 11.1436 -0.0734834 9.67771 0.00883487C6.90882 0.214631 4.75071 2.60186 4.75071 5.52416V5.77112H2.42972C1.69678 5.77112 1.00455 6.10039 0.515922 6.71778C0.0272932 7.33516 -0.094864 8.07603 0.0680123 8.77573L1.81893 17.9131L1.85965 17.9954C2.47044 19.8064 4.13992 21 6.013 21H14.1161C15.9892 21 17.6587 19.7652 18.2694 17.9542L19.9389 8.77573C20.1018 8.07603 19.9389 7.294 19.4503 6.71778ZM5.97228 5.52416C5.97228 3.26041 7.64176 1.40825 9.71843 1.28477C10.8586 1.20245 11.9173 1.61404 12.7316 2.39607C13.546 3.17809 13.9939 4.20707 13.9939 5.35952V5.81227H5.97228V5.52416ZM17.0479 17.625L18.7174 8.52878C18.7988 8.15835 18.7174 7.78791 18.473 7.45864C18.2694 7.17053 17.903 7.00589 17.5365 7.00589H15.2155V7.91139C15.2155 8.24066 14.9305 8.52878 14.6047 8.52878C14.279 8.52878 13.9939 8.24066 13.9939 7.91139V7.00589H5.97228V7.91139C5.97228 8.24066 5.68724 8.52878 5.36149 8.52878C5.03574 8.52878 4.75071 8.24066 4.75071 7.91139V7.04705H2.389C2.02253 7.04705 1.69678 7.21169 1.45246 7.4998C1.24887 7.78791 1.16743 8.15835 1.24887 8.52878L2.99979 17.625C3.4477 18.9009 4.62855 19.7652 5.97228 19.7652H14.0754C15.4191 19.7652 16.6 18.9009 17.0479 17.625Z" fill="white"/>
                        </svg>
                        <span>{multilanguage.my_orders}</span>
                    </li>: null}
                    <li className={
                    this.props.screenProps.location.pathname == '/account' ||
                    this.props.screenProps.location.pathname == '/edit_profile' ||
                    this.props.screenProps.location.pathname == '/templates' ||
                    this.props.screenProps.location.pathname == '/my_location' ||
                    this.props.screenProps.location.pathname == '/settings' ||
                    this.props.screenProps.location.pathname == '/reset_password' 
                    ?
                    "desktop_menu_list_item_active" : "desktop_menu_list_item"}
                    onClick={() => this.props.screenProps.push('/account')}>
                        <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M16 5.97753C16 9.30337 13.3134 12 10 12C6.73134 12 4 9.34831 4 6.02247C4 2.69663 6.68657 0 10 0C13.3134 0 16 2.65169 16 5.97753ZM14.6567 6.02247C14.6567 3.41573 12.5522 1.34831 10 1.34831C7.44776 1.34831 5.38806 3.41573 5.38806 5.97753C5.38806 8.53933 7.40299 10.6966 10 10.6966C12.597 10.6966 14.6567 8.62921 14.6567 6.02247Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.29744 14H14.7018C17.0993 14 19.0445 15.935 18.9992 18.275V18.725C18.9992 21.065 17.0993 23 14.7018 23H4.29744C1.94516 23 0 21.11 0 18.725V18.275C0 15.935 1.89992 14 4.29744 14ZM14.747 21.65C16.3755 21.65 17.6874 20.345 17.6874 18.725V18.275C17.6874 16.655 16.3755 15.35 14.747 15.35H4.34268C2.71418 15.35 1.40232 16.655 1.40232 18.275V18.725C1.40232 20.345 2.71418 21.65 4.34268 21.65H14.747Z" fill="white"/>
                        </svg>
                        <span>{multilanguage.my_account}</span>
                        <svg className="dmi_arrow" width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.5 9.5L8.5 1.5L1.5 9.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </li>
                    {this.props.screenProps.location.pathname == '/account' ||
                    this.props.screenProps.location.pathname == '/edit_profile' ||
                    this.props.screenProps.location.pathname == '/templates'  ||
                    this.props.screenProps.location.pathname == '/my_location' ||
                    this.props.screenProps.location.pathname == '/settings'  ||
                    this.props.screenProps.location.pathname == '/reset_password'
                    ?
                    <li className= "desktop_menu_sublist">
                       <div onClick={() => this.props.screenProps.push('/settings')} className={this.props.screenProps.location.pathname == '/settings' ? "deskop_menu_list_item_sublist_active" : "deskop_menu_list_item_sublist"}>{multilanguage.settings}</div>
                       <div onClick={() => this.props.screenProps.push('/edit_profile')} className={this.props.screenProps.location.pathname == '/edit_profile' ? "deskop_menu_list_item_sublist_active" : "deskop_menu_list_item_sublist"}>{multilanguage.edit_profile}</div>
                       {/* <div onClick={() => this.props.screenProps.push('/templates')} className={this.props.screenProps.location.pathname == '/templates' ? "deskop_menu_list_item_sublist_active" : "deskop_menu_list_item_sublist"}>{multilanguage.order_template}</div>
                       <div onClick={() => this.props.screenProps.push('/my_location')} className={this.props.screenProps.location.pathname == '/my_location' ? "deskop_menu_list_item_sublist_active" : "deskop_menu_list_item_sublist"}>{multilanguage.my_locations}</div> */}
                       <div onClick={() => auth.resetPassword()} className={this.props.screenProps.location.pathname == '/reset_password' ? "deskop_menu_list_item_sublist_active" : "deskop_menu_list_item_sublist"}>{multilanguage.reset_password}</div>
                       {/* <div onClick={() => this.props.screenProps.push('/reset_password')} className={this.props.screenProps.location.pathname == '/reset_password' ? "deskop_menu_list_item_sublist_active" : "deskop_menu_list_item_sublist"}>{multilanguage.reset_password}</div> */}
                       <div className="deskop_menu_list_item_sublist"  onClick={() => auth.logout()}>{multilanguage.log_out}</div>
                    </li>
                    : null}
                </ul>
            </div>
            </div>
 )}
}
 
export default Menu;