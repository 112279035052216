import React from 'react';

import multilanguage from '../../multilanguage';
export default  class StoreFilters extends React.Component {

    constructor() {
        super();
        this.state={

        }
    }


    render() {
        return (
           <div className="filters_container" style={this.props.visible ? {width:'100%'}: {height:0}}>
            <div className="filters_container_inner" style={this.props.visible ? {opacity:1}: {opacity:0}}>
               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.onClose()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.choose_simple}&nbsp;{multilanguage.category_simple}</div>
                </div>
               </div>    

                <div className="filters_store_container">
                    <div className="store_filters_category_item" onClick={() => this.props.onClose()}>{multilanguage.hematology_simple}</div>
                    <div className="store_filters_category_item" onClick={() => this.props.onClose()}>{multilanguage.biochemistry_simple}</div>
                    <div className="store_filters_category_item" onClick={() => this.props.onClose()}>{multilanguage.tumor_markers}</div>
                    <div className="store_filters_category_item" onClick={() => this.props.onClose()}>{multilanguage.bone_markers}</div>
                    <div className="store_filters_category_item" onClick={() => this.props.onClose()}>{multilanguage.toxicology_simple}</div>
                    <div className="store_filters_category_item" onClick={() => this.props.onClose()}>{multilanguage.molecular_biology_tests}</div>
                </div>  

            </div> 
           </div>
)}
}
