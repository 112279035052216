import LocalizedStrings from 'react-localization';

const multilanguage = new LocalizedStrings({
  en: {
    notifications: 'Notifications',
    settings: 'Settings',
    save_settings: 'Save Settings',
    about_all_results: 'I want to get notified about all results',
    sms_alerts: 'E-mail Alerts',
    push_notifications: 'Push Notifications',
    range_results: 'Only out of range results',
    new_messages: 'New messages',
    new_patient_entry: 'New patient entry',
    new_comments_results: 'New comments on results',
    nitified_about_new: 'I want to get notified about new tests',
    daily_reminders: 'Daily reminders',
    show_my_picture: 'Show my picture in comments',
    show_my_personal_details: 'Show my personal details',
    account: 'Account',
    good_morning: 'Good Morning',
    here_are_the_latest_news: 'Here are the latest updates and news',
    dashboard: 'Dashboard',
    messages: 'Messages',
    results: 'Results',
    my_patients: 'My Patients',
    medical_library: 'Medical Library',
    medical_diseases: 'Medical diseases',
    my_packages: 'My packages',
    my_orders: 'My Orders',
    my_account: 'My Account',
    edit_profile: 'Edit profile',
    order_template: 'Order template',
    my_locations: 'My locations',
    my_location: 'My location',
    reset_password: 'Reset password',
    remember_the: 'Remember the',
    appointment: 'Appointment',
    on: 'on',
    at: 'at',
    male_simple: 'Male',
    you_have: 'You have',
    notifications_short: 'notifications',
    view_all: 'View all',
    currency: 'zł',
    my_order: 'My order',
    patient: 'Patient',
    total: 'Total',
    add_discount: 'Add discount',
    personal_comments: 'Personal comments',
    write_your_notes_and: 'N/D',
    save_to_my_packages: 'Save to My Packages',
    log_out: 'Log out',
    change_your_personal:
      'Here you can change your personal informations and your avatar',
    upload_your_personal:
      'You can upload your personal logo for the order page',
    adjust_your_personal_location:
      'Adjust your personal location and change informations',
    reset_password_text:
      'In case your forgot or lost your password, reset from here',
    add_new_patient: 'Add new patient',
    save_changes: 'Save changes',
    personal_data: 'Personal data',
    mandatory: 'Mandatory',
    mandatory2: 'Mandatory',
    note: 'Note',
    add_patient: 'Add patient',
    non_mandatory: 'Non mandatory',
    non_mandatory2: 'Non mandatory',
    name: 'Name',
    phone_number: 'Phone Number',
    city: 'City',
    date_of_birth: 'Date of Birth',
    last_location: 'Last location',
    email_address: 'Email Address',
    pesel_number: 'Pesel Number',
    medical_information: 'Medical information',
    health_status: 'Health status',
    smoker: 'Smoker',
    diabetic: 'Diabetic',
    cardiovascular: 'Cardiovascular',
    known_diseases: 'Known diseases',
    family_history: 'Family history',
    notes: 'Notes',
    add_notes: 'Add Notes',
    will_be_known_diseases: 'Here will be know diseases',
    history_here: 'Family history here.',
    contract_details: 'Contract details',
    location_simple: 'Location',
    phone_simple: 'PHONE',
    email_simple: 'EMAIL',
    upload_your_logo: 'Upload your logo',
    logo_must_be_in_png: 'Please note that the logo must be in png format',
    add_file: 'Add file',
    selected: 'Selected',
    preview: 'Preview',
    order_number: 'Order number',
    edit_patient: 'Edit patient',
    save_patient: 'Save patient',
    profile_settings: 'Profile Settings',
    last_name: 'Last Name',
    account_type: 'Account type',
    short_description: 'Short description',
    new_simple: 'NEW',
    last_results: 'Last Results',
    pending: 'Pending',
    sampling_date: 'SAMPLING DATE',
    ready_simple: 'Ready',
    active_patients: 'Active Patients',
    create_order: 'Create Order',
    single_tests: 'Single Tests',
    start_creating_order:
      'Start creating an order for patients using the most relevant collection of tests',
    packages: 'Packages',
    selecting_a_package_is_easy:
      'Selecting a package is easy. Try to fit in your patient needs using this section.',
    specific_diseases: 'Specific Diseases',
    simple_and_easy_create_an_order:
      'Simple and easy. Create an order by a specific disease and send it to the patient',
    medical_files: 'Medical files',
    patient_uploads: 'Patient uploads',
    medic_uploads: 'Medic uploads',
    new_file: 'New file',
    see_more: 'See more',
    show_all: 'Show all',
    package_added: 'Package added',
    name_of_the_facility: 'Name of the facility',
    post_code: 'Post code',
    street_number: 'Street, str. number',
    type_of_the_facility: 'Type of the facility',
    hospital: 'Hospital',
    private_cabinet: 'Private cabinet',
    clinic: 'Clinic',
    healthcare_facility: 'Healthcare facility',
    saved_locations: 'Saved Locations',
    edit_simple: 'Edit',
    new_note: 'New note',
    to_buy: 'To buy',
    first_meeting: 'First meeting',
    patient_observations: "Patient's observations",
    save_simple: 'Save',
    cancel_simple: 'Cancel',
    sort_by: 'Sort by',
    newest_simple: 'Newest',
    type_simple: 'Type',
    message_simple: 'Message',
    sent_a_message: 'sent a new message',
    comment_simple: 'Comment',
    commented_on_your_post: 'commented on your post',
    result_ready: 'Result ready',
    new_result_ready: 'New result ready',
    order_no: 'Order no',
    send_on_email: 'Send on email',
    date_simple: 'DATE',
    order_resume: 'ORDER RESUME',
    price_simple: 'PRICE',
    new_order: 'New order',
    details_simple: 'Details',
    add_to_cart_simple: 'Add to cart',
    hex_code: 'HEX CODE',
    department_simple: 'DEPARTMENT',
    material_simple: 'Material',
    type_of_sample: 'TYPE OF SAMPLE',
    time_to_result: 'TIME TO RESULT',
    time_to_results: 'TIME TO RESULTS',
    sample_volume: 'SAMPLE VOLUME',
    reference_values: 'REFERENCE VALUES',
    interferences_simple: 'INTERFERENCES',
    references_simple: 'REFERENCES',
    description_simple: 'DESCRIPTION',
    clinical_purpose: 'CLINICAL PURPOSE',
    old_password: 'Old password',
    new_password: 'New password',
    read_comment_below: 'Read comment below',
    your_result_is_negative: 'Your result is negative',
    read_more: 'Read more',
    result_is_pending: 'Result is pending',
    add_comment: 'Add comment',
    print_sample: 'Print',
    repeat_test: 'Repeat test',
    age_sample: 'Age',
    recoltation_date: 'Recoltation date',
    ssn_simple: 'SSN',
    barcode_sample: 'Barcode',
    order_id: 'Order ID',
    sex_simple: 'Sex',
    female_simple: 'Female',
    new_comment: 'New comment',
    status_simple: 'STATUS',
    about_simple: 'About',
    home_simple: 'Home',
    orders_simple: 'Orders',
    patients_simple: 'Patients',
    more_simple: 'More',
    filter_simle: 'Filter',
    last_simple: 'Last',
    contract_type: 'Contract type',
    choose_type_of_contract: 'Choose type of contract',
    choose_date: 'Choose date',
    select_the_date_of: 'Select the date of preorder',
    other_filter: 'Other filter',
    simple_apply: 'Apply',
    result_date: 'Result date',
    choose_simple: 'Choose',
    category_simple: 'category',
    hematology_simple: 'Hematology',
    biochemistry_simple: 'Biochemistry',
    tumor_markers: 'Tumor markers',
    bone_markers: 'Bone markers',
    toxicology_simple: 'Toxicology',
    molecular_biology_tests: 'Molecular biology tests',
    days_for_results: 'day(s) for results',
    contract_covered_tests: 'Contract covered tests',
    not_on_contract: 'Not on contract',
    send_order: 'Send order',
    total_simple: 'Total',
    create_orders_template: 'Create orders template',
    upload_logo: 'Upload logo',
    upload_photo_png_format: 'Please note that the logo must be in .png format',
    change_photo: 'Change photo',
    short_bio: 'Short bio',
    new_health_status: 'New health status',
    choose_tags: 'Choose tags',
    stressful_simple: 'Stressful',
    add_simple: 'Add',
    create_new_order: 'Create new order',
    create_new_order_patient: 'Create a new order for a patient',
    my_dashboard: 'My dashboard',
    new_patient_simple: 'New patient',
    news_simple: 'News',
    latest_results: 'Latest results',
    simple_pesel: 'PESEL',
    result_is_ready: 'Result is ready',
    welcome_simple: 'Welcome',
    login_simple: 'Login',
    create_account: 'Create account',
    data_simple: 'Data',
    address_simple: 'Address',
    select_the_type: 'Select the type',
    please_select_the_patient_or_add:
      'Please select the patient or add a new one',
    mandatory_informations: 'Mandatory informations',
    non_mandatory_informations: 'Non mandatory informations',
    patient_data_data_will_load:
      'The patient data will load automatically when patient establish an account in our system.',
    add_new_note: 'Add new note',
    save_note: 'Save note',
    delete_note: 'Delete note',
    preorder_no: 'Preorder no',
    personal_informations: 'Personal information',
    order_simple: 'Order',
    send_a_recomandation: 'Send a recomendation',
    chat_simple: 'Chat',
    send_a_message: 'Send a message.',
    see_contract_details: 'See contract details.',
    share_request: 'Share request',
    send_share_request: 'Send share request.',
    edit_healts_status: 'Edit health status.',
    add_personal_notes: 'Add personal notes.',
    add_to_order: 'Add to order',
    related_tests: 'Related tests',
    save_comment: 'Save comment',
    age_simple: 'Age',
    deficient_simple: 'Deficient',
    result_is_deficient: 'Result is deficient',
    recent_results: 'Recent results',
    profile_simple: 'Profile',
    go_to_cart: 'Go to cart',
    success_simple: 'Success',
    successfully_sent_order: 'You have succesfully sent order to',
    go_to_home: 'Go to home',
    services: 'Services',
    remove_simple: 'Remove',
    package_name: 'Package name',
    package_saved: 'Package saved',
    add_a_name: 'You must add a name',
    add_a_patient: 'You must add a patient',
    add_an_item: 'You must add an item',
    all_services: 'All services',
    download: 'Download',
    working_day: 'working day',
    working_days: 'working days',
    working_dayss: 'working days',
    mypackages_info:
      'Create your own package fitted to the needs of your patients. Add single tests from Medical Library and name the packge in basket view.',
    material_code: 'Material Code',
    patient_presentation: 'Patient presentation',
    links: 'Links',
    previous_page: 'Previous page',
    next_page: 'Next page',
    notification_pending: 'Results still in progress',
    notification_addToCart: 'Test successfully added to the order',
    notification_removeFromCart: 'Test successfully removed from the order',
    most_often_list:
      'After 3 orders you will see the list of most often ordered tests',
    most_often_title: 'Most often ordered',
    name_results_patients: 'Name',
    patient_added: 'Patient added to cart',
    order_created: 'Order created',
    patients_info:
      'Available information based on forms filled out by patients',
    go_back: 'Go back',
    notes_info:
      'You can add here notes regarding visit and results. These informations are visible only to you (patient does not have access to them).',
    edit_profil_info:
      'If you want to change your data, please contact your respective Synevo representative.',
    list: 'List',
    select_patient: 'Select Patient',
    education: 'Education',
    display_name: 'Display Name',
    add_a_number: "You must add a phone number",
  },
  pl: {
    notifications: 'Powiadomienia',
    settings: 'Powiadomienia',
    save_settings: 'Zapisz ustawienia powiadomień',
    about_all_results: 'Chcę otrzymywać powiadomienia o wszystkich wynikach',
    sms_alerts: 'Powiadomienia Mail',
    push_notifications: 'Powiadomienia push',
    range_results:
      'Informuj tylko o wynikach poza zakresem wartości referencyjnych',
    new_messages: 'Nowe wiadomości',
    new_patient_entry: 'Nowy pacjent',
    new_comments_results: 'Nowy komentarz dotyczący wyników',
    nitified_about_new: 'Chcę otrzymywać powiadomienia o nowych badaniach',
    daily_reminders: 'Codzienne przypomnienia',
    show_my_picture: 'Pokaż moje zdjęcie w komentarzach',
    show_my_personal_details: 'Widoczne dane personalne',
    account: 'Konto',
    good_morning: 'Dzień dobry',
    here_are_the_latest_news: 'Oto najnowsze aktualizacje i aktualności',
    dashboard: 'Panel',
    messages: 'Wiadomości',
    results: 'Wyniki',
    my_patients: 'Moi pacjenci',
    medical_library: 'Katalog badań',
    medical_diseases: 'Diagnostyka jednostek chorobowych',
    my_packages: 'Moje pakiety',
    my_orders: 'Wystawione zlecenia',
    my_account: 'Moje konto',
    edit_profile: 'Edytuj profil',
    order_template: 'Szablon zlecenia',
    my_locations: 'Moje miejsca',
    my_location: 'Moje miejsce',
    reset_password: 'Zresetuj hasło',
    remember_the: 'Zapamiętaj',
    appointment: 'Wizyta',
    on: 'na',
    at: 'w',
    male_simple: 'Mężczyzna',
    you_have: 'Masz',
    notifications_short: 'Powiadomienia',
    view_all: 'Pokaż wszystkie',
    currency: 'zł',
    my_order: 'Moje zlecenie',
    patient: 'Pacjent',
    total: 'Łączna kwota',
    add_discount: 'Dodaj zniżkę',
    personal_comments: 'Komentarz do zlecenia',
    write_your_notes_and: 'N/D',
    save_to_my_packages: 'Zapisz w Moich Pakietach',
    log_out: 'Wyloguj',
    change_your_personal: 'Tutaj możesz zmienić swoje dane ',
    upload_your_personal: 'Dodaj swoje logo do strony zlecenia',
    adjust_your_personal_location: 'Zmień swoją lokalizacje lub dane ',
    reset_password_text: 'W przypadku zapomnienia hasła, zresetuj tutaj',
    add_new_patient: 'Dodaj nowego pacjenta',
    save_changes: 'Zapisz zmiany',
    personal_data: 'Dane osobowe',
    mandatory: 'Obowiązkowy',
    mandatory2: 'Obowiązkowe',
    note: 'Uwagi',
    add_patient: 'Dodaj Pacjenta',
    non_mandatory: 'Nieobowiązkowy',
    non_mandatory2: 'Nieobowiązkowe',
    name: 'Imię',
    phone_number: 'Numer telefonu',
    city: 'Miasto',
    date_of_birth: 'Data urodzenia',
    last_location: 'Ostatnia lokalizacja',
    email_address: 'Adres e-mail',
    pesel_number: 'Numer PESEL',
    medical_information: 'Informacje medyczne',
    health_status: 'Stan zdrowia',
    smoker: 'Palacz',
    diabetic: 'Cukrzyk',
    cardiovascular: 'Układ sercowo-naczyniowy',
    known_diseases: 'Historia chorób',
    family_history: 'Wywiad rodzinny',
    notes: 'Uwagi',
    add_notes: 'Dodaj notatkę',
    will_be_known_diseases: 'Tutaj będą rozponane choroby',
    history_here: 'Historia rodziny',
    contract_details: 'Szczegółowe informacje o zleceniu',
    location_simple: 'Lokalizacja',
    phone_simple: 'Numer telefonu',
    email_simple: 'E-mail',
    upload_your_logo: 'Prześlij swoje logo',
    logo_must_be_in_png: 'Należy pamiętać, że logo musi być w formacie png',
    add_file: 'Dodaj plik',
    selected: 'Wybrany',
    preview: 'Przegląd',
    order_number: 'Numer zlecenia',
    edit_patient: 'Edycja pacjenta',
    save_patient: 'Zapisz pacjenta',
    profile_settings: 'Ustawienia profilu',
    last_name: 'Nazwisko',
    account_type: 'Rodzaj konta',
    short_description: 'Krótki opis',
    new_simple: 'Nowy',
    last_results: 'Ostatnie wyniki',
    pending: 'Oczekujące',
    sampling_date: 'Data pobrania próbki',
    ready_simple: 'Gotowy',
    active_patients: 'Aktywni pacjenci',
    create_order: 'Utwórz zlecenie',
    single_tests: 'Testy pojedyncze',
    start_creating_order:
      'Wybieranie badania jest proste. Dopasuj badanie do potrzeb pacjenta za pomocą tej sekcji.',
    packages: 'Profile',
    selecting_a_package_is_easy:
      'Rozpocznij tworzenie zlecenia dla pacjentów przy użyciu najlepiej dopasowanego zestawu badań',
    specific_diseases: 'Specyficzne choroby',
    simple_and_easy_create_an_order:
      'Prosto i łatwo. Utwórz zlecenie według ścieżki diagnostycznej danej choroby i prześlij je do pacjenta',
    medical_files: 'Dokumentacja medyczna',
    patient_uploads: 'Pliki pacjenta',
    medic_uploads: 'Pliki lekarza',
    new_file: 'Nowy plik',
    see_more: 'Zobacz więcej',
    show_all: 'Pokaż wszystkie',
    package_added: 'Pakiet dodany',
    name_of_the_facility: 'Nazwa obiektu',
    post_code: 'Kod pocztowy',
    street_number: 'Adres',
    type_of_the_facility: 'Rodzaj obiektu',
    hospital: 'Szpital',
    private_cabinet: 'Prywatny gabinet',
    clinic: 'Klinika',
    healthcare_facility: 'Zakład Opieki Zdrowotnej',
    saved_locations: 'Zapisane lokalizacje',
    edit_simple: 'Edycja',
    new_note: 'Nowa notatka',
    to_buy: 'Do kupienia',
    first_meeting: 'Pierwsza wizyta',
    patient_observations: 'Obserwacje pacjenta',
    save_simple: 'Zapisz',
    cancel_simple: 'Anuluj',
    sort_by: 'Sortuj według',
    newest_simple: 'Najnowszy',
    type_simple: 'Rodzaj',
    message_simple: 'Wiadomość',
    sent_a_message: 'wysłał nową wiadomość',
    comment_simple: 'Komentarz',
    commented_on_your_post: 'skomentował wpis',
    result_ready: 'Wynik gotowy',
    new_result_ready: 'Nowy wynik gotowy',
    order_no: 'Numer zlecenia',
    send_on_email: 'Wyślij na e-mail',
    date_simple: 'Data',
    order_resume: 'Zlecenie',
    price_simple: 'Cena',
    new_order: 'Nowe zamówienie',
    details_simple: 'Szczegóły',
    add_to_cart_simple: 'Dodaj do zlecenia',
    hex_code: 'Kod hex',
    department_simple: 'Wydział',
    material_simple: 'Materiał',
    type_of_sample: 'Rodzaj próbki',
    time_to_result: 'Czas oczekiwania na wynik',
    time_to_results: 'Czas oczekiwania na wyniki',
    sample_volume: 'Objętość próbki',
    reference_values: 'Wartości referencyjne',
    interferences_simple: 'Interferencje',
    references_simple: 'Materiały źródłowe',
    description_simple: 'Opis',
    clinical_purpose: 'Znaczenie kliniczne',
    old_password: 'Stare hasło',
    new_password: 'Nowe hasło',
    read_comment_below: 'Przeczytaj komentarz poniżej',
    your_result_is_negative: 'Twój wynik jest negatywny',
    read_more: 'Czytaj więcej',
    result_is_pending: 'Oczekujący',
    add_comment: 'Dodaj komentarz',
    print_sample: 'Drukuj',
    repeat_test: 'Zleć powtórnie',
    age_sample: 'Wiek',
    recoltation_date: 'Data wystawienia zlecenia',
    ssn_simple: 'SSN',
    barcode_sample: 'Kod kreskowy',
    order_id: 'Identyfikator zlecenia',
    sex_simple: 'Płeć',
    female_simple: 'Kobieta',
    new_comment: 'Nowy komentarz',
    status_simple: 'Status',
    about_simple: 'O',
    home_simple: 'Strona główna',
    orders_simple: 'Zlecenia',
    patients_simple: 'Pacjenci',
    more_simple: 'Więcej',
    filter_simle: 'Filtr',
    last_simple: 'Ostatnie',
    contract_type: 'Rodzaj kontraktu',
    choose_type_of_contract: 'Wybierz rodzaj umowy',
    choose_date: 'Wybierz datę',
    select_the_date_of: 'Wybierz datę zlecenia',
    other_filter: 'Inny filtr',
    simple_apply: 'Zastosuj',
    result_date: 'Data wyników',
    choose_simple: 'Wybierz',
    category_simple: 'kategoria',
    hematology_simple: 'Hematologia',
    biochemistry_simple: 'Biochemia',
    tumor_markers: 'Markery nowotworowe',
    bone_markers: 'Markery kostne',
    toxicology_simple: 'Toksykologia',
    molecular_biology_tests: 'Badania biologii molekularnej',
    days_for_results: 'Oczekiwanie na wyniki',
    contract_covered_tests: 'Badania objęte kontraktem',
    not_on_contract: 'Badania nie objęte kontraktem',
    send_order: 'Wyślij zlecenie',
    total_simple: 'Całkowity',
    create_orders_template: 'Stwórz szablon zleceń',
    upload_logo: 'Prześlij logo',
    upload_photo_png_format:
      'Należy pamiętać, że logo musi być w formacie .png',
    change_photo: 'Zmień zdjęcie',
    short_bio: 'Krótki opis',
    new_health_status: 'Aktualny stan zdrowia',
    choose_tags: 'Wybierz tagi',
    stressful_simple: 'Stresujący',
    add_simple: 'Dodaj',
    create_new_order: 'Utwórz nowe zlecenie',
    create_new_order_patient: 'Dodaj pacjenta lub wybierz usługi',
    my_dashboard: 'Moja tablica',
    new_patient_simple: 'Nowy pacjent',
    news_simple: 'Aktualności',
    latest_results: 'Ostatnie wyniki',
    simple_pesel: 'PESEL',
    result_is_ready: 'Wynik jest gotowy',
    welcome_simple: 'Witaj',
    login_simple: 'Zaloguj się',
    create_account: 'Utwórz konto',
    data_simple: 'Dane',
    address_simple: 'Adres',
    select_the_type: 'Wybierz typ',
    please_select_the_patient_or_add: 'Proszę wybrać pacjenta lub dodać nowego',
    mandatory_informations: 'Informacje obowiązkowe',
    non_mandatory_informations: 'Nieobowiązkowe informacje',
    patient_data_data_will_load:
      'Dane pacjenta będą się ładować  automatycznie, gdy pacjent założy konto w systemie.',
    add_new_note: 'Dodaj nową notatkę',
    save_note: 'Zapisz notatkę',
    delete_note: 'Usuń notatkę',
    preorder_no: 'Numer zlecenia',
    personal_informations: 'Informacje osobowe',
    order_simple: 'Zlecenie',
    send_a_recomandation: 'Utwórz zlecenie',
    chat_simple: 'Czat',
    send_a_message: 'Wyślij wiadomość.',
    see_contract_details: 'Zobacz szczegóły',
    share_request: 'Wyślij prośbę',
    send_share_request: 'Wyślij prośbę o udostępnienie',
    edit_healts_status: 'Edycja stanu zdrowia.',
    add_personal_notes: 'Dodaj notatkę osobistą',
    add_to_order: 'Dodaj do zlecenia',
    related_tests: 'Badania powiązane',
    save_comment: 'Zapisz komentarz',
    age_simple: 'Wiek',
    deficient_simple: 'Niedobór',
    result_is_deficient: 'Wynik świadczy o niedoborze',
    recent_results: 'Ostatnie wyniki',
    profile_simple: 'Profil',
    go_to_cart: 'Idź do koszyka',
    success_simple: 'Sukces',
    successfully_sent_order: 'Zlecenie zostało pomyślnie wysłane do',
    go_to_home: 'Wróć do strony głównej',
    services: 'Badania ',
    remove_simple: 'Usuń ',
    package_name: 'Nazwa badania',
    package_saved: 'Zapisano pakiet',
    add_a_name: 'Dodaj nazwę',
    add_an_item: 'Dodaj usługę',
    add_a_patient: 'Musisz dodać pacjenta',
    all_services: 'Wszystkie usługi',
    download: 'Pobieranie',
    working_day: 'dzień roboczy',
    working_days: 'dni robocze',
    working_dayss: 'dni roboczych',
    mypackages_info:
      'Stwórz swoje własne pakiety dopasowane do potrzeb Twoich pacjentów. Dodaj testy w bibliotece medycznej i nazwij pakiet w widoku koszyka.',
    material_code: 'Kod badania',
    patient_presentation: 'Przygotowanie pacjenta',
    links: 'Powiązane pliki',
    previous_page: 'Poprzednia strona',
    next_page: 'Kolejna strona',
    notification_pending: 'W trakcie przygotowania ',
    notification_addToCart: 'Badanie poprawnie dodane do zlecenia',
    notification_removeFromCart: 'Badanie usunięte ze zlecenia',
    most_often_list:
      'Po pierwszych 3 zamówieniach, zobaczysz tutaj listę najczęściej zlecanych badań',
    most_often_title: 'Najczęściej zlecane',
    name_results_patients: 'Nazwa badania',
    patient_added: 'Pacjent przypisany do zlecenia',
    order_created: 'Zlecenie stworzone',
    patients_info: 'Karty pacjentów, którzy zatwierdzili podane informacje. ',
    go_back: 'Wróć',
    notes_info:
      'W tym miejscu możesz zapisać swoje uwagi dotyczące wyników. Są to informacje widoczne tylko dla Ciebie (pacjent nie ma do nich dostępu).',
    edit_profil_info:
      'Jeśli chcesz zmienić dane swojego konta, prosimy o kontakt z przedstawicielem medycznym Synevo.',
    list: 'Lista',
    select_patient: 'Wybierz pacjenta',
    education: 'Wykształcenie',
    display_name: 'Wyświetlana nazwa',
    add_a_number: "Podaj numer telefonu",
  }
});
let lang = localStorage.getItem('lang');
if (lang) multilanguage.setLanguage(lang);
else multilanguage.setLanguage('en');

export default multilanguage;
