import React from 'react';
import multilanguage from '../multilanguage';
import api from '../api/api';
import auth from '../api/auth';
import DLoader from "./parts/DLoader";
import cart from '../api/cart';
import emitter from 'tiny-emitter/instance';
import { isEmpty } from 'lodash';

export default  class ProductDetail extends React.Component {

    constructor() {
        super();
        this.state={
            produs: [],
           loading: true,
        }
    }

    componentDidMount(){
        const id = this.props.match.params.id;
        api.get('/ServiceKnowledgebase/service/'+id)
        .then(res => {
            this.setState({loading: false, produs: res.data});
        })
        emitter.on("cartUpdate", ()=>{
            this.forceUpdate()
        })
    }
    getData(id){
        api.get('/ServiceKnowledgebase/service/'+id)
        .then(res => {
            this.setState({loading: false, produs: res.data});
        })
    }
    addtocart(){
        cart.addItem(this.state.produs)
        this.props.history.goBack()
    }
    addtocartrelated(item){
        cart.addItem(item)
    }
    render() {
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="home_header_inner">
                    <div className="order_detail_back_icon" onClick={() => this.props.history.goBack()}>
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                   <div className="product_detail_header_title">{this.state.produs.name}</div>
                    <div className="morE_menu_left_white_space"></div>
                </div>
               </div>
                {this.state.loading ? <DLoader /> :
               <div className="product_detail_info_box_container">
                    {/* <div className="product_detail_info_box_item">
                        <div className="product_detail_label">{multilanguage.hex_code}</div>
                        <div className="product_detail_value">{this.state.produs.hexCode}</div>
                    </div> */}
                    {/* <div className="product_detail_info_box_item">
                        <div className="product_detail_label">{multilanguage.department_simple}</div>
                        <div className="product_detail_value">{this.state.produs.category}</div>
                    </div> */}
                    <div className="product_detail_info_box_item">
                        <div className="product_detail_label">{multilanguage.material_simple}</div>
                        <div className="product_detail_value">{this.state.produs.material ? this.state.produs.material : "-"}</div>
                    </div>
                    <div className="product_detail_info_box_item">
                        <div className="product_detail_label">{multilanguage.material_code}</div>
                        <div className="product_detail_value">{this.state.produs.sampleType ? this.state.produs.sampleType : "-"}</div>
                    </div>
                    <div className="product_detail_info_box_item">
                        <div className="product_detail_label">{multilanguage.time_to_results}</div>
                        {this.state.produs.timeToResult ? <div className="product_detail_value">{this.state.produs.timeToResult} {this.state.produs.timeToResult == "1" ? multilanguage.working_day : this.state.produs.timeToResult >= "2" && this.state.produs.timeToResult <= "4" ? multilanguage.working_days : multilanguage.working_dayss}</div>: "-"}
                    </div>
                    {/* <div className="product_detail_info_box_item">
                        <div className="product_detail_label">{multilanguage.sample_volume}</div>
                        <div className="product_detail_value">{this.state.produs.sampleVolume}</div>
                    </div> */}
                    <div className="product_detail_add_button"  onClick={() => this.addtocart()}>{multilanguage.add_to_order}</div>

                {!isEmpty(this.state.produs.relatedServiceList) ? 
                <div className="product_detail_info_container">
                    <div className="product_detail_label">{multilanguage.related_tests}</div>

                    <div className="related_overflow">
                    <div className="product_detail_related_test_container">
                        {this.state.produs.relatedServiceList.map((related)=>{
                            return(
                            <div className="product_detail_relatex_box">
                            <div className="product_detail_related_title" onClick={() => {this.props.history.push('/product_detail/'+related.id); this.getData(related.id)}}>{related.name}</div>
                            <div className="product_detail_related_line"  onClick={() => cart.addItem(related)}>
                                <div className="product_detail_related_price">{related.price} {multilanguage.currency}</div>
                                <div 
                                            
                                            className={cart.findItem(related.id) != null  ? "new_order_checked_pacient" : "new_order_unchecked_pacient"}>
                                                <img src={require('../assets/images/whiteCheck.svg')} />
                                    </div>
                            </div>
                        </div>
                            )
                        })}
                    </div>
                    </div>

                </div>
                : null}
                    {this.state.produs.clinicalPurpose ?
                    <div className="product_detail_info_container">
                        <div className="product_detail_label">{multilanguage.clinical_purpose}</div>
                        <div className="product_detail_description">{this.state.produs.clinicalPurpose}</div>
                    </div>
                    :null}
                    {this.state.produs.description ?
                    <div className="product_detail_info_container">
                        <div className="product_detail_label">{multilanguage.description_simple}</div>
                        <div className="product_detail_description">{this.state.produs.description}</div>
                    </div>
                    :null}
                    {this.state.produs.referenceValues ?
                    <div className="product_detail_info_container">
                        <div className="product_detail_label">{multilanguage.reference_values}</div>
                        <div className="product_detail_description">{this.state.produs.referenceValues}</div>
                    </div>
                    :null}
                    {this.state.produs.interferences ?
                    <div className="product_detail_info_container">
                        <div className="product_detail_label">{multilanguage.interferences_simple}</div>
                        <div className="product_detail_description">{this.state.produs.interferences}</div>
                    </div>
                    :null}
                    {this.state.produs.references ?
                    <div className="product_detail_info_container">
                        <div className="product_detail_label">{multilanguage.references_simple}</div>
                        <div className="product_detail_description">{this.state.produs.references}</div>
                    </div>
                    :null}
                    {this.state.produs.patientPreparation ?
                    <div className="product_detail_info_container">
                        <div className="product_detail_label">{multilanguage.patient_presentation}</div>
                        <div className="product_detail_description">{this.state.produs.patientPreparation}</div>
                    </div>
                    :null}
                    {this.state.produs.fileName ?
                    <div className="product_detail_info_container">
                        <div className="product_detail_label">{multilanguage.links}</div>
                        <div className="product_detail_description">{this.state.produs.fileName ? <a href={'https://www.synevo.pl/dokumenty/'+this.state.produs.fileName}>{this.state.produs.fileName}</a>:null}</div>
                    </div>
                    :null}
                </div>}

           </div>
)}
}
