import React from 'react';
import NavBar from './parts/NavBar';
import multilanguage from '../multilanguage';

let screenHeight = 0;

export default  class MyLocation extends React.Component {

    constructor() {
        super();
        this.state={
           selected : 1,
           visibleNavBar: true,
        }
        this.handleResize = this.handleResize.bind(this);
    }

    changeSelected(id){
        this.setState({selected : id})
    }

    componentDidMount(){
        screenHeight = document.documentElement.clientHeight;
        window.addEventListener('resize',this.handleResize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.handleResize);
    }

    handleResize(){
        if(screenHeight - document.documentElement.clientHeight > 150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: false})
        }
        if(screenHeight - document.documentElement.clientHeight < -150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: true})
        }
    }

    render() {
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.my_location}</div>
                </div>
               </div>

                <div className="editProfile_container">

                    <div className="edit_profile_form_container">
                        <div className="myLocationLabel">{multilanguage.name_of_the_facility}</div>
                        <input type="text" placeholder="" />
                        <div className="myLocationLabel">{multilanguage.address_simple}</div>
                        <input type="text" placeholder="" />
                        <div className="myLocationLabel">{multilanguage.type_of_the_facility}</div>
                        <div className="myLocationSubLabel">{multilanguage.select_the_type}</div>

                        <div className="myLocation_boxed_container">

                            <div className={this.state.selected == 1 ? "myLocation_box_item selected" :  "myLocation_box_item"} onClick={() => this.changeSelected(1)}>
                                <div className="myLocation_box_item_top_box">
                                     <img src={require('../assets/images/blueHospital.svg')} />
                                </div>
                                <span className="myLocation_box_item_name">{multilanguage.hospital}</span>
                            </div>

                            <div className={this.state.selected == 2 ? "myLocation_box_item selected" :  "myLocation_box_item"} onClick={() => this.changeSelected(2)}>
                                <div className="myLocation_box_item_top_box">
                                     <img src={require('../assets/images/blueCabinet.svg')} />
                                </div>
                                <span className="myLocation_box_item_name">{multilanguage.private_cabinet}</span>
                            </div>

                            <div className={this.state.selected == 3 ? "myLocation_box_item selected" :  "myLocation_box_item"} onClick={() => this.changeSelected(3)}>
                                <div className="myLocation_box_item_top_box">
                                     <img src={require('../assets/images/blueClinic.svg')} />
                                </div>
                                <span className="myLocation_box_item_name">{multilanguage.clinic}</span>
                            </div>

                            <div className={this.state.selected == 4 ? "myLocation_box_item selected" :  "myLocation_box_item"} onClick={() => this.changeSelected(4)}>
                                <div className="myLocation_box_item_top_box">
                                     <img src={require('../assets/images/blueHealthcare.svg')} />
                                </div>
                                <span className="myLocation_box_item_name">{multilanguage.healthcare_facility}</span>
                            </div>
                        </div>

                        <button>{multilanguage.save_simple}</button>
                    </div>
                </div>
                
                {this.state.visibleNavBar ?
                <NavBar navigation={this.props.history} />
                : null}
           </div>
)}
}
