import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import Switch from './parts/Switch';
import multilanguage from '../multilanguage';
import api from '../api/api';
import auth from '../api/auth';
import cart from '../api/cart';
import emitter from 'tiny-emitter/instance';
import {NotificationManager} from 'react-notifications';
import DLoader from "./parts/DLoader";


export default  class Cart extends React.Component {

    constructor() {
        super();
        this.state={
            loading: false,
        pakageName: '',
        savePressed: false,
        discount:false,
        phoneNumber: '',
        showphonefield: false,
        
           tests:[
               {id:1, name: 'Hemogramă cu formulă leucocitară, Hb, Ht, indici şi reticulocite (Hemoleucogramă)', code:'HE56'},
               {id:2, name: 'Anticorpi antitrombocitari liberi în ser', code:'HE56'},
               {id:3, name: 'Examen picătură groasă şi frotiu de sânge pentru malarie', code:'ME71'},
           ],
           tests_not:[
            {id:1, name: '1,25-(OH)2-Vitamina D3', code:'HE56'},
           ],
           discount: true,
           dicountDisabled: false,
        }
    }

    componentDidMount(){
        AOS.init();
        api.get('/Profile')
            .then(res => {
                this.setState({dicountDisabled: !res.data.hasDiscount})
            }) 
    }

    deleteTest(product,e){
        var item = e.target.closest('.store_test_item');
        item.style.opacity = '0';
        item.style.transform = 'translateX(-1000px)';
        setTimeout(() => {
            var array = [...this.state.tests];
            var index = array.indexOf(product);
            if(index != -1){
                this.state.total += item.pret;
                array.splice(index, 1);
            }
            this.setState({ tests: array });
        }, 500);
    }
    componentWillMount(){
        emitter.on("cartUpdate", ()=>{
            this.forceUpdate()
        })
      }

    renderTests = (items) => {
        return items.map((item,index) => {
            return(
                <div key={item.id} className="store_test_item">
                    <div className="store_test_item_left">
                        <div className="store_test_name">{item.name}</div>
                        <div className="store_test_bottom">
                            <div className="store_test_code">{item.code}</div>
                            <div className="store_test_point"></div>
                            {item.basePrice == item.price ? 
                            <div className="store_test_code">

{item.price} {multilanguage.currency}
                            </div>:
                            <div className="store_test_code">

<span className="discount-text">{item.basePrice} {multilanguage.currency}</span> {item.price} {multilanguage.currency}
                            </div>}
                        </div>              
                    </div>
                    <div className="store_test_item_delete_button" onClick={(e) => this.removeProduct(item.id)}>
                        <img src={require('../assets/images/whiteclose.svg')} />
                    </div>
                </div>
            )
        })
    }
    removeProduct(id){
        cart.removeItem(id)
    }
    saveMyPakage(){
        
        var serviceCodeList = []
        if(cart.itemCount > 0){

            if(this.state.savePressed){
                if(this.state.pakageName != ''){
                    this.setState({loading: true})
                    cart.items.map((item)=>{
                        serviceCodeList.push(item.code+':'+item.material)
                    }) 
                    var name = this.state.pakageName
                    api.put('/ServiceKnowledgebase/CustomServicePackage', {name, serviceCodeList})
                        .then(res => {
                            this.setState({loading: false, packages: res.data, savePressed: false});
                            cart.empty()
                            this.setState({loading: false})
                            NotificationManager.success(multilanguage.package_saved, '',1000)
                        })
                }else{
                    NotificationManager.error(multilanguage.add_a_name, '',1000)
                }
                
            }
            this.setState({savePressed: true})
        }else{
            NotificationManager.error(multilanguage.add_an_item, '',1000)
        }

    }
    saveNumber(){
        if(this.state.phoneNumber.length > 8){
            var patient = {
                phoneNumber: this.state.phoneNumber
            }
            cart.addPatient(patient)
            this.setState({showphonefield: false, phoneNumber: ""})
        }else{
            NotificationManager.error(multilanguage.add_a_number, '',1000)
        }
    }
    sendOrder(){
            cart.setLoading(true)
            var request = {}
            request.categoty = 1
            request.patient = cart.patient
            request.services = cart.items
            request.services.map((item)=>{
                if(item.material){
                    if(item.code.indexOf(":") == -1){
                        item.code = item.code+':'+item.material
                    }
                }else{
                    item.code = item.code+':'
                }
                
            })
            
            if(cart.patient){
                if(cart.itemCount > 0){
                    
                    api.put('/ServiceRequest', request)
                    .then(res => {
                        // this.setState({loading: false})
                        
                        // this.props.history.push("/patient_detail/"+this.state.patient.id)
                        this.props.history.push('/success', {name: cart.patient.firstName+" "+cart.patient.lastName})
                        cart.empty()
                        
                    })
                    .catch(error =>{
                        // this.setState({loading: false})
                        error.response.data.invalidParameters ? error.response.data.invalidParameters.map((e)=>{
                            NotificationManager.error(e.reason, '',1000);
                        }) : 
                        cart.setLoading(false)
                    })
                }else{
                    NotificationManager.error(multilanguage.add_an_item, '',1000)
                    cart.setLoading(false)
                }
            } else {
                NotificationManager.error(multilanguage.add_a_patient, '',1000)
                cart.setLoading(false)
            }     
        
        
}

    render() {
        if(cart.patient){
        var pesel = ''
        if(cart.patient.id){
            cart.patient.externalIds.map((external)=>{   
                if(external.system == 'PESEL'){
                    pesel = external.value
                }
            })
        }
    }
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.my_order}</div>
                </div>
               </div>

                <div className="cart_container">
                    <div className="store_section_title store_section_title_first">{multilanguage.patient}</div>
                    {cart.patient ? 
                    cart.patient.id ? 
                    <div  className="patients_box_container_cart">
                        <div className="display_flex patient_mobile_align">
                            <div className="patients_box_inner_cart" onClick={() => this.props.history.push('/patient_detail/'+cart.patient.id, {name: cart.patient.firstName+' '+cart.patient.lastName, patient: cart.patient})}>
                                <div className="patients_box_top">
                                    <div className="patients_box_line">
                                        <span className="patients_box_label">{multilanguage.name}</span>
                                        <span className="patients_box_value_name_cart">{cart.patient.firstName} {cart.patient.lastName}</span>
                                    </div>
                                </div>
                                <div className="patients_box_line">
                                    <span className="patients_box_label">{multilanguage.pesel_number}</span>
                                    <span className="patients_box_value">{pesel}</span>
                                </div>
                                {/* <div className="patients_box_line">
                                    <span className="patients_box_label">{multilanguage.last_location}</span>
                                    <span className="patients_box_value">Centrul Synevo "Mama si Copilul"</span>
                                </div> */}
                            </div>
                            <div className="store_test_item_delete_button" onClick={(e) => cart.removePatient()}>
                                <img src={require('../assets/images/whiteclose.svg')} />
                            </div>
                        </div>
                    </div>
                    :
                    <div  className="patients_box_container_cart">
                        <div className="display_flex patient_mobile_align">
                            <div className="patients_box_inner_cart">
                                <div className="patients_box_top">
                                    <div className="patients_box_line">
                                        <span className="patients_box_label">{multilanguage.phone_number}</span>
                                        <span className="patients_box_value_name_cart">{cart.patient.phoneNumber}</span>
                                    </div>
                                </div>
                                {/* <div className="patients_box_line">
                                    <span className="patients_box_label">{multilanguage.last_location}</span>
                                    <span className="patients_box_value">Centrul Synevo "Mama si Copilul"</span>
                                </div> */}
                            </div>
                            <div className="store_test_item_delete_button" onClick={(e) => cart.removePatient()}>
                                <img src={require('../assets/images/whiteclose.svg')} />
                            </div>
                        </div>
                    </div>
                    : 
                    this.state.showphonefield ?
                    <div className="cart_phone_input">
                        <input className="d_add_pateint_input" type="text" placeholder={multilanguage.phone_number} onChange={(e) => this.setState({phoneNumber: e.target.value})} value={this.state.phoneNumber} />
                        <div className="d_add_patient_button button_margin" onClick={() => this.saveNumber()}>
                            <img  src={require('../assets/images/desktop/plus.svg')} />
                            <span>{multilanguage.save_patient}</span>
                        </div>
                    </div>
                    :
                    <div className="display_flex">
                        <div className="new_order_top_intro_cart">
                                <div className="new_order_box_add_new"  onClick={() => this.props.history.push('/new_order',{edit:false})}>
                                    <img src={require('../assets/images/bluePlus.svg')} />
                                    <span>{multilanguage.select_patient}</span>
                                </div>
                        </div>
                        <div className="new_order_top_intro_cart">
                        <div className="new_order_box_add_new"  onClick={() => this.setState({showphonefield: true})}>
                            <img src={require('../assets/images/bluePlus.svg')} />
                            <span>{multilanguage.new_patient_simple}</span>
                        </div>
                        </div>
                    </div>
                    }
                    <div className="store_section_title store_section_title_first">{multilanguage.services}</div>
                    <div className="store_tests_container">
                        {this.renderTests(cart.items)}
                    </div>
                    <div className="cart_discount_container">
                        <Switch 
                            disabled={this.state.dicountDisabled}
                            activeBackground={'#FDB913'}
                            value={cart.discount}
                            onChangeValue={(discount) => cart.setDiscount(discount)}
                        />
                        <span className="store_switch_label">{multilanguage.add_discount}</span>
                    </div>
                    {/* <div className="store_section_title store_section_title_first">{multilanguage.personal_comments}</div>
                    <textarea rows="4" className="cart_textarea" placeholder={multilanguage.write_your_notes_and}></textarea> */}
                    
                </div>

                <div className="cart_buottom_total_container">
                    <div className="cart_buottom_total_container_inner">
                        <div className="cart_bottom_total">
                            <span className="cart_bottom_total_label">{multilanguage.total_simple}:</span>
                            {cart.discountTotal ? 
                            <span className="cart_bottom_value"><span className="discount-text">{cart.totalPrice} {multilanguage.currency}</span> {cart.discountTotal} {multilanguage.currency}</span>:
                            <span className="cart_bottom_value">{cart.totalPrice} {multilanguage.currency}</span>}
                        </div>
                        <input className={this.state.savePressed ? "d_add_pateint_input": "d_add_pateint_input hide"} type="text" placeholder={multilanguage.package_name} onChange={(e) => this.setState({pakageName: e.target.value})} value={this.state.pakageName} />
                        <div className="cart_bottom_button_container">
                            <div className="cart_bottom_cancel" onClick={() => this.saveMyPakage()}>{multilanguage.save_to_my_packages}</div>
                            {auth.auth.user.role == 'practitioner' ? 
                            <div className="cart_bottom_send" onClick={() => this.sendOrder()}>
                                {cart.loading ? 
                                <span><img src={require('../assets/images/loader.svg')} width="60" alt=""/></span>:
                                <span>{multilanguage.send_order}</span>
                                }
                                {/* <img  src={require('../../assets/images/rightWhiteArrow.png')} /> */}
                            </div>: null }
                            
                        </div>
                    </div>
                </div>
           </div>
)}
}
