import React, { Component } from 'react';

class DLoader extends Component {
render(){
    return(
        <div className="lds-ring-container">
     <img src={require('../../assets/images/loader.svg')} width="60" alt=""/>
    </div>
    )
}
}

export default DLoader;