import React from "react";
import "./App.css";
import "./desktop/style.css";
import MediaQuery, { useMediaQuery } from "react-responsive";
/**
|--------------------------------------------------------------------------
| Mobile Components
|--------------------------------------------------------------------------
 */
import Login from "./components/Login";
import Home from "./components/Home";
import Orders from "./components/Orders";
import Patients from "./components/Patients";
import Notifications from "./components/Notifications";
import Messages from "./components/Messages";
import MedicalLibrary from "./components/MedicalLibrary";
import OrderDetail from "./components/OrderDetail";
import NewOrder from "./components/NewOrder";
import PatientDetail from "./components/PatientDetail";
import MedicalFiles from "./components/MedicalFiles";
import PatientOrders from "./components/PatientOrders";
import Notes from "./components/Notes";
import Contract from "./components/Contract";
import HealthStatus from "./components/HealthStatus";
import Results from "./components/Results";
import EditProfile from "./components/EditProfile";
import MyLocation from "./components/MyLocation";
import CreateTemplate from "./components/CreateTemplate";
import LatestResults from "./components/LatestResults";
import Settings from "./components/Settings";
import Packages from "./components/Packages";
import StoreList from "./components/StoreList";
import ProductDetail from "./components/ProductDetail";
import Cart from "./components/Cart";
import Success from "./components/Success";
import Result from "./components/Result";
import ResultDetail from "./components/ResultDetail";
import Chat from "./components/Chat";
import NewPatient from "./components/NewPatient";
import EditPatient from "./components/EditPatient";
import PacketList from "./components/PacketList";
import MyPackages from "./components/MyPackages";

/**
|--------------------------------------------------------------------------
| Desktop Components
|--------------------------------------------------------------------------
 */

import DHome from "./desktop/DHome";
import DNotifications from "./desktop/DNotifications";
import DPatients from "./desktop/DPatients";
import DAccount from "./desktop/DAccount";
import DOrders from "./desktop/DOrders";
import DEditProfile from "./desktop/DEditProfile";
import DCreateTemplate from "./desktop/DCreateTemplate";
import DMessages from "./desktop/DMessages";
import DMedicalLibrary from "./desktop/DMedicalLibrary";
import DOrderDetail from "./desktop/DOrderDetail";
import DPatientDetail from "./desktop/DPatientDetail";
import DMedicalFiles from "./desktop/DMedicalFiles";
import DSettings from "./desktop/DSettings";
import DResults from "./desktop/DResults";
import DContract from "./desktop/DContract";
import DNotes from "./desktop/DNotes";
import DMyLocation from "./desktop/DMyLocation";
import DPackages from "./desktop/DPackages";
import DResultDetail from "./desktop/DResultDetail";
import DProductDetail from "./desktop/DProductDetail";
import DAddPatient from "./desktop/DAddPatient";
import DResetPassword from "./desktop/DResetPassword";
import DEditPatient from "./desktop/DEditPatient";
import DMyPackages from "./desktop/DMyPackages";



import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

function AppRouter(props) {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return (
    <React.Fragment>
      <Router basename="/">
        <ScrollToTop>
          {!props.auth.logged ? (
            <>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route path="/login" component={Login} />
            <Route>
              <Redirect to="/login" />
            </Route>
            </>
          ):(
            <>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="/home" component={isMobile ? Home : DHome} />
            <Route path="/orders" component={isMobile ? Orders : DOrders} />
            <Route path="/patients" component={isMobile ? Patients : DPatients} />
            <Route path="/account" component={isMobile ? DAccount : DAccount} />
            <Route path="/notifications" component={isMobile ? Notifications : DNotifications} />
            <Route path="/messages" component={isMobile ? Messages : DMessages} />
            <Route path="/library" component={isMobile ? MedicalLibrary : DMedicalLibrary} />
            <Route path="/order_detail/:id" component={isMobile ? OrderDetail : DOrderDetail} />
            <Route path="/new_order" component={isMobile ? NewOrder : DHome} />
            <Route path="/patient_detail/:id" component={isMobile ? PatientDetail : DPatientDetail} />
            <Route path="/medical_files" component={isMobile ? MedicalFiles : DMedicalFiles} />
            <Route path="/packet_list/:id" component={isMobile ? PacketList : DPackages} />
            
            <Route path="/patient_orders/:id" component={isMobile ? PatientOrders : DPatientDetail} />
            <Route path="/notes/:id" component={isMobile ? Notes : DNotes} />
            <Route path="/contract" component={isMobile ? Contract : DContract} />
            <Route path="/health_status" component={isMobile ? HealthStatus : DPatientDetail} />
            <Route path="/results" component={DResults} />
            <Route path="/patient_results/:id" component={Results} />
            <Route path="/edit_profile" component={isMobile ? EditProfile : DEditProfile} />
            <Route path="/my_location" component={isMobile ? MyLocation : DMyLocation} />
            <Route path="/templates" component={isMobile ? CreateTemplate : DCreateTemplate} />
            <Route path="/latest_results" component={isMobile ? LatestResults : DResults} />
            <Route path="/settings" component={isMobile ? Settings : DSettings} />
            <Route path="/packages" component={isMobile ? Packages : DPackages} />
            <Route path="/store_list" component={isMobile ? StoreList : DMedicalLibrary} />
            <Route path="/product_detail/:id" component={isMobile ? ProductDetail : DProductDetail} />
            <Route path="/cart" component={isMobile ? Cart : DHome } />
            <Route path="/success" component={isMobile ? Success  : DHome} />
            <Route path="/result/:id" component={isMobile ? Result : DResultDetail} />
            <Route path="/result_detail/:id" component={isMobile ? ResultDetail : DResultDetail} />
            <Route path="/chat" component={isMobile ? Chat  : DMessages} />
            <Route path="/new_patient" component={isMobile ? NewPatient : DAddPatient} />
            <Route path="/edit_patient/:id" component={isMobile ? EditPatient : DEditPatient} />        
            <Route path="/reset_password" component={isMobile ? EditProfile : DResetPassword} />
            <Route path="/my_packages" component={isMobile ? MyPackages : DMyPackages} />
            <Route>
                <Redirect to="/home" />
            </Route>
            </>
          )}
        </ScrollToTop>
      </Router>
    </React.Fragment>
  );
}

export default AppRouter;
