import React, { PureComponent } from 'react'
import Switch from '../../components/parts/Switch';
import multilanguage from '../../multilanguage';
import api from '../../api/api';
import auth from '../../api/auth';
import cart from '../../api/cart';
import emitter from 'tiny-emitter/instance';
import {NotificationManager} from 'react-notifications';
import DLoader from './DLoader';

export default class SlideCart extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
        loading: false,
        pakageName: '',
        savePressed: false,
        hasDiscount:false,
        showphonefield: false,
        phoneNumber: "",
        dicountDisabled: false,
        categorii : [
                {
                    id:1,
                    name: 'Items',
                    teste : [
                        {
                            id:1,
                            name: 'Hemogramă cu formulă leucocitară, Hb,Ht,indici şi reticulocite (Hemoleucogramă)',
                            pret: 350,
                        },
                        {
                            id:2,
                            name: 'Anticorpi antitrombocitari liberi în ser',
                            pret: 40,
                        },
                    ]
                },
               
            ]
    }
    this.cartSlideRef = React.createRef();
  }

  componentWillMount(){
    api.get('/Profile')
            .then(res => {
                this.setState({dicountDisabled: !res.data.hasDiscount})
            })
    emitter.on("cartUpdate", ()=>{
        this.forceUpdate()
    })
  }

  componentDidUpdate(){
    if(this.props.active){
        setTimeout(() => {
               this.cartSlideRef.current.style.transform = 'translateX(0px)';
        }, 10);
     
    }
  }

    componentWillUnmount() {
    }

handleOutsideClick() {
    if (this.props.active) {
        this.closeSlide()
    }
}

    closeSlide(){
          this.cartSlideRef.current.style.transform = 'translateX(450px)';
          setTimeout(() => {
              this.props.onClose();
          }, 200);
    }


    removeProduct(id){
        cart.removeItem(id)
    }
    renderItems = (items) => {
        return cart.items.map((item,index) => {
            return(
                <div key={index} className="slide_cart_category_items_cointainer">
                    <div className="slide_cart_category_items_cointainer_left">                
                        <div className="slide_cart_item_remove_button" onClick={() => this.removeProduct(item.id)}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.41007 6.49966L12.5053 2.40446C13.106 1.80369 13.1676 0.882698 12.6421 0.357199C12.1166 -0.168301 11.1949 -0.106719 10.5949 0.494048L6.49966 4.58925L2.40446 0.494048C1.80369 -0.106719 0.882698 -0.168301 0.357199 0.357883C-0.168301 0.884067 -0.106719 1.80506 0.494048 2.40514L4.58925 6.50034L0.494048 10.5955C-0.106719 11.1963 -0.168301 12.1173 0.357883 12.6428C0.884067 13.1683 1.80506 13.1067 2.40514 12.506L6.50034 8.41075L10.5955 12.506C11.1963 13.1067 12.1173 13.1683 12.6428 12.6428C13.1683 12.1173 13.1067 11.1956 12.506 10.5955L8.41075 6.50034L8.41007 6.49966Z" fill="#F23E3A"/>
                            </svg>
                        </div>
                        <span  className="slide_cart_item_name">{item.name}</span>
                    </div>
                    {item.basePrice == item.price ? 
                    <span className="slide_cart_item_price">{item.price} {multilanguage.currency}</span>:
                    <span className="slide_cart_item_price"><span className="discount-text">{item.basePrice} {multilanguage.currency}</span> {item.price} {multilanguage.currency}</span>}
                </div>
            )
        })
    }
    saveNumber(){
        if(this.state.phoneNumber.length > 8){
            var patient = {
                phoneNumber: this.state.phoneNumber
            }
            cart.addPatient(patient)
            this.setState({showphonefield: false, phoneNumber: ""})
        }else{
            NotificationManager.error(multilanguage.add_a_number, '',1000)
        }
    }
    renderCategories = (items) => {
        return items.map((item,index) => {
            return(
                <div key={index}>
                    <span className="slide_cart_category_name">{multilanguage.services}</span>
                    <div className="slide_cart_category_items_container">
                        {this.renderItems(item.teste)}
                    </div>
                </div>
            )
        })
    }
  
    saveMyPakage(){
        
        var serviceCodeList = []
        if(cart.itemCount > 0){

            if(this.state.savePressed){
                if(this.state.pakageName != ''){
                    this.setState({loading: true})
                    cart.items.map((item)=>{
                        serviceCodeList.push(item.code+':'+item.material)
                    }) 
                    var name = this.state.pakageName
                    api.put('/ServiceKnowledgebase/CustomServicePackage', {name, serviceCodeList})
                        .then(res => {
                            this.setState({loading: false, packages: res.data, savePressed: false});
                            cart.empty()
                            this.setState({loading: false})
                            NotificationManager.success(multilanguage.package_saved, '',1000)
                        })
                }else{
                    NotificationManager.error(multilanguage.add_a_name, '',1000)
                }
                
            }
            this.setState({savePressed: true})
        }else{
            NotificationManager.error(multilanguage.add_an_item, '',1000)
        }

        
    }
  render() {
    
    return( 
        <div id="slideCart" style={this.props.active ? null : {display:"none"}}>
            <div className="slide-cart-overlay" onClick={()=> this.handleOutsideClick()}> </div>
            <div ref={this.cartSlideRef} id="slide_cart_container">
                <div className="cart_slide_container_top_close">
                    <div className="slide_menu_title_cart">{multilanguage.my_order} ({cart.itemCount})</div>
                    <div className="slide_cart_close_button" onClick={() => this.closeSlide()}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.62852 19.549C2.0272 20.1503 1.05228 20.1503 0.450972 19.549C-0.150334 18.9477 -0.150323 17.9727 0.450997 17.3714L17.3715 0.450981C17.9728 -0.150337 18.9477 -0.150326 19.549 0.451006C20.1503 1.05234 20.1503 2.02728 19.549 2.6286L2.62852 19.549Z" fill="#A09FA6"/>
                            <path d="M0.450985 2.62853C-0.150328 2.02722 -0.150328 1.0523 0.450985 0.450985C1.0523 -0.150328 2.02722 -0.150328 2.62853 0.450985L19.549 17.3715C20.1503 17.9728 20.1503 18.9477 19.549 19.549C18.9477 20.1503 17.9728 20.1503 17.3715 19.549L0.450985 2.62853Z" fill="#A09FA6"/>
                        </svg>
                    </div>
                </div>
                {auth.auth.user.role == 'practitioner' ? 
                <div>
                <div className="store_section_title store_section_title_first desktop_section_title">{multilanguage.patient}</div>
                {!cart.patient ? 
                this.state.showphonefield ?  
                <>
                <input className="d_add_pateint_input" type="text" placeholder={multilanguage.phone_number} onChange={(e) => this.setState({phoneNumber: e.target.value})} value={this.state.phoneNumber} />
                <div className="d_add_patient_button button_margin" onClick={() => this.saveNumber()}>
                        <img  src={require('../../assets/images/desktop/plus.svg')} />
                        <span>{multilanguage.save_patient}</span>
                    </div>
                    </>
                    :
                <div className="display_flex">
                    <div className="d_add_patient_button button_margin" onClick={() => this.props.screenProps.push('/patients')}>
                        <img  src={require('../../assets/images/desktop/plus.svg')} />
                        <span>{multilanguage.select_patient}</span>
                    </div>
                    <div className="d_add_patient_button button_margin" onClick={() => this.setState({showphonefield: true})}>
                        <img  src={require('../../assets/images/desktop/plus.svg')} />
                        <span>{multilanguage.new_patient_simple}</span>
                    </div>
                </div>
                :
                
                cart.patient.id ?
                <div className="d_cart_patient_container" >
                    <div className="slide_cart_item_remove_button margin_right" onClick={() => cart.removePatient()}>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.41007 6.49966L12.5053 2.40446C13.106 1.80369 13.1676 0.882698 12.6421 0.357199C12.1166 -0.168301 11.1949 -0.106719 10.5949 0.494048L6.49966 4.58925L2.40446 0.494048C1.80369 -0.106719 0.882698 -0.168301 0.357199 0.357883C-0.168301 0.884067 -0.106719 1.80506 0.494048 2.40514L4.58925 6.50034L0.494048 10.5955C-0.106719 11.1963 -0.168301 12.1173 0.357883 12.6428C0.884067 13.1683 1.80506 13.1067 2.40514 12.506L6.50034 8.41075L10.5955 12.506C11.1963 13.1067 12.1173 13.1683 12.6428 12.6428C13.1683 12.1173 13.1067 11.1956 12.506 10.5955L8.41075 6.50034L8.41007 6.49966Z" fill="#F23E3A"/>
                        </svg>
                    </div>
                    <img className="d_header_left_avatar" src={cart.patient.gender == 'F' ? require('../../assets/images/famale.png') : require('../../assets/images/male.png')} />
                    <div className="d_header_left_detail_name" onClick={() => this.props.screenProps.push('/patient_detail/'+cart.patient.id)}>{cart.patient.firstName} {cart.patient.lastName}</div>
                    {/* <div className="d_add_patient_button button_margin" onClick={() => this.props.screenProps.push({pathname:'/patients', state: { patient: cart.patient}})}>
                    <span>{multilanguage.edit_patient}</span>
                    </div> */}
                    
                </div>
                :
                <div className="d_cart_patient_container">
                    <div className="slide_cart_item_remove_button margin_right" onClick={() => cart.removePatient()}>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.41007 6.49966L12.5053 2.40446C13.106 1.80369 13.1676 0.882698 12.6421 0.357199C12.1166 -0.168301 11.1949 -0.106719 10.5949 0.494048L6.49966 4.58925L2.40446 0.494048C1.80369 -0.106719 0.882698 -0.168301 0.357199 0.357883C-0.168301 0.884067 -0.106719 1.80506 0.494048 2.40514L4.58925 6.50034L0.494048 10.5955C-0.106719 11.1963 -0.168301 12.1173 0.357883 12.6428C0.884067 13.1683 1.80506 13.1067 2.40514 12.506L6.50034 8.41075L10.5955 12.506C11.1963 13.1067 12.1173 13.1683 12.6428 12.6428C13.1683 12.1173 13.1067 11.1956 12.506 10.5955L8.41075 6.50034L8.41007 6.49966Z" fill="#F23E3A"/>
                        </svg>
                    </div>
                    <img className="d_header_left_avatar" src={cart.patient.gender == 'F' ? require('../../assets/images/famale.png') : require('../../assets/images/male.png')} />
                    {cart.patient.firstName ? 
                    <div className="d_header_left_detail_name">{cart.patient.firstName} {cart.patient.lastName}</div>
                    :
                    <div className="d_header_left_detail_name">{cart.patient.phoneNumber}</div>
                    }
                </div>    
                }
                </div> : null}
                <div className="slide_cart_menu_scrollable">
                    {this.renderCategories(this.state.categorii)}
                </div>

                 
                <br />
                <div className="buttons-slide-cart-fixed"> 
                    <div className="cart_slide_container_total">
                            <div className="slide_menu_title_total">{multilanguage.total}</div>
                            {cart.discountTotal ? 
                            <div className="slide_menu_title_total "><span className="discount-text">{cart.totalPrice} {multilanguage.currency} </span> {cart.discountTotal} {multilanguage.currency}</div>:
                            <div className="slide_menu_title_total ">{cart.totalPrice} {multilanguage.currency}</div>}
                        </div>
                    <div className="cart_discount_container desktop_discount">
                            
                            <Switch 
                                disabled={this.state.dicountDisabled}
                                activeBackground={'#FDB913'}
                                value={cart.discount}
                                onChangeValue={(discount) => cart.setDiscount(discount)}
                            />
                            <span className="store_switch_label">{multilanguage.add_discount}</span>
                        </div>
                            {auth.auth.user.role == 'practitioner' ?
                                <div>
                                    {/* <div className="store_section_title store_section_title_first desktop_section_title">{multilanguage.personal_comments}</div> */}
                                    {/* <textarea rows="4" className="cart_textarea desktop_cart_textarea" placeholder={multilanguage.write_your_notes_and}></textarea> */}
                                </div>: null}
                            {auth.auth.user.role == 'practitioner' ? 
                            <div className="slide_cart_pay_now_button" onClick={() => cart.sendOrder(this.props)}>
                                {cart.loading ? 
                                <span><DLoader /></span>:
                                <span>{multilanguage.send_order}</span>}
                                {/* <img  src={require('../../assets/images/rightWhiteArrow.png')} /> */}
                            </div>: null }
                            
                            <input className={this.state.savePressed ? "d_add_pateint_input": "d_add_pateint_input hide"} type="text" placeholder={multilanguage.package_name} onChange={(e) => this.setState({pakageName: e.target.value})} value={this.state.pakageName} />
                            <div className="slide_cart_pay_later_button" onClick={() => this.saveMyPakage()}>
                            {this.state.loading ? <span><DLoader /></span> :
                                <span>{multilanguage.save_to_my_packages}</span>}
                            </div>
                    </div>
                    
            </div>
            
            
        </div>   
)}
}