import React from 'react';
import NavBar from './parts/NavBar';
import AOS from 'aos';
import 'aos/dist/aos.css'
import PatientFilters from './parts/PatientFilters';
import multilanguage from '../multilanguage';
import DLoader from "./parts/DLoader";
import _ from 'lodash';
import api from '../api/api';
import auth from '../api/auth';
import Fuse from 'fuse.js'
import cart from '../api/cart';
let screenHeight = 0;

export default  class Patients extends React.Component {

    constructor() {
        super();
        this.state={
            patients: [],
            loading: true,
            search: null,
            visibleNavBar: true,
            filtersVisible:false,
         }
         this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount(){
        AOS.init(); 
        screenHeight = document.documentElement.clientHeight;
        window.addEventListener('resize',this.handleResize);
        var patients = []
        api.get('/Patient')
        .then(res => {
        patients = res.data.results    
        this.setState({patients: res.data.results, loading: false});
        })
    }
    searchtimeout = null
    doSearch(search){
      this.setState({loading: true})
      api.get('/Patient', {searchQuery: search})
              .then(res => {
                this.setState({
                  loading: false,
                  patients: res.data.results
                })
              })
    }
    search(search){
      this.setState({patients: []})
      if (this.searchtimeout) clearTimeout(this.searchtimeout)
      this.searchtimeout = setTimeout(() => this.doSearch(search), 1000);
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.handleResize);
    }

    handleResize(){
        if(screenHeight - document.documentElement.clientHeight > 150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: false})
        }
        if(screenHeight - document.documentElement.clientHeight < -150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: true})
        }
    }

    setFilterVisible(){
        this.setState({filtersVisible: true});
    }
    handleCloseFilters(){
        this.setState({filtersVisible: false});
    }


    render() {
        return (
           <div className="app_container">
                <PatientFilters navigation={this.props.history} visible={this.state.filtersVisible} onClose={() => this.handleCloseFilters()} />
               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner space-flex-header" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.my_patients}</div>
                    <div className="header_cart_container"  onClick={() => this.props.history.push('/cart')}>
                        <img src={require('../assets/images/cartIcon.svg')} />
                        <div className="header_cart_container_bullet">{cart.itemCount}</div>
                    </div>
                </div>
               </div>

                <div className="orders_search_container">
                    <div className="orders_search_input_container">
                        <img src={require('../assets/images/graysearch.svg')} />
                        <input type="text" placeholder={multilanguage.search_simple} 
                        onChange={evt => this.search(evt.target.value)}
                        disabled={this.state.loading}
                        />
                    </div>
                    {/* <div className="orders_search_filter_button" onClick={() => this.setFilterVisible()}>
                        <img src={require('../assets/images/filterIcon.svg')} />
                    </div> */}
                </div>

                <div className="orders_list_container">

                {/* <div className="orders_letter_delimitator">
                    <div className="orders_letter_delimitator_inner">A</div>
                </div> */}


                {this.state.loading ? <DLoader /> : null}
                {this.state.patients.map((persoana) => {
                  return(
                <div data-aos="zoom-in" className="patients_box_container" onClick={() => this.props.history.push('/patient_detail/'+persoana.id, {name: persoana.firstName+' '+persoana.lastName, patient: persoana})}>
                    <div className="patients_box_inner">
                        <div className="patients_box_top">
                            <div className="patients_box_line">
                                <span className="patients_box_label">{multilanguage.name}</span>
                                <span className="patients_box_value_name">{persoana.firstName} {persoana.lastName}</span>
                            </div>
                            <div className="morePatientsButton">
                                <img src={require('../assets/images/morePatients.svg')} />
                            </div>
                        </div>
                        <div className="patients_box_line">
                            <span className="patients_box_label">{multilanguage.pesel_number}</span>
                            {persoana.externalIds.map((external)=>{   
                        if(external.system == 'PESEL'){
                          return(
                            <span className="patients_box_value">{external.value}</span>
                          )
                        }
                    })}
                        </div>
                        {/* <div className="patients_box_line">
                            <span className="patients_box_label">{multilanguage.last_location}</span>
                            <span className="patients_box_value">Centrul Synevo "Mama si Copilul"</span>
                        </div> */}
                    </div>
                </div>
                  )
                })}
                </div>

                {/* {this.state.visibleNavBar ?
                <div className="patients_button_fixed" onClick={() => this.props.history.push('/new_patient',{edit:false})}>
                    <img src={require('../assets/images/whitePlus.svg')} />
                </div>
                : null} */}
                {this.state.visibleNavBar ?
                <NavBar navigation={this.props.history} />
                : null}
           </div>
)}
}
