import React from 'react';
import NavBar from './parts/NavBar';
import multilanguage from '../multilanguage';

let screenHeight = 0;

export default  class Messages extends React.Component {

    constructor() {
        super();
        this.state={
            visibleNavBar: true,
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount(){
        screenHeight = document.documentElement.clientHeight;
        window.addEventListener('resize',this.handleResize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.handleResize);
    }

    handleResize(){
        if(screenHeight - document.documentElement.clientHeight > 150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: false})
        }
        if(screenHeight - document.documentElement.clientHeight < -150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: true})
        }
    }

    render() {
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="header_with_back_title">{multilanguage.messages}</div>
                </div>
               </div>

                <div className="orders_search_container">
                    <div className="messages_search_input_container">
                        <img src={require('../assets/images/graysearch.svg')} />
                        <input type="text" placeholder={multilanguage.search_simple} />
                    </div>
                </div>

                <div className="horizontal_scroll_container">
                   <div className="horizontal_scroll_container_messages_inner">
                        <div className="messages_top_item">
                            <img className="messages_list_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                            <span>Olivia Wilson</span>
                        </div>
                        <div className="messages_top_item">
                            <img className="messages_list_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                            <span>Adam King</span>
                        </div>
                        <div className="messages_top_item">
                            <img className="messages_list_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                            <span>Maya Cooper</span>
                        </div>
                   </div>
                </div>

                <div className="messages_list_container">
                    <div className="messages_list_item" onClick={() => this.props.history.push('/chat')}>
                        <div className="messages_list_item_left">
                            <img className="messages_list_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                            <div className="messages_list_item_middle">
                                <div className="messages_list_item_name">Olivia Wilson (2)</div>
                                <div className="messages_list_item_text bold">Lorem ipsum dolor sit amet, consectetur...</div>
                            </div>
                        </div>
                        <div className="messages_list_item_right bold">
                            <span>17:58</span>
                            <div className="messages_list_item_dot"></div>
                        </div>
                    </div>

                    <div className="messages_list_item"  onClick={() => this.props.history.push('/chat')}>
                        <div className="messages_list_item_left">
                            <img className="messages_list_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                            <div className="messages_list_item_middle">
                                <div className="messages_list_item_name">Robert Davis</div>
                                <div className="messages_list_item_text">Lorem ipsum dolor sit amet, consectetur...</div>
                            </div>
                        </div>
                        <div className="messages_list_item_right">
                            <span>Sun</span>
                            <div className="messages_list_item_dot"></div>
                        </div>
                    </div>

                    
                    <div className="messages_list_item"  onClick={() => this.props.history.push('/chat')}>
                        <div className="messages_list_item_left">
                            <img className="messages_list_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                            <div className="messages_list_item_middle">
                                <div className="messages_list_item_name">Julia Henderson</div>
                                <div className="messages_list_item_text">Lorem ipsum dolor sit amet, consectetur...</div>
                            </div>
                        </div>
                        <div className="messages_list_item_right">
                            <span>Wed</span>
                            <div className="messages_list_item_dot"></div>
                        </div>
                    </div>
                </div>

                {this.state.visibleNavBar ?
                <NavBar navigation={this.props.history} />
                : null}
           </div>
)}
}
