import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import multilanguage from '../multilanguage';

class DMedicalFiles extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="desktop_container">
                <Menu screenProps={this.props.history} />
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} detail={true} />
                    <div className="d_main_content">

                        <div className="d_page_title_container_bordered">
                            <div className="d_page_title">{multilanguage.medical_files}</div>
                        </div>

                        <div className="d_medical_files_container">
                            <div className="d_medical_files_column">
                                <div className="d_medical_files_column_header">
                                    <div className="d_mfc_header_title">{multilanguage.patient_uploads}</div>
                                </div>
                                <div className="medical_files_item">
                                    <div className="messages_list_item_left">
                                        <img className="medical_files_picture" src={require('../assets/images/medical_files_placeholder.png')} />
                                        <div className="messages_list_item_middle">
                                            <div className="medical_files_item_name">img_02-radiografie pulmonara</div>
                                            <div className="messages_list_item_text">22.05.2018</div>
                                        </div>
                                    </div>
                                    <div className="messages_list_item_right">
                                        <img className="medical_files_download" src={require('../assets/images/blueDownload.svg')} />
                                    </div>
                                </div>
                                <div className="medical_files_item">
                                    <div className="messages_list_item_left">
                                        <img className="medical_files_picture" src={require('../assets/images/medical_files_placeholder.png')} />
                                        <div className="messages_list_item_middle">
                                            <div className="medical_files_item_name">img_02-radiografie pulmonara</div>
                                            <div className="messages_list_item_text">22.05.2018</div>
                                        </div>
                                    </div>
                                    <div className="messages_list_item_right">
                                        <img className="medical_files_download" src={require('../assets/images/blueDownload.svg')} />
                                    </div>
                                </div>
                                <div className="medical_files_item">
                                    <div className="messages_list_item_left">
                                        <img className="medical_files_picture" src={require('../assets/images/medical_files_placeholder.png')} />
                                        <div className="messages_list_item_middle">
                                            <div className="medical_files_item_name">img_02-radiografie pulmonara</div>
                                            <div className="messages_list_item_text">22.05.2018</div>
                                        </div>
                                    </div>
                                    <div className="messages_list_item_right">
                                        <img className="medical_files_download" src={require('../assets/images/blueDownload.svg')} />
                                    </div>
                                </div>
                            </div>
                            <div className="d_medical_files_column">
                                <div className="d_medical_files_column_header">
                                    <div className="d_mfc_header_title">{multilanguage.medic_uploads}</div>
                                    <div className="d_add_patient_button">
                                        <img  src={require('../assets/images/desktop/plus.svg')} />
                                        <span>{multilanguage.new_file}</span>
                                    </div>
                                </div>
                                <div className="medical_files_item">
                                    <div className="messages_list_item_left">
                                        <img className="medical_files_picture" src={require('../assets/images/medical_files_placeholder.png')} />
                                        <div className="messages_list_item_middle">
                                            <div className="medical_files_item_name">img_02-radiografie pulmonara</div>
                                            <div className="messages_list_item_text">22.05.2018</div>
                                        </div>
                                    </div>
                                    <div className="messages_list_item_right">
                                        <img className="medical_files_download" src={require('../assets/images/blueDownload.svg')} />
                                    </div>
                                </div>
                                <div className="medical_files_item">
                                    <div className="messages_list_item_left">
                                        <img className="medical_files_picture" src={require('../assets/images/medical_files_placeholder.png')} />
                                        <div className="messages_list_item_middle">
                                            <div className="medical_files_item_name">img_02-radiografie pulmonara</div>
                                            <div className="messages_list_item_text">22.05.2018</div>
                                        </div>
                                    </div>
                                    <div className="messages_list_item_right">
                                        <img className="medical_files_download" src={require('../assets/images/blueDownload.svg')} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
         );
    }
}
 
export default DMedicalFiles;