import React from 'react';
import NavBar from './parts/NavBar';
import multilanguage from '../multilanguage';
import moment from 'moment';
import DLoader from "./parts/DLoader";
import cart from '../api/cart';
import api from '../api/api';
import auth from '../api/auth';

export default  class PatientDetail extends React.Component {

    constructor() {
        super();
        this.state={
            parselNumber: '',
            patient: [],
            loading: true
        }
    }

    componentDidMount(){
        if(this.props.location.state && this.props.location.state.patient ) {
            this.props.location.state.patient.externalIds.map((external)=>{   
                if(external.system == 'PESEL'){
                    pesel = external.value
                }
            })
            this.setState({loading: false, patient: this.props.location.state.patient, parselNumber: pesel})

        }else{
            var pesel = ''
            api.get('/Patient/'+this.props.match.params.id)
            .then(res => {
                
                res.data.externalIds.map((external)=>{   
                    if(external.system == 'PESEL'){
                        pesel = external.value
                    }
                })
                this.setState({patient: res.data, loading: false, parselNumber: pesel});
            })
        }
    }
    addpatienttoorder(patient){
        cart.addPatient(patient)
        this.props.history.push('/library')
    }
    render() {
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="menu_account_avatar_container">
                        <img className="menu_account_avatar" src={this.state.patient.gender == 'F' ? require('../assets/images/famale.png') : require('../assets/images/male.png')} />
                    </div>
                    <div className="header_with_back_title_no_margin">{this.props.location.state.name}</div>
                </div>
               </div>
                

                <div className="patient_detail_navigator_container">
                    <div className="patient_detail_navigator_inner">
                        <div className="patient_detail_navigator_item active">{multilanguage.data_simple}</div>
                        {/* <div className="patient_detail_navigator_item" onClick={() => this.props.history.push('/medical_files/'+this.state.patient.id)}>{multilanguage.medical_files}</div> */}
                        <div className="patient_detail_navigator_item" onClick={() => this.props.history.push('/patient_results/'+this.state.patient.id, {name: this.props.location.state.name})}>{multilanguage.results}</div>
                        <div className="patient_detail_navigator_item" onClick={() => this.props.history.push('/patient_orders/'+this.state.patient.id, {name: this.props.location.state.name})}>{multilanguage.orders_simple} </div>
                    </div>
                </div>

                <div className="patient_detail_tags_container">
                    {/* <div className="patient_detail_tag_item">{multilanguage.smoker}</div>
                    <div className="patient_detail_tag_item">{multilanguage.diabetic}</div>
                    <div className="patient_detail_tag_item">{multilanguage.cardiovascular}</div>
                    <div className="patient_detail_tag_item">{multilanguage.cardiovascular}</div>
                    <div className="patient_detail_tag_item" onClick={() => this.props.history.push('/health_status')}>
                        <img src={require('../assets/images/bluePencil.svg')} />
                    </div> */}
                </div>
                {this.state.loading ? <DLoader /> :
                <div className="patient_detail_info_container">
                    <div className="patient_detail_edit_container">
                        <div className="patient_detail_info_title">{multilanguage.personal_informations}</div>
                        <div className="patient_detail_edit" onClick={() => this.props.history.push('/edit_patient/'+this.state.patient.id,{edit:true})}>{multilanguage.edit_simple}</div>
                    </div>
                    <div className="patient_detail_info_line">
                        <div className="patient_info_label">{multilanguage.date_of_birth}</div>
                        <div className="patient_info_value">{moment(this.state.patient.birthDate).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="patient_detail_info_line">
                        <div className="patient_info_label">{multilanguage.phone_simple}</div>
                        <div className="patient_info_value">{this.state.patient.phoneNumber}</div>
                    </div>
                    <div className="patient_detail_info_line">
                        <div className="patient_info_label">{multilanguage.email_simple}</div>
                        <div className="patient_info_value">{this.state.patient.email}</div>
                    </div>
                    <div className="patient_detail_info_line">
                        <div className="patient_info_label">{multilanguage.pesel_number}</div>
                        <div className="patient_info_value">{this.state.parselNumber}</div>
                    </div>
                    {/* <div className="patient_detail_info_line">
                        <div className="patient_info_label">{multilanguage.last_location}</div>
                        <div className="patient_info_value">Centrul Synevo "Mama si Copilul"</div>
                    </div> */}
                </div>
                }
                {this.state.loading ? null :
                <div className="patient_detail_boxes_container">
                    <div className="patient_detail_box_item" onClick={() => this.addpatienttoorder(this.state.patient)}>
                        <img src={require('../assets/images/blueBag.svg')} />
                        <div>
                            <div className="patient_detail_box_title" >{multilanguage.order_simple}</div>
                            <div className="patient_detail_box_subtitle">{multilanguage.send_a_recomandation}</div>
                        </div>
                    </div>
                    {/* <div className="patient_detail_box_item"  onClick={() => this.props.history.push('/chat')}>
                        <img src={require('../assets/images/patientChat.svg')} />
                        <div>
                            <div className="patient_detail_box_title">{multilanguage.chat_simple}</div>
                            <div className="patient_detail_box_subtitle">{multilanguage.send_a_message}</div>
                        </div>
                    </div> */}
                    {/* <div className="patient_detail_box_item" onClick={() => this.props.history.push('/contract')}>
                        <img src={require('../assets/images/contract.svg')} />
                        <div>
                            <div className="patient_detail_box_title">{multilanguage.contract_details}</div>
                            <div className="patient_detail_box_subtitle">{multilanguage.see_contract_details}</div>
                        </div>
                    </div> */}
                    {/* <div className="patient_detail_box_item">
                        <img src={require('../assets/images/blueShare.svg')} />
                        <div>
                            <div className="patient_detail_box_title">{multilanguage.share_request}</div>
                            <div className="patient_detail_box_subtitle">{multilanguage.send_share_request}</div>
                        </div>
                    </div> */}
                    {/* <div className="patient_detail_box_item" onClick={() => this.props.history.push('/health_status')}>
                        <img src={require('../assets/images/blueHealthStatus.svg')} />
                        <div>
                            <div className="patient_detail_box_title">{multilanguage.health_status}</div>
                            <div className="patient_detail_box_subtitle">{multilanguage.edit_healts_status}</div>
                        </div>
                    </div> */}
                    <div className="patient_detail_box_item"  onClick={() => this.props.history.push('/notes/'+this.state.patient.id)}>
                        <img src={require('../assets/images/blueNote.svg')} />
                        <div>
                            <div className="patient_detail_box_title">{multilanguage.notes}</div>
                            <div className="patient_detail_box_subtitle">{multilanguage.add_personal_notes}</div>
                        </div>
                    </div>
                </div>}
                <NavBar navigation={this.props.history} />

           </div>
)}
}
