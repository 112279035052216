import React, { Component } from 'react';
import Header from './parts/Header';
import Menu from './parts/Menu';
import multilanguage from '../multilanguage';
import api from '../api/api';
import auth from '../api/auth';
import moment from 'moment';
import DLoader from "./parts/DLoader";

class DOrderDetail extends Component {
    state = { 
        loading: true,
        order: [],
     }

     componentWillUnmount(){
         
     }

     componentDidMount(){
        const id = this.props.match.params.id;
        api.get('/ServiceRequest/'+id)
        .then(res => {
            this.setState({order: res.data, loading: false});
        })
        
     }
    render() { 
        var total = 0
        var totalDiscount = 0
        return ( 
            <div className="desktop_container">
                <Menu screenProps={this.props.history} />
                <div className="desktop_container_content">
                    <Header screenProps={this.props.history} />
                    <div className="d_main_content">

                    <div className="d_page_title_container">
                        <div className="d_page_title_with_button">
                            <div className="d_header_detail_back" onClick={() => this.props.history.goBack()}>
                                <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.515152 7.20675L6.9798 0.531902C7.66667 -0.177301 8.79798 -0.177301 9.48485 0.531902C10.1717 1.2411 10.1717 2.4092 9.48485 3.1184L4.27273 8.5L9.48485 13.8816C10.1717 14.5908 10.1717 15.7589 9.48485 16.4681C8.79798 17.1773 7.66667 17.1773 6.9798 16.4681L0.515152 9.79325C-0.171717 9.08405 -0.171717 7.91595 0.515152 7.20675Z" fill="black"/>
                                </svg>
                            </div>
                            <div className="d_page_title">{multilanguage.order_no}. {this.props.match.params.id}</div>
                        </div>
                    
                        <div className="d_page_title_right_flex">
                            {/* <div className="d_add_patient_button">
                                <span>{multilanguage.send_on_email}</span>
                            </div>  */}
                            <div onClick={()=> window.print()} className="d_order_print_button">{multilanguage.print_sample}</div>                
                        </div>

                    </div>

                    <div className="dpat_table_header">
                        <div className="d_odh_patient">{multilanguage.patient}</div>
                        {/* <div className="d_odh_location">{multilanguage.location_simple}</div> */}
                        <div className="d_odh_date">{multilanguage.date_simple}</div>
                        <div className="d_odh_order">{multilanguage.services}</div>
                        <div className="d_odh_price">{multilanguage.price_simple}</div>
                    </div>
                    {this.state.loading ? <DLoader /> : 
                    <div className="d_od_line">
                        {this.state.order.patient.id ? 
                            <div className="d_od_patient" onClick={() => this.props.history.push('/patient_detail/'+this.state.order.patient.id)}>
                            <img className="d_header_left_avatar" src={this.state.order.patient.gender == 'F' ? require('../assets/images/famale.png') : require('../assets/images/male.png')} />
                            <span>{this.state.order.patient.firstName} {this.state.order.patient.lastName}</span>
                        </div>
                        :
                        <div className="d_od_patient" >
                            <img className="d_header_left_avatar" src={this.state.order.patient.gender == 'F' ? require('../assets/images/famale.png') : require('../assets/images/male.png')} />
                            <span>{this.state.order.patient.firstName} {this.state.order.patient.lastName}</span>
                        </div>
                        }
                        
                        {/* <div className="d_od_location">{this.state.order.locationScope}</div> */}
                        <div className="d_od_date">{moment(this.state.order.validFrom).format('DD.MM.YYYY')}</div>
                        <div className="d_order_detail_product_container">
                            {this.state.order.services.map((service)=>{
                                total = total + service.price
                                totalDiscount = totalDiscount + service.basePrice
                                return(
                                    <div className="d_order_detail_prod_line">
                                        <div className="d_od_prod_name">{service.name}</div>
                                        {service.basePrice == service.price ?
                                        <div className="d_od_prod_price">{service.price} {multilanguage.currency}</div>:
                                        <div className="d_od_prod_price"><span className="discount-text">{service.basePrice} {multilanguage.currency}</span> {service.price} {multilanguage.currency}</div>}
                                    </div>
                                )
                            })}
                            <div className="d_order_detail_prod_total_line">
                                <div className="d_order_detail_prod_total_title">{multilanguage.total}</div>
                                {total == totalDiscount ?
                                <div className="d_order_detail_prod_total_price">{total} {multilanguage.currency}</div>:
                                <div className="d_order_detail_prod_total_price"><span className="discount-text">{totalDiscount} {multilanguage.currency}</span> {total} {multilanguage.currency}</div>}
                            </div>
                        </div>
                        
                    </div>
    }
                    </div>
                </div>
            </div>
 )}
}
 
export default DOrderDetail;