
import Oidc from 'oidc-client';
import AppConfig from '../config';
import api from './api';
import axios from 'axios';

var authApi = {};

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.DEBUG;
console.log('Using oidc-client version: ', Oidc.Version);
var baseurl = window.location.origin;

authApi.state = {
    logged: false,
    user: null,
};

var mgr = null;
authApi.init = () => {
    mgr = new Oidc.UserManager({
        authority: AppConfig.auth.baseUrl,
        client_id: AppConfig.auth.client_id,
        client_secret: AppConfig.auth.client_secret,
        redirect_uri: baseurl + '',
        post_logout_redirect_uri: baseurl + '',
        response_type: "id_token token",
        // response_type: "code",
        scope: 'openid',
        automaticSilentRenew: false,
        validateSubOnSilentRenew: false,
        filterProtocolClaims: true,
        loadUserInfo: true,
        revokeAccessTokenOnSignout : true
    });
    window.mgr = mgr;
    let auth = localStorage.getItem('auth')
    if(auth){
        let authparse = JSON.parse(auth)
        if(authparse)authApi.auth = authparse
    }
}

authApi.accessToken = null;
authApi.auth = {
    logged: false,
    user:{
        FirstName: '',
        LastName: '',
    }
}
authApi.init();
authApi.login = () => {
    return new Promise((resolve, reject) => {
        mgr.signinRedirect({ state: 'clean' })
        .then(console.log)
        .catch(console.error);
    });
}
authApi.resetPassword = () => {
    window.location.href = AppConfig.auth.baseUrl + '/Account/ForgotPassword';
}
authApi.login_old = (email, password) => {

    return new Promise((resolve, reject) => {
        authApi.post('/connect/token', {
            grant_type: 'password',
            username: email,
            password: password,
            client_secret: AppConfig.auth.client_secret,
            client_id: AppConfig.auth.client_id,
            scope: 'openid profile',

        }).then(res => {
            authApi.accessToken = res.data.access_token;
            let auth = {
                logged: true,
                token: {
                    accessToken: authApi.accessToken,
                    expiresAt: Math.floor(Date.now() / 1000) + res.data.expires_in
                }
            }
            authApi.auth = auth
            localStorage.setItem('auth', JSON.stringify(auth))
            authApi.get('/connect/userinfo')
            .then(res2 => {
                authApi.auth.user = res2.data;
                localStorage.setItem('auth', JSON.stringify(auth))
                resolve(res2.data);
            })
            
        })
        mgr.signinRedirect({ state: 'clean', extraQueryParams:{username: email, password: password, client_secret: AppConfig.auth.client_secret, grant_type: 'password'}})
        .then(console.log)
        .catch(console.error);
    });
}

authApi.createAccount = () => {
    window.location.href = AppConfig.auth.baseUrl + '/Account/Register';
}

window.mockApiData = true;//false; // this turns on then the server is down. we load some mock data

authApi.check = () => {
    return new Promise((resolve, reject) => {
        
        // fake login to bypass identity service
        if (window.mockApiData) {
            authApi.auth.logged = true;
            authApi.auth.user = {
                FirstName: "four",
                LastName: "silab",
                email: "four.silab@yopmail.com",
                medicalProfiles: "31006068212|31006068214",
                scope: "medicalProfiles",
                sid: "4Opkc-o9PC_J16MvztyM2Q",
                sub: "f2f868e6-84fe-4e66-bf6e-b1fb7a772f4c",
                role: 'practitioner',
            };
            authApi.auth.medicalProfiles = require('./medicalprofiles.json');
            authApi.auth.token = {
                accessToken: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjRBRUZDNjJCREU5NERCRjU1RjA3NkNGOUNFRDY0MEIyN0I3QUUwQjUiLCJ0eXAiOiJhdCtqd3QiLCJ4NXQiOiJTdV9HSzk2VTJfVmZCMno1enRaQXNudDY0TFUifQ.eyJuYmYiOjE2NDY4MzM0MzEsImV4cCI6MTY0NjgzNjczMSwiaXNzIjoiaHR0cHM6Ly9pZGVudGl0eXNlcnZpY2UtZXh0ZXJuYWwtc252by1yby10c3QtY3VycmVudC5jbG91ZC5zeW5ldm8uY29tIiwiY2xpZW50X2lkIjoicGF0aWVudC1hcHAtc3BhIiwic3ViIjoiZjJmODY4ZTYtODRmZS00ZTY2LWJmNmUtYjFmYjdhNzcyZjRjIiwiYXV0aF90aW1lIjoxNjQ2ODIyNzQ1LCJpZHAiOiJsb2NhbCIsIkFzcE5ldC5JZGVudGl0eS5TZWN1cml0eVN0YW1wIjoiT0tZU0FWQlVBT1c2UVcyTjRHNFczWURCRVpKNDVGR0QiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJmb3VyLnNpbGFiQHlvcG1haWwuY29tIiwibmFtZSI6ImZvdXIuc2lsYWJAeW9wbWFpbC5jb20iLCJlbWFpbCI6ImZvdXIuc2lsYWJAeW9wbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiRmlyc3ROYW1lIjoiUGF0aWVudCIsIkxhc3ROYW1lIjoiZm91ciIsIm1lZGljYWxQcm9maWxlcyI6IjMxMDA3MzE3MjA2Iiwic2NvcGUiOlsicHJvZmlsZSIsIm9wZW5pZCJdLCJhbXIiOlsicHdkIl19.g9p4M65Yb7bL4F4r5bKjbj4o9ulYlz6GQy8ZoqAn0cUpJK_-oJwP_8Upo2rpzau-GNrNfALpMfKZIuE3TcRTYMv6-sYhVFBIPuyPc88WS8dr8-lJeju_zWGRmlUulE6f1ZN3OR3wfhXCd-S0hVB2qrrOrbtxWOjjK4lLyog8rmhiwWIGlZHSfs-u7uiAE6mW0z6uMTOxakMIjonH-aM7wHDi2XYeSe6-2fZero8coCedCkVbxnCXt8WEU-M0EIHdBqe5sTvEp2dUnmO-eAbVse8fK-ONH0lIQHUMArrDP5efX-_N2b59dZuaRZLZtBzEULBl3Zjk6bfb1Jwq8CZtWw",
                expiresAt: (Date.now() / 1000) + 12000000,
            };
            resolve(authApi.auth);
            return null;
        }
        
        if (window.location.href.indexOf('token=') != -1) {
            mgr.signinRedirectCallback()
            .then((user) => {
                authApi.auth.logged = true;
                authApi.auth.user = user.profile;
                authApi.auth.token = {
                    accessToken: user.access_token,
                    expiresAt: user.expires_at
                }
                resolve(authApi.auth);
            })
            .catch((err) => {
                console.error(err);
                authApi.auth.logged = false;
                authApi.auth.user = null;
                mgr.removeUser();
                mgr.clearStaleState();
                reject(authApi.auth);
            });
        } else {
            mgr.getUser().then((user) => {
                authApi.auth.logged = true;
                authApi.auth.user = user.profile;
                authApi.auth.token = {
                    accessToken: user.access_token,
                    expiresAt: user.expires_at
                }
                resolve(authApi.auth);
            }).catch((err) => {
                authApi.auth.logged = false;
                authApi.auth.user = null;
                mgr.removeUser();
                mgr.clearStaleState();
                reject(authApi.auth);
            });
        }
    });
}

authApi.check_old = () => {
    return new Promise((resolve, reject) => {
        mgr.getUser().then((user) => {
            authApi.state.logged = true;
            authApi.state.user = user.profile;
            resolve(authApi.state);
        }).catch((err) => {
            authApi.state.logged = false;
            authApi.state.user = null;
            mgr.removeUser();
            mgr.clearStaleState();
            reject(authApi.state);
        });
    });
}

authApi.logout = () => {
    return new Promise((resolve, reject) => {
        mgr.signoutRedirect({ state: 'clean' })
        .then(() => {
            authApi.state.logged = false;
            authApi.state.user = null;
            mgr.removeUser();
            mgr.clearStaleState();
            resolve(authApi.state);
        })
        .catch((err) => {
            console.error(err);
            window.location.reload();
            mgr.removeUser();
            mgr.clearStaleState();
            reject(authApi.state);
        });
    });
}

authApi.post = (url, data) => {
    return authApi.sendRequest("POST", url, data);
};
// authApi request get shorthand
authApi.get = (url, data) => {
    return authApi.sendRequest("GET", url, data);
};


authApi.sendRequest = (method, url, data) => {
    if (typeof headers == "undefined") var headers = {};
    let defaultHeaders = {
        // 'Content-Type': 'application/json',
    };
    if(data){
        let form_data = new FormData;
        Object.keys(data).forEach(key => {
            form_data.append(key, data[key]);
        });
        data = form_data;
    }
    headers = {...defaultHeaders, ...headers};
    if(authApi.accessToken){
        headers['Authorization'] = 'Bearer '+authApi.accessToken;
    }
    url = AppConfig.auth.baseUrl + url;
    let options = {
        method: method,
        url: url,
        headers: headers,
    };
    if (method.toUpperCase() == 'GET') {
        options.params = data;
    } else {
        options.data = data;
    }
    return new Promise((resolve, reject) => {
        axios(options)
        .then((response) => { resolve(response); })
        .catch((response) => { reject(response); });
    });
};

export default authApi;
