import React from 'react';
import NavBar from './parts/NavBar';
import ResultFilters from './parts/ResultFilters';
import multilanguage from '../multilanguage';
import moment from 'moment';
import DLoader from "./parts/DLoader";
import cart from '../api/cart';
import api from '../api/api';
import auth from '../api/auth';
let screenHeight = 0;

export default  class Results extends React.Component {

    constructor() {
        super();
        this.state={
            search: '',
           selected:1,
           visibleNavBar: true,
           loading: true,
           filtersVisible:false,
           results: [],
        }
        this.handleResize = this.handleResize.bind(this);
    }

    selectFiles(id){
        this.setState({selected: id});
    }

    componentDidMount(){
        screenHeight = document.documentElement.clientHeight;
        window.addEventListener('resize',this.handleResize);
        const id = this.props.match.params.id;
            api.get('/Patient/'+id+'/serviceresult')
            .then(res => {
                this.setState({results: res.data.results, loading: false});
            })
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.handleResize);
    }

    handleResize(){
        if(screenHeight - document.documentElement.clientHeight > 150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: false})
        }
        if(screenHeight - document.documentElement.clientHeight < -150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: true})
        }
    }

    setFilterVisible(){
        this.setState({filtersVisible: true});
    }
    handleCloseFilters(){
        this.setState({filtersVisible: false});
    }
    searchtimeout = null
    doSearch(search){
      this.setState({loading: true})
      api.get('/Patient/'+this.props.match.params.id+'/serviceresult', {searchQuery: search})
              .then(res => {
                this.setState({
                  loading: false,
                  results: res.data.results
                })
              })
    }
    search(search){
      this.setState({results: []})
      if (this.searchtimeout) clearTimeout(this.searchtimeout)
      this.searchtimeout = setTimeout(() => this.doSearch(search), 1000);
    }
    render() {
        return (
           <div className="app_container">
            <ResultFilters navigation={this.props.history} visible={this.state.filtersVisible} onClose={() => this.handleCloseFilters()} />
               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="menu_account_avatar_container">
                        <img className="menu_account_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                    </div>
                    <div className="header_with_back_title_no_margin">{this.props.location.state.name}</div>
                </div>
               </div>
                

                <div className="patient_detail_navigator_container">
                    <div className="patient_detail_navigator_inner">
                        <div className="patient_detail_navigator_item" onClick={() => this.props.history.push('/patient_detail/'+this.props.match.params.id, {name: this.props.location.state.name})}>{multilanguage.data_simple}</div>
                        {/* <div className="patient_detail_navigator_item" onClick={() => this.props.history.push('/medical_files')}>{multilanguage.medical_files}</div> */}
                        <div className="patient_detail_navigator_item active">{multilanguage.results}</div>
                        <div className="patient_detail_navigator_item" onClick={() => this.props.history.push('/patient_orders/'+this.props.match.params.id, {name: this.props.location.state.name})}>{multilanguage.orders_simple} </div>
                    </div>
                </div>

                
                <div className="orders_search_container">
                    <div className="orders_search_input_container">
                        <img src={require('../assets/images/graysearch.svg')} />
                        <input type="text" placeholder={multilanguage.search_simple} 
                        defaultValue={this.state.search}
                        onChange={evt => this.search(evt.target.value)}
                        disabled={this.state.loading}
                        />
                    </div>
                    {/* <div className="orders_search_filter_button" onClick={() => this.setFilterVisible()}>
                        <img src={require('../assets/images/filterIcon.svg')} />
                    </div> */}
                </div>


                <div className="result_list_container">
                    <div className="patient_detail_info_title">{multilanguage.recent_results}</div>
                    {this.state.loading ? <DLoader/> : null}
                    {this.state.results ? this.state.results.map((result)=>{
                                    var nameResult = result.display
                                    var pesel = ''
                                    result.patient.externalIds.map((external)=>{   
                                        if(external.system == 'PESEL'){
                                            pesel = external.value
                                        }
                                    })
                                    
                                    
                                    return(
                    <div className="result_box" onClick={() => this.props.history.push('/result/'+result.id, {name: nameResult})}>
                        <div className="patients_box_inner">
                            <div className="patients_box_top">
                                <div className="result_item_first_line">
                                    <img src={require('../assets/images/blackFile.svg')} />
                                    <span className="patients_box_value_name">{((nameResult).length > 70) ? (((nameResult).substring(0,70-3)) + '...') : nameResult}</span>
                                </div>
                            </div>
                            <div className="patients_box_top">
                                <div className="patients_box_line">
                                    <span className="patients_box_label">{multilanguage.name}</span>
                                    <span className="patients_box_value">{result.patient.firstName} {result.patient.lastName}</span>
                                </div>
                                <div className="patients_box_line end">
                                    <span className="patients_box_label">{multilanguage.pesel_number}</span>
                                    <span className="patients_box_value">{pesel}</span>                       
                                </div>
                            </div>
                            <div className="patients_box_top">
                            <div className="patients_box_line">
                                <span className="patients_box_label">{multilanguage.sampling_date} </span>
                                <span className="patients_box_value">{moment(result.issued).format('DD.MM.YYYY')}</span>
                            </div>
                            <div className="patients_box_line">
                                {result.status == "Ready" ? 
                                <div className="result_ready_tag">
                                    {multilanguage.result_is_ready}
                                </div>
                                :
                                <div className="result_pending_tag">
                                    {multilanguage.result_is_pending}
                                </div>
                                }
                            </div>
                            </div>
                            
                        </div>
                    </div>
                                    )
                    }): null}
                </div>



                {this.state.visibleNavBar ?
                <NavBar navigation={this.props.history} />
                : null}
           </div>
)}
}
