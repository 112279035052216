import React from 'react';
import NavBar from './parts/NavBar';
import { FilePicker } from 'react-file-picker';
import multilanguage from '../multilanguage';

let screenHeight = 0;

export default  class MedicalFiles extends React.Component {

    constructor() {
        super();
        this.state={
           selected:1,
           visibleNavBar: true,
        }
        this.handleResize = this.handleResize.bind(this);
    }

    selectFiles(id){
        this.setState({selected: id});
    }

    componentDidMount(){
        screenHeight = document.documentElement.clientHeight;
        window.addEventListener('resize',this.handleResize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.handleResize);
    }

    handleResize(){
        if(screenHeight - document.documentElement.clientHeight > 150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: false})
        }
        if(screenHeight - document.documentElement.clientHeight < -150){
            screenHeight = document.documentElement.clientHeight;
            this.setState({visibleNavBar: true})
        }
    }

    render() {
        return (
           <div className="app_container">

               {/* HEADER */}
                <div className="home_header">
                <div className="header_wih_back_inner" onClick={() => this.props.history.goBack()}>
                    <div className="header_with_back_icon_container">
                        <img src={require('../assets/images/arrowBack.svg')} />
                    </div>
                    <div className="menu_account_avatar_container">
                        <img className="menu_account_avatar" src={require('../assets/images/personPlaceholder.jpg')} />
                    </div>
                    <div className="header_with_back_title_no_margin">Olivia Wilson</div>
                </div>
               </div>
                

                <div className="patient_detail_navigator_container">
                    <div className="patient_detail_navigator_inner">
                        <div className="patient_detail_navigator_item" onClick={() => this.props.history.push('/patient_detail')}>{multilanguage.data_simple}</div>
                        <div className="patient_detail_navigator_item active">{multilanguage.medical_files}</div>
                        <div className="patient_detail_navigator_item" onClick={() => this.props.history.push('/results')}>{multilanguage.results}</div>
                        <div className="patient_detail_navigator_item" onClick={() => this.props.history.push('/patient_orders')}>{multilanguage.orders_simple} </div>
                    </div>
                </div>

                
                <div className="orders_search_container">
                    <div className="messages_search_input_container">
                        <img src={require('../assets/images/graysearch.svg')} />
                        <input type="text" placeholder={multilanguage.search_simple} />
                    </div>
                </div>

                <div className="medical_files_category_select">
                    <div className={this.state.selected == 1 ? "medical_files_category_item active" : "medical_files_category_item"} onClick={() => this.selectFiles(1)}>
                        <img src={require('../assets/images/blueFolder.svg')} />
                        <span>{multilanguage.patient_uploads}</span>
                    </div>
                    <div className={this.state.selected == 2 ? "medical_files_category_item active" : "medical_files_category_item"} onClick={() => this.selectFiles(2)}>
                        <img src={require('../assets/images/blueFolder.svg')} />
                        <span>{multilanguage.medic_uploads}</span>
                    </div>
               </div>

                <div className="medical_files_list_container">
                    <div className="patient_detail_info_title">{this.state.selected == 1 ? 'Patient' : 'Doctor'} Uploads</div>

                    <div className="medical_files_item">
                        <div className="messages_list_item_left">
                            <img className="medical_files_picture" src={require('../assets/images/personPlaceholder.jpg')} />
                            <div className="messages_list_item_middle">
                                <div className="medical_files_item_name">img_02-radiografie pulmonara</div>
                                <div className="messages_list_item_text">22.05.2018</div>
                            </div>
                        </div>
                        <div className="messages_list_item_right">
                            <img className="medical_files_download" src={require('../assets/images/blueDownload.svg')} />
                        </div>
                    </div>
                    <div className="medical_files_item">
                        <div className="messages_list_item_left">
                            <img className="medical_files_picture" src={require('../assets/images/personPlaceholder.jpg')} />
                            <div className="messages_list_item_middle">
                                <div className="medical_files_item_name">Sed volutpat velit in dui vulputate, ac bibenduma</div>
                                <div className="messages_list_item_text">22.05.2018</div>
                            </div>
                        </div>
                        <div className="messages_list_item_right">
                            <img className="medical_files_download" src={require('../assets/images/blueDownload.svg')} />
                        </div>
                    </div>
                    
                </div>
                {this.state.visibleNavBar ?
                <FilePicker
                    extensions={[]}
                    // onChange={}
                    // onError={}
                >
                    <div className="patients_button_fixed">
                        <img src={require('../assets/images/whitePlus.svg')} />
                    </div>
                </FilePicker>
                : null}

                {this.state.visibleNavBar ?
                <NavBar navigation={this.props.history} />
                : null}
           </div>
)}
}
