import emitter from 'tiny-emitter/instance';
import api from '../api/api';
import auth from '../api/auth';
import {NotificationManager} from 'react-notifications';
import multilanguage from '../multilanguage';

var cart = {}

cart.loading = false;
cart.totalPrice = 0;
cart.itemCount = 0;
cart.commanet = '';
cart.items = [];
cart.patient = null;
cart.discount = false;
cart.discountTotal = null;


cart.setDiscount = (discount)=>{
    cart.discount = discount
    
    
    cart.refresh()
}

cart.sendOrder = (props)=>{
    cart.setLoading(true)
    var request = {}
    request.categoty = 1
    request.patient = cart.patient
    request.services = cart.items
    request.services.map((item)=>{
        if(item.material){
            if(item.code.indexOf(":") == -1){
                item.code = item.code+':'+item.material
            }
        }else{
            item.code = item.code+':'
        }
        
    })
    
    if(cart.patient){
        if(cart.itemCount > 0){
            
            api.put('/ServiceRequest', request)
            .then(res => {
                // this.setState({loading: false})
                
                // this.props.history.push("/patient_detail/"+this.state.patient.id)
                cart.empty()
                props.screenProps.push('/order_detail/'+res.data.id)
                NotificationManager.success(multilanguage.order_created, '',1000);
                
            })
            .catch(error =>{
                // this.setState({loading: false})
                // console.log(error.response.data.invalidParameters)
                // error.response ? error.response.data.invalidParameters.map((e)=>{
                //     NotificationManager.error(e.reason);
                // }) : 
                cart.setLoading(false)
            })
        }else{
            NotificationManager.error(multilanguage.add_an_item, '',1000)
            cart.setLoading(false)
        }
    } else {
        NotificationManager.error(multilanguage.add_a_patient, '',1000)
        cart.setLoading(false)
    }     
}

cart.addItem = (item)=>{
    let foundItem = cart.findItem(item.id)
    if(foundItem === null){
        cart.items.push(item)
        NotificationManager.success(multilanguage.notification_addToCart, '',1000)
    }
    else{
        cart.removeItem(item.id)
        NotificationManager.success(multilanguage.notification_removeFromCart, '',1000)
    }
    //oustide of else
    cart.refresh()
}

cart.removeItem = (id)=>{
    let foundItem = cart.findItem(id)
    if(foundItem !== null)
    cart.items.splice(foundItem, 1)
    cart.refresh()
}

cart.empty = ()=>{
    cart.totalPrice = 0;
    cart.itemCount = 0;
    cart.commanet = '';
    cart.items = [];
    cart.patient = null;
    cart.loading = false;
    cart.discountTotal = null;
    cart.discount = false;
    cart.refresh()
}

cart.setLoading = (bool)=>{
    cart.loading = bool
    cart.refresh()
}

cart.addPatient = (patient)=>{
    cart.patient = null
    cart.patient = patient
    NotificationManager.success(multilanguage.patient_added, '',1000)
    cart.refresh()
}

cart.removePatient = ()=>{
    cart.patient = null;
    cart.refresh()
}

cart.refresh = ()=>{
    if(cart.items && cart.items.length > 0){
    var serviceCodeMaterialList = ''
        cart.items.map((item)=>{
            if(item.material){
                if(item.code.indexOf(":") == -1){
                    serviceCodeMaterialList=serviceCodeMaterialList+item.code+':'+item.material+','
                }
            }else{
                serviceCodeMaterialList=serviceCodeMaterialList+item.code+':,'
            }
        })
        
            api.get('/ServiceKnowledgebase/servicePriceDetails', {serviceCodeMaterialList, useDiscounts: cart.discount})
            .then(res => {
                cart.totalPrice = res.data.total
                cart.items = res.data.serviceList
                if(res.data.discountTotal){
                    cart.discountTotal = res.data.discountTotal
                }else{
                    cart.discountTotal = null
                }
                cart.itemCount = cart.items.length
                emitter.emit("cartUpdate")
            })
        }else{
            cart.itemCount = 0
            cart.totalPrice = 0
            cart.discountTotal = null
            emitter.emit("cartUpdate")
        }
        
    
    
}

cart.findItem = (id)=>{
    let itemIndex = null
    cart.items.map((cartItem, index)=>{
        if(cartItem.id == id){
            itemIndex = index
        }
    })
    return itemIndex
}

export default cart;